<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <card style="height: 100%">
          <div class="row">
            <div class="col-md-10">
              <input
                v-if="editable"
                v-model="client.stageName"
                style="width: 100%"
              />
              <h3 v-else>{{ client.stageName }}</h3>
            </div>
            <div class="col-md-2">
              <button v-if="!editable" class="btn btn-primary" @click="edit">
                Edit
              </button>
              <button v-else class="btn btn-success" @click="edit">Edit</button>
            </div>
          </div>
          <hr />
          <span
            ><b>Username</b>:
            <input
              v-if="editable"
              v-model="client.username"
              style="width: 100%"
            /><span v-else>{{ client.username }}</span></span
          >
          <br />
          <br />
          <span><b>Type</b>: {{ clientTypes[client.type] }}</span>
          <br />
          <br />
          <span><b>Signed Date</b>: {{ dateFormat(client.created) }}</span>
          <br />
          <br />
          <span
            ><b>Submissions</b>: {{ submissions.length }} ({{
              recallTotal
            }}
            recalls)</span
          >
          <br />
          <br />
          <span><b>Booked Jobs</b>: 0 (£0.00 commission)</span>
        </card>
      </div>
      <div class="col-md-4">
        <card style="height: 100%">
          <h4>Details</h4>
          <hr />
          <span
            ><b>PIN</b>:
            <a
              :href="'https://spotlight.com/' + client.spotlightPIN"
              target="_blank"
              rel="noopener"
              >{{ client.spotlightPIN }}</a
            ></span
          >
          <br />
          <br />
          <div v-if="client.onboarded">
            <span><b>Age</b>: {{ calculateAge(client.dateOfBirth) }}</span>
            <br />
            <br />
            <span
              ><b>Phone Number</b>:
              <input
                v-if="editable"
                v-model="client.phoneNumber"
                style="width: 100%"
              /><span v-else>{{ client.phoneNumber }}</span></span
            >
            <br />
            <br />
            <span
              ><b>UK RTW</b>:
              <span v-html="tick(client.rightToWork.hasRightToWork)"></span
            ></span>
            <br />
            <br />
            <span
              ><b>Self-Employed</b>:
              <span v-html="tick(client.rightToWork.selfEmployed)"></span
            ></span>
            <br />
            <hr />
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8 text-center">
                <button @click="resetPassword" class="btn btn-warning">
                  Reset Password
                </button>
                <br />
                <button
                  v-if="!deleteCheck"
                  @click="deleteCheck = true"
                  class="btn btn-danger"
                >
                  Deactivate User
                </button>
                <button v-else @click="deleteClient" class="btn btn-danger">
                  Are you sure?
                </button>
              </div>
              <div class="col-md-2"></div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
              <button @click="resendInvite" class="btn btn-primary">
                Resend Invite
              </button>
            </div>
            <div class="col-md-2"></div>
          </div>
        </card>
      </div>
      <div class="col-md-4">
        <card style="height: 100%">
          <h3>Headshot</h3>
          <hr />
          <div v-if="!headshotReloading" class="row">
            <div class="col-md-2"></div>
            <img class="headshot col-md-8" :src="headshot" />
            <div class="col-md-2"></div>
          </div>
          <div v-else class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <Spinner></Spinner>
              <br />
              <br />
              <br />
              <h4 style="text-align: center">HEADSHOT UPDATING</h4>
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="row">
            <div class="col-md-2"></div>
            <button
              class="btn btn-primary col-md-8"
              :disabled="headshotReloading"
              @click="headshotModal = true"
            >
              Edit
            </button>
            <div class="col-md-2"></div>
          </div>
        </card>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <card style="height: 100%">
          <div class="row">
            <div class="col-md-12">
              <h3>Submissions</h3>
            </div>
          </div>
          <hr />
          <div class="row">
            <Table :columns="submissionColumns" :data="submissions"></Table>
          </div>
        </card>
      </div>
      <div class="col-md-4">
        <card style="height: 100%">
          <div class="row">
            <div class="col-md-10">
              <h3>Notes</h3>
            </div>
            <div class="col-md-2">
              <button class="btn btn-primary" style="width: 100%">+</button>
            </div>
          </div>

          <hr />
          <div v-for="note in client.notes" :key="note.content">
            <span>{{ note.content }}</span>
            <br />
            <br />
            <b>{{ note.createdBy }} - {{ dateFormat(note.created) }}</b>
            <hr />
          </div>
        </card>
      </div>
      <div class="col-md-4">
        <card style="height: 100%">
          <div class="row">
            <div class="col-md-9">
              <h3>Financials</h3>
            </div>
          </div>

          <hr />
          <InvoiceTable :invoices="invoices"></InvoiceTable>
          <div></div>
        </card>
      </div>
    </div>
    <el-dialog title="Create Invoice" :visible.sync="financeModal">
      <InvoiceCreation :id="id"></InvoiceCreation>
    </el-dialog>
    <el-dialog title="New Headshot" :visible.sync="headshotModal">
      <div class="row">
        <div class="col-md-3"></div>
        <Upload
          name="profilePic"
          :action="'/'"
          :http-request="uploadPic"
          class="avatar-uploader"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
        >
          <img v-if="imageUrl" :src="imageUrl" style="height: 500px" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </Upload>
        <div class="col-md-3"></div>
      </div>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <button
            class="btn btn-primary"
            @click="newHeadshot"
            style="width: 100%"
          >
            Save
          </button>
        </div>
        <div class="col-md-3"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Table } from "src/components/index";
import { Dialog, Upload } from "element-ui";
import InvoiceCreation from "../../components/InvoiceCreation.vue";
import InvoiceTable from "../../components/InvoiceTable.vue";
import Spinner from "../../components/Spinner.vue";

export default {
  components: {
    Table,
    [Dialog.name]: Dialog,
    Upload,
    InvoiceCreation,
    InvoiceTable,
    Spinner,
  },
  props: {
    id: String,
  },
  data() {
    return {
      client: {},
      status: "",
      nextStatus: "",
      invoices: [],
      submissions: [],
      financeModal: false,
      headshotModal: false,
      auditions: [],
      statuses: [],
      newShot: {},
      imageUrl: null,
      clientTypes: {},
      submissionColumns: ["id", "name", "role", "status"],
      deleteCheck: false,
      access: this.getCookie("al"),
      editable: false,
      headshotReloading: false,
    };
  },
  computed: {
    headshot() {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${this.client.id}`;
    },
    auth() {
      return this.client.agentId === this.getCookie("id");
    },
    recallTotal() {
      let result = 0;

      for (var i = 0; i < this.submissions.length; i++) {
        if (this.submissions[i].status === "Recall") {
          result++;
        }
      }

      return result;
    },
  },
  async mounted() {
    this.client = await this.get(`Client/Id/${this.id}`);

    if (this.getCookie(`${this.client.id}-HSR`) === "T") {
      this.headshotReloading = true;
    }

    this.clientTypes = await this.get("Client/Types");
    var subs = await this.get(`Job/Client/${this.client.id}`);
    const statuses = await this.get("Job/Statuses");

    for (var i = 0; i < subs.length; i++) {
      this.submissions.push({
        id: subs[i].id,
        name: subs[i].name,
        role: subs[i].role,
        status: statuses[subs[i].status],
      });
    }
  },
  methods: {
    async resetPassword() {
      await fetch(
        `${process.env.VUE_APP_API}Password/Reset?id=${this.client.id}`,
        {
          method: "POST",
          headers: {
            AT: process.env.VUE_APP_TOKEN,
          },
        }
      )
        .then((res) => res.text())
        .then(async (id) => {
          this.send(3, [{
            email: this.client.username,
            name: this.client.stageName
          }], [], [], {
            parameters: {
              URL: `${window.location.origin}/new-password?id=${id.replace(
                '"',
                ""
              )}`,
            },
          });

          await this.$toastr.s("Success");
        });
    },
    async resendInvite() {
      const password = await this.postBodyVoid(
        `Client/Password?userId=${this.client.id}`,
        this.client.password
      );

      this.send(2, [{
            email: this.client.username,
            name: this.client.stageName
          }], [], [], {
          USERNAME: this.client.username,
          PASSWORD: await password.text(),
          URL: window.location.origin,
        });

      await this.$toastr.s("Success");
    },
    calculateAge(birthday) {
      var ageDifMs = Date.now() - new Date(Date.parse(birthday)).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    },
    tick(e) {
      if (e === true) {
        return '<i class="nc-icon nc-check-2" style="font-size: 1.5re m; color: lightgreen; font-weight:bolder;"></i>';
      } else if (e === false) {
        return '<i class="nc-icon nc-check-2" style="font-size: 1.5rem; color: lightgrey; font-weight:bolder;"></i>';
      }

      return null;
    },
    async newHeadshot() {
      this.newShot.position = 0;
      await fetch(
        `https://storage.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_BUCKET}/o?name=headshots/${this.client.id}&uploadType=media`,
        {
          headers: new Headers({
            Authorization: `Bearer ${this.getCookie("token")}`,
            "Content-Type": this.newShot.raw.type,
          }),
          method: "POST",
          body: this.newShot.raw,
        }
      ).then(() => {
        this.$toastr.s("Success");
        this.headshotReloading = true;
        this.setCookie(`${this.client.id}-HSR`, "T", 0.01);
      });
    },
    async uploadPic() {
      await fetch("/");
    },
    async handleAvatarSuccess(res, file) {
      this.newShot = file;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    async deleteClient() {
      await this.delete(`Client?id=${this.client.id}`)
        .then(async () => {
          this.$toastr.s("Success");
          this.$router.push("list");
        })
        .catch(async (e) => this.$toastr.e(e));
    },
    async edit() {
      this.editable = !this.editable;

      if (!this.editable) {
      }
    },
  },
};
</script>
