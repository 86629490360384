<template>
  <card title="Quick Actions">
    <div v-for="link in links" :key="link.title">
      <button class="btn btn-primary" style="width:100%" @click="$router.push(link.url)">
        {{ link.title }}
      </button>
      <br />
    </div>
  </card>
</template>
<script>
export default {
  data() {
    return {
      links: [
        {
          title: "Record Submission",
          url: "/admin/submissions/log"
        },
        {
          title: "Add New Client",
          url: "/admin/clients/add"
        },
        {
          title: "Create Job",
          url: "/admin/jobs/create"
        }
      ]
    }
  }
}
</script>
