<template>
    <div>
        <div class="row">
            <div v-if="access > 0" class="col-md-4">
                <div class="row">
                    <h4>Set A New Challenge</h4>
                </div>
                <div class="row">
                    <card>
                        <input placeholder="Title" v-model="newChallenge.title" style="width: 100%;" />
                        <br />
                        <br />
                        <textarea placeholder="Description" v-model="newChallenge.description" style="width: 100%;"></textarea>
                        <br/>
                        <br/>
                        <label>Duration</label>
                        <DatePicker :picker-options="openOptions" type="daterange" v-model="dateRange"></DatePicker>
                        <br/>
                        <br/>
                        <button class="btn btn-success" @click="create" style="width: 100%;">Create</button>
                    </card>
                </div>
            </div>
            <div :class="(access > 0) ? 'col-md-8' : 'col-md-12'">
                <div class="row">
                    <h4>Current Challenges</h4>
                </div>
                <div class="row">
                    <div v-if="current.length === 0" class="col-md-12">
                        <i>No current challenges</i>
                    </div>
                    <div class="col-md-5" v-for="challenge in current" :key="challenge.id">
                        <card>
                            <span>{{ dateFormat(challenge.open) }}</span>
                            <h5>{{ challenge.title }}</h5>
                            <sub><i>{{ challenge.type }}</i></sub>
                            <hr />
                            <span v-html="challenge.description.replace(/\n/g, '<br/>')"></span>
                            <br />
                            <br />
                            <span><b>{{ challenge.submissions.length }}</b> submissions, due {{ dateFormat(challenge.close)
                            }}</span>
                            <br />
                            <br />
                            <button class="btn btn-primary" @click="openGallery(challenge.id)" style="width: 100%;">View</button>
                        </card>
                    </div>
                </div>
                <div class="row">
                    <h4>Archived Challenges</h4>
                </div>
                <div class="row">
                    <div v-if="archived.length === 0" class="col-md-12">
                        <i>No archived challenges</i>
                    </div>
                    <div class="col-md-5" v-for="challenge in archived" :key="challenge.id">
                        <card>
                            <h5>{{ challenge.title }}</h5>
                            <hr />
                            <span v-html="challenge.description.replace(/\n/g, '<br/>')"></span>
                            <br />
                            <br />
                            <span><b>{{ challenge.submissions.length }}</b> submissions before {{
                                dateFormat(challenge.close) }}</span>
                            <br />
                            <br />
                            <button class="btn btn-primary" @click="openGallery(challenge.id)" style="width: 100%;">View</button>
                        </card>
                    </div>
                </div>
                <div class="row">
                    <h4>Future Challenges</h4>
                </div>
                <div class="row">
                    <div v-if="future.length === 0" class="col-md-12">
                        <i>No future challenges</i>
                    </div>
                    <div class="col-md-5" v-for="challenge in future" :key="challenge.id">
                        <card>
                            <h5>{{ challenge.title }}</h5>
                            <hr />
                            <span v-html="challenge.description.replace(/\n/g, '<br/>')"></span>
                            <br/>
                            <b>Will open on  {{ dateFormat(challenge.open) }}</b>
                        </card>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import { DatePicker } from "element-ui";
export default {
    components: {
        DatePicker,
    },
    data() {
        return {
            current: [],
            archived: [],
            future: [],
            access:  this.getCookie("al"),
            newChallenge: {
                setterId: this.getCookie("id"),
                type: '',
                title: '',
                description: '',
                open: new Date(),
                close: new Date(),
            },
            dateRange: [new Date(), new Date()],
            openOptions: {
                disabledDate(date) {
                    var today = new Date();
                    var before = today.setDate(today.getDate() - 1);

                    return date < before;
                },
            },
        }
    },
    async mounted() {
        var res = await this.get('Challenge')
        var now = new Date().toISOString();

        for (var i = 0; i < res.length; i++) {
            if (res[i].close < now) {
                this.archived.push(res[i]);
            } else if (res[i].open > now) {
                this.future.push(res[i])
            } else {
                this.current.push(res[i]);
            }
        }
    },
    methods: {
        async create() {
            this.newChallenge.open = this.dateRange[0]
            this.newChallenge.close = this.dateRange[1]

            await this.postBodyVoid('Challenge/Create', this.newChallenge)
            .then(() => this.$toastr.s('Success'))
            .catch((e) => this.$toastr.e(e));

            window.location.reload();
        },
        openGallery(id) {
            this.$router.push(`/challenge-gallery?id=${id}`)
        }
    }
}
</script>