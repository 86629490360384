<template>
    <div>
        <button
      class="btn btn-wd btn-primary"
      style="width: 100%"
      @click="$router.push('/admin/submissions/log')"
    >
      Log Submission
    </button>
    <br />  
        <Spinner v-if="loading"></Spinner>
        <Table v-else filtered @edit="openSubmission" :columns="columns" :data="submissions"> </Table>
        <el-dialog
            title="Warning"
            :visible.sync="modal">
                <h3 v-if="initialCheck & !auditionForm">Has your client been invited to audition?</h3>
                <div v-if="auditionForm">
                    <Checkbox v-model="isSelfTape">&nbsp;Self Tape?</Checkbox>
                    <br/>
                    <div v-if="!isSelfTape">
                        <input style="width: 100%" v-model="auditionLocation" placeholder="Location"/>
                        <br/>
                        <br/>
                    </div>
                    <DatePicker v-model="auditionDate"></DatePicker>
                    <hr />
                    <button class="btn btn-primary" @click="submit">Submit</button>
                </div>
                <h3 v-if="furtherChecks">Have they been offered the job instantly?</h3>
                <h3 v-if="lastCheck">Have they already done the job?</h3>
            <span v-if="!auditionForm" slot="footer" class="dialog-footer">
              <button class="btn btn-danger" @click="openChecks">No</button>&nbsp;
              <button class="btn btn-success" @click="submit">Yes</button>
            </span>
          </el-dialog>
    </div>
</template>
<script>
import { Table, Checkbox, Spinner } from "../../components/index";
import { Dialog, DatePicker } from 'element-ui'

export default {
    components: {
        Table,
        Checkbox,
        [Dialog.name]: Dialog,
        DatePicker,
        Spinner,
    },
    data() {
        return {
            columns: ["project", "role", "client", "submitted", "edit"],
            submissions: [],
            modal: false,
            initialCheck: true,
            auditionForm: false,
            furtherChecks: false,
            lastCheck: false,
            newStatus: 1,
            selectedId: "",
            isSelfTape: true,
            auditionLocation: "",
            auditionDate: new Date(),
            loading: true,
        }
    },
    async mounted() {
        var subs = (await this.get('Job/Submissions')).reverse()
        var clients = await this.get('Client/Names')

        for (var i = 0; i < subs.length; i++) {
            this.submissions.push({
                id: subs[i].id,
                project: subs[i].name,
                role: subs[i].role,
                client: clients[subs[i].clientId],
                submitted: this.dateFormat(subs[i].created)
            })
        }

        this.loading = false;
    },
    methods: {
        openSubmission(e) {
            this.selectedId = e.id;
            this.modal = true;
        },
        async submit(e) {
            if (this.newStatus === 1 & !this.auditionForm) {
                this.auditionForm = true;
            } else {
                this.postVoid(`Job/Status/Update?id=${this.selectedId}&status=${this.newStatus}`);

                if (this.newStatus === 1) {
                    this.postBody("Audition", {
                        jobId: this.selectedId,
                        isSelfTape: this.isSelfTape,
                        location: this.auditionLocation,
                        date: this.auditionDate,
                    })
                }

                await this.$toastr.s("Success");
                this.$router.push('/admin/jobs/auditions')
            }
        },
        openChecks(e) {
            this.initialCheck = false;

            if (this.lastCheck) {
                this.modal = false;
            }

            if (this.newStatus === 5) {
                this.furtherChecks = false;
                this.lastCheck = true;
                this.newStatus = 7
            }

            if (this.newStatus === 1) {
                this.furtherChecks = true;
                this.newStatus = 5;
            }
        }
    }
}
</script>