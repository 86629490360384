<template>
  <div class="user">
    <div class="photo">
      <img :src="image" alt="avatar"/>
    </div>
    <div class="info">
      <a href="">
        <span>{{title}}</span>
      </a>
    </div>
  </div>
</template>
<script>
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

  export default {
    components: {
      [CollapseTransition.name]: CollapseTransition
    },
    data () {
      return {
        isClosed: true,
        image: 'https://storage.googleapis.com/' + process.env.VUE_APP_BUCKET + '/headshots/' + this.getCookie('id'),
        title: this.getCookie("name"),
      }
    },
    methods: {
      toggleMenu () {
        this.isClosed = !this.isClosed
      }
    }
  }
</script>
<style>
  .collapsed {
    transition: opacity 1s;
  }
</style>
