<template>
  <div>
    <button class="btn btn-wd btn-primary" style="width: 100%" @click="$router.push('/admin/clients/add')">
      Add New Client
    </button>
    <br />
    <button class="btn btn-wd btn-warning" style="width: 100%" @click="submissionModal = true">
      Submit
    </button>
    <br />
    <div v-if="!loading" class="row">
      <div v-for="client in clients" :key="client.id" class="col-md-3">
        <card>
          <div class="row">
            <div class="col-md-1"></div>
            <img class="headshot col-md-10" :src="client.headshot" />
            <div class="col-md-1"></div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-9">{{ client.stageName }} - <button class="btn btn-primary"
                @click="view(client)">View</button></div>
            <div class="col-md-1"></div>
          </div>
        </card>
      </div>
    </div>
    <el-dialog :title="selectedClient.stageName" :visible.sync="viewModal">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img class="headshot" :src="selectedClient.headshot" />
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">Bases:</div>
              <div class="col-md-6">
                <div class="container">
                  <div class="row" v-for="location in selectedClient.details.locations" :key="location">
                    {{ location }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">&nbsp;</div>
            </div>
            <div class="row">
              <div class="col-md-6">Age:</div>
              <div class="col-md-6">{{ (selectedClient.age > 100) ? 'Unknown' : selectedClient.age }}</div>
            </div>
            <div class="row">
              <div class="col-md-6">Gender:</div>
              <div class="col-md-6">{{ selectedClient.details.gender }}</div>
            </div>
            <div class="row">
              <div class="col-md-6">Playing age:</div>
              <div class="col-md-6">{{ selectedClient.details.playingAgeLow }} to {{
      selectedClient.details.playingAgeHigh }}</div>
            </div>
            <div class="row">
              <div class="col-md-6">Height:</div>
              <div class="col-md-6">{{ selectedClient.details.height }}cm</div>
            </div>
            <div class="row">
              <div class="col-md-6">Ethnicity:</div>
              <div class="col-md-6">{{ selectedClient.details.ethnicity }}</div>
            </div>
            <div class="row">
              <div class="col-md-6">Eye Colour:</div>
              <div class="col-md-6">{{ selectedClient.details.eyeColour }}</div>
            </div>
            <div class="row">
              <div class="col-md-6">Hair Colour:</div>
              <div class="col-md-6">{{ selectedClient.details.hairColour }}</div>
            </div>
            <div class="row">
              <div class="col-md-6">Showreel:</div>
              <div class="col-md-6">{{ selectedClient.details.showreel }}</div>
            </div>
            <div class="row" v-if="!selectedClient.spotlightPIN">
              <div class="col-md-6">CV:</div>
                <div class="col-md-6"><a :href="selectedClient.details.cv">{{ selectedClient.details.cv }}</a></div>
            </div>
            <div class="row" v-else>
              <div class="col-md-6">CV:</div>
                <div class="col-md-6"><a :href="`https://app.spotlight.com/${selectedClient.spotlightPIN}`">{{ selectedClient.spotlightPIN }}</a></div>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-3"></div>
          <div class="col-md-2">
            <button @click="openEdit" class="btn btn-warning" style="width: 100%;">
              Edit
            </button>
          </div>
          <div class="col-md-2">
            <button @click="openCalendar(selectedClient.id)" class="btn btn-primary" style="width: 100%;">
              Calendar
            </button>
          </div>
          <div class="col-md-2">
            <button @click="resetPassword" class="btn btn-secondary" style="width: 100%;">
              Reset Password
            </button>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="selectedClient.stageName" :visible.sync="calendarModal">
      <div v-if="calendar.length > 0" class="container">
        <div v-for="event in calendar" :key="event.id" class="row">
          <div class="col-md-4">
            <span>{{ event.title }}</span>
          </div>
          <div class="col-md-4">
            <span>{{ dateFormat(event.from) }} - {{ dateFormat(event.to) }}</span>
          </div>
          <div class="col-md-4">
            <span>{{ event.description }}</span>
          </div>
        </div>
      </div>
      <div v-else class="container">
        <div class="col-md-12">
          <h4>No calendar events</h4>
        </div>
      </div>
    </el-dialog>
    <el-dialog :title="editableClient.stageName" :visible.sync="editModal">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img class="headshot" :src="editableClient.headshot" />
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6">Bases:</div>
              <div class="col-md-6">
                <div class="container">
                  <div class="row" v-for="location in editableClient.details.locations" :key="location">
                    <span>{{ location }} <a href="#"
                        @click="editableClient.details.locations.splice(editableClient.details.locations.indexOf(location), 1); $forceUpdate()">X</a></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <input v-model="newLocation"
                  @keyup.enter="editableClient.details.locations.push(newLocation); newLocation = ''; $forceUpdate()"
                  style="width: 100%;" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <a href="#"
                  @click="editableClient.details.locations.push(newLocation); newLocation = ''; $forceUpdate()">Add</a>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">&nbsp;</div>
            </div>
            <div class="row">
              <div class="col-md-6">Gender:</div>
              <div class="col-md-6">
                <select class="form-control" v-model="editableClient.details.gender">
                  <option value=""></option>
                  <option v-for="(t, i) in genders" :key="i" :value="t">{{ t }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">Playing age:</div>
              <div class="col-md-6">
                <input type="number" v-model="editableClient.details.playingAgeLow" />
                to
                <input type="number" v-model="editableClient.details.playingAgeHigh" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">Height:</div>
              <div class="col-md-6">
                <input type="number" v-model="editableClient.details.height" />cm
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">Ethnicity:</div>
              <div class="col-md-6">
                <select class="form-control" v-model="editableClient.details.ethnicity">
                  <option value=""></option>
                  <option v-for="(t, i) in ethnicities" :key="i" :value="t">{{ t }}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">Eye Colour:</div>
              <div class="col-md-6">
                <input v-model="editableClient.details.eyeColour" style="width: 100%;" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">Hair Colour:</div>
              <div class="col-md-6">
                <input v-model="editableClient.details.hairColour" style="width: 100%;" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">Showreel:</div>
              <div class="col-md-6">
                <input v-model="editableClient.details.showreel" style="width: 100%;" />
              </div>
            </div>
            <div class="row" v-if="!editableClient.spotlightPIN">
              <div class="col-md-6">CV:</div>
              <div class="col-md-6">
                <input v-model="editableClient.details.cv" style="width: 100%;" />
              </div>
            </div>
          </div>
        </div>
        <br/>
        <div class="row">
          <div class="col-md-4"></div>
          <div class="col-md-4">
            <button @click="update" class="btn btn-success" style="width: 100%;">
              Save
            </button>
          </div>
          <div class="col-md-4"></div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="Submit Clients" :visible.sync="submissionModal">
      <CommercialSubmission></CommercialSubmission>
    </el-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
import { Dialog } from 'element-ui'
import CommercialSubmission from '../../components/CommercialSubmission.vue'

export default {
  components: {
    [Dialog.name]: Dialog,
    CommercialSubmission,
  },
  data() {
    return {
      loading: true,
      clients: [],
      selectedClient: {
        details: {}
      },
      editableClient: {
        details: {}
      },
      viewModal: false,
      editModal: false,
      calendarModal: false,
      submissionModal: false,
      newLocation: "",
      ethnicities: [],
      genders: [],
      calendar: []
    };
  },
  async mounted() {
    this.clients = await this.get('Client/Type/4');
    for (var i = 0; i < this.clients.length; i++) {
      this.clients[i].headshot = `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${this.clients[i].id}`;
    }
    this.loading = false;
  },
  methods: {
    async view(client) {
      const characteristics = await this.get('Characteristics');

      this.ethnicities = characteristics.ethnicity;
      this.genders = characteristics.gender;

      this.selectedClient = client;
      try {
        this.selectedClient.details = await this.get(`ClientDetails/${client.id}`);
      } catch {
        const newDetails = {
          id: client.id,
          locations: [],
          gender: "",
          playingAgeLow: 0,
          playingAgeHigh: 0,
          height: 0,
          ethnicity: "",
          eyeColour: "",
          hairColour: "",
          showreel: "",
          cv: ""
        };

        await this.postBodyVoid(`ClientDetails`, newDetails);

        this.selectedClient.details = newDetails;
      }

      var ageDifMs = Date.now() - new Date(Date.parse(client.dateOfBirth)).getTime();
      var ageDate = new Date(ageDifMs); // miliseconds from epoch
      this.selectedClient.age = Math.abs(ageDate.getUTCFullYear() - 1970);

      this.viewModal = true;
    },
    openEdit() {
      this.editableClient = _.cloneDeep(this.selectedClient);
      this.editModal = true
    },
    async update() {
      this.selectedClient = this.editableClient;
      await this.postBodyVoid('ClientDetails/Update', this.selectedClient.details);
      await this.$toastr.s('Success');

      this.$forceUpdate();
    },
    async openCalendar(id) {
      this.calendar = (await this.get(`Calendar/User/${id}`))[0].events
      this.calendarModal = true;
    },
    async resetPassword() {
      await fetch(`${process.env.VUE_APP_API}Password/Reset?id=${this.selectedClient.id}`, {
        method: 'POST',
        headers: {
          "AT": process.env.VUE_APP_TOKEN,
        },
      })
        .then((res) => res.text())
        .then(async (id) => {
          await this.$toastr.s("Success")
        })
    },
  },
}
</script>
