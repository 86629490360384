<template>
  <div>
    <table style="border-collapse: collapse !important;">
      <thead style="position: sticky; top:0; z-index:99; background-color: white;">
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          First Name</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Last Name</th>
        <th
          style="position: sticky; left:0; z-index:99; background-color: white; border-style: solid; border-color: gray; border-width: 0.5px;">
          Stage Name</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Spotlight PIN</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Username</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Client Type</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Onboarded</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Date Of Birth</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Phone Number</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Address Line 1</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Address Line 2</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Address Line 3</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Town</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          County</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Postcode</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Emergency Contact First Name</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Emergency Contact Last Name</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Emergency Contact Number</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Emergency Contact Relationship</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Citizenship</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Right to Work?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Needs Work Visa?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Has Work Visa?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Visa Type</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          NINO</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Proof of Right to Work</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Self Employed?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Has Valid Passport?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Passport Expiry Date</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Passport Number</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Has Valid UK Driving Licence?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Has Vehicle?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Has Business Insurance?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Has Unspent Criminal Convictions?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Details</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Awaiting Court Appearance?</th>
        <th
          style="overflow: hidden; white-space: nowrap; border-style: solid; border-color: gray; border-width: 0.5px;">
          Has Background Check?</th>
      </thead>
      <tbody>
        <tr v-for="client in clients" :key="client.id">
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" style="width: auto; border: none;" v-model="client.firstName" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" style="width: auto; border: none;" v-model="client.lastName" />
          </td>
          <td
            style="position: sticky; left:0; z-index:99; background-color: white; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" style="width: auto; border: none;" v-model="client.stageName" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" style="width: auto; border: none;" v-model="client.spotlightPIN" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" style="width: auto; border: none;" v-model="client.username" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <select v-model="client.type" @change="edit(client)">
              <option v-for="(t, i) in types" :key="i" :value="i">{{ t }}</option>
            </select>
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox v-model="client.onboarded" disabled />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <DatePicker @change="edit(client)" format="dd/MM/yyyy" style="width:max-content !important"
              v-model="client.dateOfBirth"></DatePicker>
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.phoneNumber" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.address.line1" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.address.line2" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.address.line3" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.address.town" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.address.county" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.address.postcode" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.emergencyContact.firstName" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.emergencyContact.lastName" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.emergencyContact.phoneNumber" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.emergencyContact.relationship" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.rightToWork.citizenship" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.rightToWork.hasRightToWork"
              :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.rightToWork.requiresWorkVisa"
              :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.rightToWork.hasWorkVisa" :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.rightToWork.visaType" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.rightToWork.nino" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.rightToWork.rightToWorkProof" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.rightToWork.selfEmployed" :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.travel.validPassport" :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.travel.passportExpiry" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.travel.passportNumber" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.travel.validUKDrivingLicence"
              :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.travel.hasVehicle" :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.travel.hasBusinessInsurance"
              :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.criminalHistory.unspentCriminalConvictions"
              :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; border-style: solid; border-color: gray; border-width: 0.5px;">
            <input @blur="edit(client)" :disabled="!client.onboarded" style="width: auto; border: none;"
              v-model="client.criminalHistory.detail" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.criminalHistory.awaitingCourtAppearance"
              :disabled="!client.onboarded" />
          </td>
          <td style="width: auto; text-align: center; border-style: solid; border-color: gray; border-width: 0.5px;">
            <Checkbox @change="edit(client)" v-model="client.criminalHistory.hasBackgroundCheck"
              :disabled="!client.onboarded" />
          </td>
        </tr>
      </tbody>

    </table>
  </div>
</template>
<script>
import { Checkbox, DatePicker } from 'element-ui'

export default {
  data() {
    return {
      clients: [],
      types: {},
      dateOptions: {
        disabledDate(date) {
          return date < new Date();
        },
      },
    };
  },
  async mounted() {
    this.clients = await this.get('Client');
    this.types = await this.get('Client/Types');
  },
  methods: {
    typeModel(t) {
      return this.types[t];
    },
    date(d) {
      return this.dateFormat(d);
    },
    async edit(client) {
      client.type = Number(client.type)
      await this.postBody('Client/Update', client)
    }
  },
  components: { Checkbox, DatePicker }
}
</script>
<style>
input:focus {
  border-color: blue !important;
  border-width: 3px;
}

.el-checkbox.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409EFF;
  border-color: #409EFF;
}

.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
  border-color: white;
}
</style>
