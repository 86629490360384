import { render, staticRenderFns } from "./ClientPayView.vue?vue&type=template&id=16230a8a&"
import script from "./ClientPayView.vue?vue&type=script&lang=js&"
export * from "./ClientPayView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports