<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate" title="Test">
      <h5 class="text-center">Let's see who we're talking about...</h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="firstName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="firstName"
              :error="failed ? 'The First Name field is required' : null"
              :hasSuccess="passed"
              placeholder="First Name (required)"
              v-model="firstName"
              addon-left-icon="nc-icon nc-single-02"
            >
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="lastName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="lastName"
              :error="failed ? 'The Last Name field is required' : null"
              :hasSuccess="passed"
              placeholder="Last Name (required)"
              v-model="lastName"
              addon-left-icon="nc-icon nc-single-02"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <h5>Headshot</h5>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4">
            <Upload
              name="profilePic"
              :action="'/'"
              :http-request="uploadPic"
              class="avatar-uploader"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </Upload>
        </div>
        <div class="col-md-4"></div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { Upload } from "element-ui";
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  components: {
    Upload,
  },
  props: {
    canStoreImage: Boolean,
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      imageUrl: null,
      profilePic: null
    };
  },
  methods: {
    async uploadPic() {
      await fetch('/')
    },
    validate() {
      var item = {
        firstName: this.firstName,
        lastName: this.lastName,
        profilePic: this.profilePic,
      };

      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", item);
        return res;
      });
    },
    async handleAvatarSuccess(res, file) {
      this.profilePic = file
      this.imageUrl = URL.createObjectURL(file.raw);
    },
  },
};
</script>
<style>
@import url("//unpkg.com/element-ui@2.15.8/lib/theme-chalk/index.css");
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 250px;
  height: 500px;
  display: block;
}
</style>
