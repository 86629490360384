var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('span',[_vm._v("Project:")])]),_c('div',{staticClass:"col-md-10"},[_c('ValidationProvider',{attrs:{"name":"projectName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"projectName","error":failed ? 'This field is required' : null,"hasSuccess":passed},model:{value:(_vm.projectName),callback:function ($$v) {_vm.projectName=$$v},expression:"projectName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('span',[_vm._v("Role:")])]),_c('div',{staticClass:"col-md-10"},[_c('ValidationProvider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"role","error":failed ? 'This field is required' : null,"hasSuccess":passed},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('span',[_vm._v("Recipient:")])]),_c('div',{staticClass:"col-md-10"},[_c('ValidationProvider',{attrs:{"name":"recipient","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"recipient","error":failed ? 'This field is required' : null,"hasSuccess":passed},model:{value:(_vm.recipient),callback:function ($$v) {_vm.recipient=$$v},expression:"recipient"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('span',[_vm._v("Email:")])]),_c('div',{staticClass:"col-md-10"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"email","error":failed ? 'This field must be a valid email address' : null,"hasSuccess":passed},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})]}}])})],1)]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('span',[_vm._v("Clients")])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ClientSelection',{attrs:{"commercial":"","canSubmit":false},on:{"list-changed":_vm.setClients}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"}),_c('div',{staticClass:"col-md-4"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.submit}},[_vm._v(" Submit ")])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }