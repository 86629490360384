<template>
  <Spinner v-if="loading"></Spinner>
  <card v-else class="card-tasks" title="Payslips" subTitle="Keep Track of Payments">
    <card v-for="payslip in payslips" :key="payslip.month">
      <h5>{{ payslip.month }} 2024</h5>
      <button class="btn btn-success"
        @click="$router.push(`finance/payslip?id=${payslip.agentId}&y=2024&m=${payslip.reference.split('-')[1]}`)"
        style="width: 100%">Open</button>
    </card>
  </card>
</template>
<script>
import Spinner from '../../../components/Spinner.vue'
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      loading: true,
      payslips: [],
    }
  },
  async mounted() {
    this.payslips = await this.get(`Commission/Agent/ByMonth?agentId=${this.getCookie('id')}`)
    this.loading = false
  }
}
</script>
