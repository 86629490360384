<template>
  <div class="container">
    <Spinner v-if="loading"></Spinner>
    <div v-else class="container">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <h4>{{ client.stageName }}</h4>
          <h6>{{ job.role.toUpperCase() }} in {{ job.name.toUpperCase() }}</h6>
        </div>
        <div class="col-md-2"></div>
      </div>

      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <video-player class="bigvid" :options="tape.videoOptions" />
        </div>
        <div class="col-md-2"></div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <button class="btn btn-success" style="width: 100%;" @click="download">Download</button>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>

  </div>
</template>
<script>
import Spinner from '../../components/Spinner.vue'
import VideoPlayer from '@/components/VideoPlayer.vue';

export default {
  components: { Spinner, VideoPlayer },
  props: {
    id: { type: String }
  },
  data() {
    return {
      loading: true,
      job: {},
      client: {},
      tape: {},
    }
  },
  async mounted() {
    this.job = await this.get(`Job/${this.id}`)
    this.client = await this.get(`Client/Id/${this.job.clientId}`)

    this.tape = {
      id: this.job.id,
      videoOptions: {
        autoplay: false,
        controls: true,
        preload: 'poster',
        sources: [
          {
            src:
              `https://storage.googleapis.com/public-store-castmate/tapes/${this.job.id}`,
            type: 'video/mp4'
          }
        ]
      }
    }
    this.loading = false

    fetch("https://api.ipify.org/")
      .then((res) => res.text())
      .then(async (ip) => {
        await this.post(`PageView/${ip}/Tape`);
      });
  },
  methods: {
    async download() {
      await fetch(`https://storage.googleapis.com/public-store-castmate/tapes/${this.job.id}`, {
        method: 'get',
        mode: 'cors',
        referrerPolicy: 'no-referrer',
        headers: {
          'Access-Control-Allow-Origin': '*',
        }
      })
        .then(res => {
          return res.blob()
        })
        .then(res => {
          res = res.slice(0, res.size, "video/mp4");
          const aElement = document.createElement('a');
          aElement.setAttribute('download', `${this.client.stageName.toUpperCase()} _ ${this.job.role} _ VELLA WOZNIAK TALENT AGENCY`);
          const href = URL.createObjectURL(res);
          aElement.href = href;
          aElement.setAttribute('target', '_blank');
          aElement.click();
          URL.revokeObjectURL(href);
        });
    }
  }
}
</script>
