<template>
    <div>
    </div>
</template>
<script>
export default {
    async mounted() {
        if (!this.getCookie("sgr")) {
            await fetch("https://oauth.accounting.sage.com/token", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json"
                },
                body: encodeURIComponent(`client_id=fcac3868-1c5a-4121-959b-dbc3650c87a8/1b5bc4fb-5f25-4b41-bf73-ee3e753f3714&client_secret=T>?bh.0Xo6Xq//JAO}@q&code=${this.code}&grant_type=authorization_code&redirect_uri=${window.location.origin}/sage/callback`)
            }).then(async (res) => res.json())
            .then((data) => {
                this.setCookie("sg", data.access_token, data.expires_in/86400)
                this.setCookie("sgr", data.refresh_token, data.refresh_token_expires_in/86400)
            });
        }

        this.$router.push('/admin/finance/hub')

    },
    props: {
        code: {
            type: String,
            default: "",
        },
        country: {
            type: String,
            default: "",
        }
    },
    data() {
        return {
            accounts: null
        }
    }
}
</script>
