<template>
    <card :style="`background-color: ${colour};`">
        <h4>{{ agentName }}</h4>
        <hr />
        <h5>Amount: {{ moneyFormat(invoice.amount, "GBP") }}</h5>
        <hr />
        <button v-if="payable && !paid" @click="payCommission(invoice)" class="btn btn-primary" style="width:100%">Pay</button>
    </card>
</template>
<script>
export default {
    props: {
        invoice: Object,
        title: String,
        colour: String,
        payable: Boolean,
    },
    data() {
      return {
        paid: this.invoice.paid,
        agentName: "",
      }
    },
    async mounted() {
      this.agentName = (await this.get(`Staff/Names`))[this.invoice.agentId]
    },
    methods: {
        async payCommission() {
            await this.post(`Commission/Pay?id=${this.invoice.id}`)
            .then(() => {
              this.paid = Date.now()
              this.$toastr.s('Commission Paid')
            })
            .catch((e) => this.$toastr.e(e));
        }
    }
}
</script>
