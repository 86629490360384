<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="row">
                    <div class="col-md-12">
                        <input style="width:100%" type="email" placeholder="Email" v-model="newStaff.username" />
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <input style="width:100%" placeholder="First Name" v-model="newStaff.firstName" />
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <input style="width:100%" placeholder="Last Name" v-model="newStaff.lastName" />
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <input style="width:100%" placeholder="Job Title" v-model="newStaff.jobTitle" />
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col-md-12">
                        <select style="width:100%" @change="selectAccessLevel">
                            <option value="">Access Level</option>
                            <option v-for="level in accessLevels" :key="level" :value="level">{{ level }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
                <Upload name="profilePic" :action="'/'" :http-request="uploadPic" class="avatar-uploader"
                    :show-file-list="false" :on-success="handleAvatarSuccess">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </Upload>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-8">

            </div>
            <div class="col-md-4">
                <button class="btn btn-primary" @click="create">Create</button>
            </div>
        </div>
    </div>
</template>
<script>
import { Upload } from "element-ui";

export default {
    components: {
        Upload,
    },
    data() {
        return {
            imageUrl: null,
            profilePic: null,
            accessLevels: {},
            newStaff: {
                username: "",
                firstName: "",
                lastName: "",
                jobTitle: "",
                password: "",
                accessLevel: 0,
            }
        }
    },
    async mounted() {
        this.accessLevels = await this.get('Staff/AccessLevels');
    },
    methods: {
        generatePassword() {
            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            let result = ' ';
            const length = 8;

            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * characters.length));
            }

            return result;
        },
        selectAccessLevel(e) {
            this.newStaff.accessLevel = Object.keys(this.accessLevels).find(key => this.accessLevels[key] === e.target.value);
        },
        async create() {
            this.newStaff.password = this.generatePassword();
            var userId = await this.postBody('Staff/Create', this.newStaff)

            if (userId) {
                this.profilePic.position = 0;
                await fetch(`https://storage.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_BUCKET}/o?name=headshots/${userId}&uploadType=media`, {
                    headers: new Headers({
                    'Authorization': `Bearer ${this.getCookie('token')}`,
                    'Content-Type': this.profilePic.raw.type
                    }),
                    method: "POST",
                    body: this.profilePic.raw,
                });

                this.send(
                  2,
                  [this.newStaff.username],
                  [],
                  [],
                  {
                    USERNAME: this.newStaff.username,
                    PASSWORD: this.newStaff.password,
                    URL: window.location.origin
                  }
                );

                await this.$toastr.s("Success");
            } else {
                await this.$toastr.e("Creation failed!");
            }
        },
        async uploadPic() {
            await fetch('/')
        },
        async handleAvatarSuccess(res, file) {
            this.profilePic = file
            this.imageUrl = URL.createObjectURL(file.raw);
        },
    }
}
</script>
