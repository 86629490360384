<template>
  <Spinner v-if="loading"></Spinner>
  <card v-else class="card-tasks" title="Upcoming Commission">
    <div>
      <hr />
      <div v-for="commission in commissions" :key="commission.id" class="container">
        <div class="row">
          <div class="col-md-4">
            <h5 class="pb-0 mb-0">{{ commission.projectName }}</h5>
            <small class="pt-0 mt-0">{{ commission.clientName }}</small>
          </div>
          <div class="col-md-8">
            <h5 class="pb-0 mb-0">{{ moneyFormat(commission.amount, "GBP") }}</h5>
            <small class="pt-0 mt-0">{{ commission.date }}</small>
          </div>
        </div>
        <div class="row">
          <hr />
        </div>
      </div>
    </div>
  </card>
</template>
<script>
import Spinner from "../../../components/Spinner.vue";
export default {
  components: {
    Spinner,
  },
  data() {
    return {
      loading: true,
      commissions: [],
    }
  },
  async mounted() {
    this.commissions = await this.get(`Commission/Unpaid/Agent/${this.getCookie('id')}`)

    for (var i = 0; i < this.commissions.length; i++) {
      const inv = await this.get(`Invoice/${this.commissions[i].invoiceId}`)
      this.commissions[i].projectName = (await this.get(`Job/${inv.jobId}`)).name
      this.commissions[i].clientName = (await this.get(`Client/Id/${inv.clientId}`)).stageName
      this.commissions[i].date = this.dateFormat(inv.dueDate)
    }

    this.loading = false
  }
}
</script>
