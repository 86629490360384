<template>
  <div class="container">
    <div v-if="!loading" class="row">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <stats-card :title="`£${totalIncome} / £${totalInvoiced}`" subTitle="Income">
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
        <stats-card :title="invoices.length.toString() || '0'" subTitle="Invoices">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-paper-2"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <Spinner v-if="loading" class="row"></Spinner>
    <div v-else class="row">
      <Table :columns="columns" :data="invoices" @edit="openInvoice" @print="print"></Table>
    </div>

    <el-dialog :visible.sync="viewInvoiceDetails">
      <ClientPayView :id="detailId" v-if="!loadingView"></ClientPayView>
      <Spinner v-else></Spinner>
    </el-dialog>

    <div v-if="printing" style="padding-top: 1000px;">
      <ClientRemittance id="client-letter" :invId="printId" v-if="printing"></ClientRemittance>
    </div>
  </div>
</template>
<script>
import { StatsCard, Table, Spinner } from "src/components/index";
import { Dialog } from 'element-ui'
import ClientPayView from "../../components/ClientPayView.vue";
import ClientRemittance from "../../components/Documents/ClientRemittance.vue";
export default {
  components: {
    StatsCard,
    Table,
    Spinner,
    [Dialog.name]: Dialog,
    ClientPayView,
    ClientRemittance
  },
  data() {
    return {
      loading: true,
      loadingView: true,
      printing: false,
      totalIncome: 0.00,
      totalInvoiced: 0.00,
      columns: ["id", "job", "date", "paid", "total", "view", "print"],
      invoices: [],
      viewInvoiceDetails: false,
      detailId: "",
      printId: ""
    }
  },
  async mounted() {
    this.invoices = await this.get(`Invoice/Client/${this.getCookie('id')}`)

    for (var i = 0; i < this.invoices.length; i++) {
      if (this.invoices[i].paid) {
        this.totalIncome += this.invoices[i].total
      }

      this.totalInvoiced += this.invoices[i].total

      this.invoices[i].date = this.dateFormat(this.invoices[i].date)
      this.invoices[i].total = this.moneyFormat(this.invoices[i].total, "GBP")
    }

    this.loading = false;
  },
  methods: {
    openInvoice(item) {
      this.viewInvoiceDetails = true;
      this.detailId = item.id;
      setTimeout(() => {
        this.loadingView = false;
      }, 2000)
    },
    print(inv) {
      this.printId = inv.id;
      this.printing = true;

      setTimeout(() => {
        const e = document.getElementById('client-letter');
        this.createDocument(e, "h", `Remittance - ${inv.job}`)
        this.printing = false;
      }, 2000);
    }
  }
}
</script>
