<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card>
                <div slot="header">
                  <div class="row">
                    <div class="col-md-1"></div>
                    <img :src="logo" class="col-md-10" />
                    <div class="col-md-1"></div>
                  </div>

                  <h3 class="card-title text-center">Staff Login</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="email"
                    rules="required|email"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="email"
                      :error="failed ? 'The Email field is required' : null"
                      :hasSuccess="passed"
                      label="Email address"
                      name="email"
                      v-model="email"
                    >
                    </fg-input>
                  </ValidationProvider>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                    class="row"
                  >
                    <fg-input
                      :type="!showPassword ? 'password' : ''"
                      :error="failed ? 'The Password field is required' : null"
                      :hasSuccess="passed"
                      name="password"
                      label="Password"
                      v-model="password"
                      class="col-md-9"
                    >
                    </fg-input>
                    <button class="col-md-3 btn btn-primary" style="height: fit-content; margin-top:9%;" type="button" @click="showPassword = !showPassword">Show</button>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <div  v-if="error">
                    <span>{{ error }}</span>
                  <br />
                  <br />
                  </div>
                  <button
                    type="submit"
                    class="btn btn-fill btn-info btn-round btn-wd"
                  >
                    Login
                  </button>
                  <br />
                  <div class="forgot">
                    <router-link to="/login" class="card-category">
                      Client Login
                    </router-link>
                  </div>
                </div>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  Checkbox as LCheckbox,
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  props: {
    code: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
      error: false,
      expiry: 0,
    };
  },
  computed: {
    logo() {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/logo/Main.png`
    }
  },
  async mounted() {
    if (this.code !== '') {
      await fetch(`https://oauth2.googleapis.com/token?code=${this.code}&redirect_uri=${window.location.origin}/login/staff&client_id=${process.env.VUE_APP_CLIENT_ID}&client_secret=${process.env.VUE_APP_CLIENT_SECRET}&grant_type=authorization_code`, {
        method: 'POST'
      })
      .then((res) => res.json())
      .then((data) => {
        this.expiry = (data.expires_in/86400);
        this.setCookie('token', data.access_token, this.expiry);
        this.setCookie('lg', 'true', this.expiry);
        this.$router.push("/");
      })
      .catch()
    } else {
      this.removeAllCookies();
    }
  },
  methods: {
    async submit() {
      var details = {
        username: this.email,
        password: this.password,
      };

      var res = await this.postBody(`Staff/Login`, details);
      if (res.success) {
      var staff = await this.get(`Staff/Username/${this.email}`);

      this.setCookie("id", staff.id, 1);
      this.setCookie("name", `${staff.firstName} ${staff.lastName}`, 1)
      this.setCookie("hs", staff.profilePic, 1);
      this.setCookie("si", staff.signableId, 1);
      this.setCookie("al", staff.access, 1);
      this.setCookie("e", staff.username, 1);
      
      window.location.replace(`https://accounts.google.com/o/oauth2/v2/auth?scope=https://mail.google.com+https://www.googleapis.com/auth/cloud-platform&response_type=code&redirect_uri=${window.location.origin}/login/staff&client_id=${process.env.VUE_APP_CLIENT_ID}`)
      } else {
        this.error = res.message
      }
    },
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
