<template>
  <div>
    <div class="row">
      <div class="col-md-10"></div>
      <div class="col-md-2">
        <button @click="print" :disabled="printing" class="btn btn-wd btn-warning" style="width: 100%;">Print</button>
      </div>
    </div>
    <button class="btn btn-wd btn-primary" style="width: 100%" @click="$router.push('/admin/clients/add')">
      Add New Client
    </button>
    <br />
    <Table id="client-list" filtered :columns="columns" :data="clients" @edit="openClient"></Table>

    <div id="report" style="width: 100%;" v-if="!printing">
      <h4 style="margin-top: 0px;">Client List</h4>
      <table style="width: 100%;">
        <tr class="report-line" style="border: grey solid 1px" v-for="client in clients" :key="client.name">
          <td>
            <img :src="`${cdn}${client.headshot}`" style="width: 50px; height: 50px;" />
          </td>
          <td style="text-align: left;">{{ client.name }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { Table } from "../../components";

export default {
  components: {
    Table,
  },
  data() {
    return {
      columns: ["headshot", "name", "onboarded", "spotlight", "edit"],
      clients: [],
      printing: false,
    };
  },
  computed: {
      cdn() {
        return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/`;
      }
    },
  async mounted() {
    await this.get(`Client/Agent/${this.getCookie('id')}`).then(async (data) => {
      for (var i = 0; i < data.length; i++) {
        this.clients.push({
          headshot: `headshots/${data[i].id}`,
          name: data[i].stageName,
          onboarded: data[i].onboarded,
          spotlight: data[i].spotlightPIN,
        });
      }
    });
  },
  methods: {
    openClient(e) {
      window.location.replace(`profile?id=${e.headshot.replace('headshots/', '')}`)
    },
    print() {
      this.printing = true;
      this.createDocument(document.getElementById('report'), "l", "Client_List")
      this.printing = false;
    }
  }
};
</script>
