<template>
  <div v-if="!loading" class="container">
    <div
      class="row"
      style="border-bottom: 1px solid black"
      v-for="application in applications"
      :key="application.id"
    >
      <div class="col-md-3">
        <img
          v-if="application.name !== 'ACCESS REQUEST'"
          class="headshot"
          :src="`https://storage.googleapis.com/public-store-castmate/applicationshots/${application.id}`"
        />
      </div>
      <div class="col-md-3">
        <span>{{ application.name }}</span>
      </div>
      <div class="col-md-3">
        <span v-html="application.message"></span>
        <br />
        <br />
        Spotlight:
        <a
          :href="`https://app.spotlight.com/${application.spotlightPIN}`"
          target="_blank"
          rel="noopener"
          >{{ application.spotlightPIN }}</a
        >
      </div>
      <div class="col-md-3">
        <div v-if="application.isContacted">
          <button
            class="btn btn-success"
            :disabled="application.tempPause"
            @click="accept(application)"
          >
            Accept
          </button>
        </div>
        <div v-if="!application.isContacted">
          <button
            @click="contact(application)"
            type="button"
            class="btn btn-warning"
          >
            Contact
          </button>
        </div>
        <button
          @click="decline(application)"
          type="button"
          class="btn btn-danger"
        >
          Decline
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      applications: [],
    };
  },
  async mounted() {
    this.applications = await this.get("Application");

    for (var i = 0; i < this.applications.length; i++) {
      const urls = [
        ...new Set(
          this.applications[i].message.match(
            /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi
          )
        ),
      ];

      for (var j = 0; j < urls.length; j++) {
        this.applications[i].message = this.applications[i].message.replaceAll(
          urls[j],
          ` <a href="${urls[j]}" target="_blank" rel="noopener">${urls[j]}</a> `
        );
      }

      this.applications[i].spotlightPIN = this.applications[i].spotlightPIN
        .replace("https://app.spotlight.com/", "")
        .replace("https://www.spotlight.com/", "")
        .replace("https://spotlight.com/", "");
    }
    this.loading = false;
  },
  methods: {
    async accept(application) {
      var body = {
        username: application.email,
        firstName: application.name.split(" ")[0],
        lastName: application.name.split(" ")[1] ?? "",
        spotlightPIN: application.spotlightPIN,
        clientType: 4,
        password: this.generatePassword(),
      };

      const userId = await this.postBody(`Client?contractSigned=true`, body);

      await fetch(
        `https://storage.googleapis.com/public-store-castmate/applicationshots/${application.id}`,
        {
          mode: "cors",
        }
      )
        .then((res) => res.blob())
        .then((res) => {
          application.profilePic = res;
        });

      await fetch(
        `https://storage.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_BUCKET}/o?name=headshots/${userId}&uploadType=media`,
        {
          headers: new Headers({
            Authorization: `Bearer ${this.getCookie("token")}`,
            "Content-Type": application.profilePic.type,
          }),
          method: "POST",
          body: application.profilePic,
        }
      );

      try {
        await this.postBodyVoid(`ClientDetails`, {
          id: userId,
          locations: [],
          gender: "",
          playingAgeLow: 0,
          playingAgeHigh: 0,
          height: 0,
          ethnicity: "",
          eyeColour: "",
          hairColour: "",
          showreel: "",
          cv: "",
        });
      } catch {}

      this.send(
        6,
        [
          {
            email: body.username,
            name: `${body.firstName} ${body.lastName}`,
          },
        ],
        [],
        [],
        {
          USERNAME: body.username,
          PASSWORD: body.password,
          URL: 'https://castmate.expression-agency.com/'
        }
      );

      await this.post(`Application/Approve?id=${application.id}`).then(() => {
        this.applications.splice(this.applications.indexOf(application), 1);
      });

      await this.$toastr.s("Success");
    },
    async contact(application) {
      this.send(
        7,
        [
          {
            email: application.email,
            name: application.name,
          },
        ],
        [],
        [],
        {
          NAME: application.name.split(" ")[0],
          AGENT: `${this.getCookie("name")
            .split(" ")[0]
            .toLowerCase()}@expression-agency.com`,
        }
      );
      await this.post(`Application/Contact?id=${application.id}`).then(() => {
        application.isContacted = true;
        application.tempPause = true;
        this.$forceUpdate();
      });

      await this.$toastr.s("Success");
    },
    async decline(application) {
      await this.post(`Application/Delete?id=${application.id}`).then(() => {
        this.applications.splice(this.applications.indexOf(application), 1);
      });
    },
    generatePassword() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = " ";
      const length = 8;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }

      return result;
    },
  },
};
</script>
