<template>
  <ul class="nav nav-mobile-menu text-white">
    <template slot="title">
      <i class="nc-icon nc-bullet-list-67"></i>
    </template>
    <a class="dropdown-item text-white" href="#">
      <i class="nc-icon nc-email-85"></i> &nbsp; {{notificationsCount}} {{notificationsCount === 1 ? 'Message' : 'Messages'}} </a
    ><a class="dropdown-item text-white" href="/support">
      <i class="nc-icon nc-support-17"></i> &nbsp; Support
    </a><a class="dropdown-item text-white" href="/settings">
      <i class="nc-icon nc-settings-90"></i> &nbsp; Settings
    </a>
    <hr />
    <a @click="logOut()" class="dropdown-item text-danger">
      <i class="nc-icon nc-button-power"></i> Log out
    </a>
  </ul>
</template>
<script>
export default {
  props: {
    notificationsCount: {
      type: Number,
      default: 0
    }
  },
  methods: {
    async logOut() {
      await this.removeAllCookies().then(() => {
        window.location.replace('/');
      })
    }
  }
}
</script>
