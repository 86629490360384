<template>
    <div>
        <Spinner v-if="loading"></Spinner>
        <div v-else>
            <div class="row">
                <div class="col-md-12">
                    <h5>Original Invoice</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <Table :columns="columns" :data="linkedInvoice.lines"></Table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5>Commission</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <Table :columns="commissionColumns" :data="commission"></Table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5>Your Payment</h5>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <Table :columns="columns" :data="invoice.lines"></Table>
                </div>
            </div>
            <br/>
            <hr/>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-6">
                    <h3>Total Earned</h3>
                </div>
                <div class="col-md-4">
                    <h3><b>£{{ invoice.total }}</b></h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Table from './Table.vue'
import Spinner from './Spinner.vue'
export default {
    components: {
        Table,
        Spinner,
    },
    props: {
        id: String,
    },
    data() {
        return {
            loading: true,
            commissionColumns: ['agent', 'percentage', 'total'],
            commission: [
                {
                    agent: 'Glynis Wozniak',
                    percentage: '',
                    total: 0.00
                }
            ],
            columns: ['quantity', 'description', 'net', 'total'],
            invoice: {},
            linkedInvoice: {}
        }
    },
    async mounted() {
        this.invoice = await this.get(`Invoice/${this.id}`)
        this.linkedInvoice = await this.get(`Invoice/${this.invoice.linkedInvoice}`)

        for (var i = 0; i < this.invoice.lines.length; i++) {
            this.invoice.lines[i].net = this.invoice.lines[i].net.toFixed(2)
            this.invoice.lines[i].total = this.invoice.lines[i].total.toFixed(2)
        }

        this.commission[0].total = (this.linkedInvoice.total - this.invoice.total).toFixed(2)
        this.commission[0].percentage = `${Math.round((this.commission[0].total/this.linkedInvoice.total) * 100)}%`

        this.loading = false;
    }
}
</script>
