<template>
  <div>
    <Spinner v-if="loading"></Spinner>
    <div v-else class="row">
      <div class="col-md-6">
        <div style="position: fixed; top:1; background-color: white; width: 40%;">
          <i v-html="lineBreak(breakdown.message)"></i>
          <br />
          <h3>{{ breakdown.project || "" }}</h3>
          <hr />
          <b v-html="lineBreak(breakdown.summary)"></b>
          <br />
          <br />
          <span v-html="processDetails(breakdown.details)"></span>
        </div>
      </div>
      <div class="col-md-6">
        <Card class="breakdown-role" :id="role.name" v-for="(role, i) in breakdown.roles" :key="role.name"
          :title="role.name">
          <span v-html="processDescription(role.description)"></span>
          <br />
          <hr />
          <br />
          <div v-if="role.suggestions.length > 0">
            <div class="row mb-3" v-for="sub in role.suggestions" :key="sub.id">
              <div class="col-md-2">
                <a href="https://www.spotlight.com/8218-3497-0502" target="_blank" tabindex="0">
                  <img
                    style="left: 50%;position: relative;top: 50%;-webkit-transform: translate(-50%,-50%);transform: translate(-50%,-50%);"
                    :src="headshot(sub.clientId)" :alt="client(sub.clientId).stageName">
                </a>
              </div>
              <div class="col-md-10">
                <a :href="`https://www.spotlight.com/${client(sub.clientId).spotlightPIN}`"
                  style="color: chocolate; font-weight: bolder;" target="_blank" tabindex="0">
                  {{ client(sub.clientId).stageName }}
                </a>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <button v-if="!role.suggesting" class="btn btn-primary" style="width: 100%;"
            @click="role.suggesting = true; $forceUpdate()">Submit Clients</button>
          <ClientSelection v-else @cancel="cancelSelection(role)" :preSelected="role.suggestions"
            @submit="submitClients($event, role, i); role.suggesting = false; $forceUpdate();"></ClientSelection>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import Card from '../../components/Cards/Card.vue'
import Spinner from '../../components/Spinner.vue'
import ClientSelection from '../../components/ClientSelection.vue'
export default {
  components: {
    ClientSelection,
    Spinner,
    Card,
  },
  props: {
    id: String,
  },
  data() {
    return {
      loading: true,
      clients: [],
      breakdown: {},
      roleOpen: false,
      roleName: "",
      roleDescription: ""
    }
  },
  async mounted() {
    this.breakdown = await this.get(`Breakdown/${this.$route.params.id}`)
    this.clients = await this.get('Client')

    for (var i = 0; i < this.breakdown.roles.length; i++) {
      this.breakdown.roles[i].suggesting = false;
      this.breakdown.roles[i].suggestions = await this.get(`Job/Submissions/Name/${encodeURIComponent(this.breakdown.project)}?role=${encodeURIComponent(this.breakdown.roles[i].name)}`)
    }

    this.loading = false
  },
  methods: {
    headshot(id) {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${id}`
    },
    lineBreak(text) {
      return text.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>')
    },
    processDetails(text) {
      return this.lineBreak(text)
        .replace("Casting director: ", "<b>Casting director: </b>")
        .replace("Casting location: ", "<b>Casting location: </b>")
        .replace("Casting details:", "<b>Casting details:</b>")
        .replace("Production dates:", "<b>Production dates:</b>")
        .replace("Casting type: ", "<br/><b>Casting type: </b>")
        .replace("Production location: ", "<b>Production location: </b>")
        .replace("Hiring preference: ", "<b>Hiring preference: </b>")
        .replace("Producer: ", "<b>Producer: </b>")
        .replace("Director: ", "<b>Director: </b>")
        .replace("Contract type: ", "<b>Contract type: </b>")
    },
    processDescription(text) {
      return this.lineBreak(text)
        .replace("Height: ", "<br/><b>Height: </b>")
        .replace("Gender: ", "<b>Gender: </b>")
        .replace("Appearance: ", "<b>Appearance: </b>")
        .replace("Contract type: ", "<b>Contract type: </b>")
        .replace("Contract info:", "<br/><b>Contract info:</b>")
    },
    suggestClients(role, i, open) {
      this.breakdown.roles = this.breakdown.roles.filter(function (r) { return r != role })
      role.suggest = open
      this.breakdown.roles.splice(i, 0, role)
      this.roleOpen = open

      this.roleName = role.name
      this.roleDescription = role.description

      document.getElementById(role.name).scrollIntoView();
    },
    client(id) {
      return this.filterByObject(this.clients, { id: id })[0]
    },
    async submitClients(e, role, num) {
      try {
        for (var i = 0; i < e.length; i++) {
          var client = this.filterByObject(this.clients, { id: e[i] })[0]

          this.send(1, [{
            email: client.username,
            name: client.stageName
          }], [], [], {
            ROLE: role.name,
            PROJECT: this.breakdown.project,
            DESCRIPTION: this.processDescription(role.description)
          });

          this.postBody('Job/Submission', {
            project: this.breakdown.project,
            role: role.name,
            clientId: e[i],
            description: role.Description
          })

          role.suggestions.push({
            clientId: e[i],
            created: new Date(),
            name: role.name
          })
        }

        if (e.length > 0) {
          await this.$toastr.s("Submission Successful")
        }

        this.suggestClients(role, num, false)
      }
      catch (ex) {
        await this.$toastr.e(ex)
      }
    },
    cancelSelection(role) {
      role.suggesting = false;
      this.$forceUpdate();
      document.getElementById(role.name).scrollIntoView();
    }
  }
}
</script>
<style>
.breakdown-role div .card-title {
  color: chocolate;
  font-weight: bolder;
}
</style>
