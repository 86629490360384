<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <card>
                    <div class="row">
                        <div class="col-md-10">
                            <h4>New Ticket</h4>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-md-3">
                            <span>Title</span>
                            <br/>
                            <br/>
                            <span>Priority</span>
                            <br/>
                            <br/>
                            <span>Description</span>
                        </div>
                        <div class="col-md-9">
                            <input v-model="ticket.title" style="width: 100%;" />
                            <br/>
                            <br/>
                            <select style="width:100%" v-model="ticket.priority">
                                <option v-for="(priority, i) in priorities" :key="priority" :value="i">{{ priority }}</option>
                            </select>
                            <br/>
                            <br/>
                            <textarea v-model="ticket.content" @input="this.autosize" style="width: 100%; height: auto; resize: none"></textarea>
                            
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <button @click="create" class="btn btn-primary" style="width: 100%;">Create</button>
                        </div>
                        <div class="col-md-4"></div>
                        
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                ticket: {
                    title: '',
                    content: '',
                    priority: 1,
                    userId: this.getCookie('id')
                },
                priorities: {}
            }
        },
        async mounted() {
            this.priorities = await this.get('Support/Priorities')
            delete this.priorities[0]
        },
        methods: {
            async create() {
                this.ticket.priority = Number(this.ticket.priority)
                await this.postBodyVoid('Support', this.ticket)
                await this.$toastr.s('Success')
                this.$router.push('/support')
            }
        }
    }
</script>