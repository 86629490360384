<template>
  <ClientSelection></ClientSelection>
</template>
<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import ClientSelection from "../../../components/ClientSelection.vue"

extend("required", required);

export default {
  components: {
    ClientSelection,
  },
  data() {
    return {
      clientName: "",
      clientId: "",
      clientList: []
    };
  },
  methods: {
    validate() {
      var item = {
        clientId: this.clientId
      };

      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", item);
        return res;
      });
    }
  },
};
</script>
