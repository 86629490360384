<template>
    <div class="container">
        <div v-if="clients.length === 0">
            <h4>No Accounts Need Matching</h4>
        </div>
        <div v-for="(client,i) in clients" :key="client.id" class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <card style="height: 90%;">
                    <h4>{{ client.stageName }}</h4>
                    <span>{{ client.address.line1 }}</span>, 
                    <span v-if="client.address.line2">{{ client.address.line2 }}, </span> 
                    <span>{{ client.address.town }}</span>, 
                    <span>{{ client.address.postcode }}</span>

                </card>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
                <card style="height: 90%;">
                    <div class="row">
                        <button :class="`col-md-6 btn ${client.selecting ? 'btn-warning' : 'btn-primary'}`" @click="openSelector(i, client.selecting)">Match</button>
                        <button :class="`col-md-6 btn ${client.creating ? 'btn-success' : 'btn-primary'}`" @click="openCreator(i, client.creating)">Create</button>
                    </div>
                    <br/>
                    <div v-if="client.selecting">
                        <div class="row">
                            <div class="col-md-3"></div>
                            <select class="col-md-6" v-model="client.selectedAccount" style="max-width: 100%;">
                                <option></option>
                                <option v-for="account in accounts" :key="account.id" :value="account.id">{{
                                    account.displayed_as }}</option>
                            </select>
                            <div class="col-md-3"></div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <button class="col-md-6 btn btn-warning" @click="assignAccount(i)">
                                Match
                            </button>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                    <div v-if="client.creating">
                        <div class="row">
                            <div class="col-md-4">
                                <span>Label: </span>
                            </div>
                            <div class="col-md-8">
                                <input v-model="client.accountCode" />
                            </div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <button class="col-md-6 btn btn-success" @click="create(client)">
                                Create
                            </button>
                            <div class="col-md-3"></div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            clients: [],
            accounts: [],
        }
    },
    async mounted() {
        this.clients = await this.get('Client/Unmatched')

        for (var i = 0; i < this.clients.length; i++) {
            this.clients[i].selecting = false;
            this.clients[i].creating = false;
            this.clients[i].selectedAccount = "";
            this.clients[i].accountCode = this.accountCode(this.clients[i].stageName);
        }

        await fetch(`${process.env.VUE_APP_API}Invoice/Suppliers`, {
            method: "GET",
            headers: {
                "AT": process.env.VUE_APP_TOKEN,
                "SAT": this.getCookie('sg')
            }
        }).then(async (response) => {
            this.accounts = (await response.json()).$items;
        });
    },
    methods: {
        openSelector(i, selecting) {
            this.clients[i].selecting = !selecting;
            this.clients[i].creating = selecting;
            this.$forceUpdate()
        },
        openCreator(i, creating) {
            this.clients[i].selecting = creating;
            this.clients[i].creating = !creating;
            this.$forceUpdate()
        },
        async assignAccount(i) {
            this.clients[i].sageId = this.clients[i].selectedAccount
            await this.postBody('Client/Update', this.clients[i])
            this.clients.splice(i,1)
            await this.$toastr.s('Success')
        },
        async create(client) {
            var data = {
                name: `${client.firstName} ${client.lastName}`,
                contact_type_ids: [
                    "VENDOR"
                ],
                reference: client.accountCode,
                default_sales_ledger_account_id:"bf411147bfde11ecb2850279d0cc53c5",
                main_address: {
                    address_line_1: client.address.line1,
                    address_line_2: client.address.line2,
                    city: client.address.town,
                    postal_code: client.address.postcode
                }
            }

            await fetch(`${process.env.VUE_APP_API}Invoice/Customers/Create`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AT": process.env.VUE_APP_TOKEN,
                    "SAT": this.getCookie('sg')
                },
                body: JSON.stringify(data)
            })
            .then(async (res) => {
                if (res.status === 201) {
                    client.sageId = client.selectedAccount
                    await this.postBody('Client/Update', client)
                    this.clients.splice(this.clients.indexOf(client),1)
                    await this.$toastr.s("Success")
                } else {
                    var e = await res.json();
                    this.$toastr.e(e[0].$message)
                }
            })
        },
        accountCode(name) {
            const names = name.split(' ')
            return `${names[0].split('')[0]}${names[1].split('')[0]}${names[1].split('')[1]}001`.toUpperCase()
        }
    }
}
</script>