<template>
    <Table :columns="columns" :data="values" @print="print"></Table>
</template>
<script>
import Table from "./Table.vue";
export default {
    components: {
        Table,
    },
    props: {
        invoices: Array,
    },
    data() {
        return {
            loading: true,
            columns: ["edit", "print", "reference", "date", "due", "total", "validated", "paid"],
            values: [],
        }
    },
    mounted() {
        for (var i = 0; i < this.invoices.length; i++) {
            this.values.push({
                edit: "",
                reference: this.invoices[i].reference,
                type: this.invoices[i].type,
                date: this.dateFormat(this.invoices[i].date),
                due: this.dateFormat(this.invoices[i].dueDate),
                total: `£${this.invoices[i].total}`,
                validated: !(!this.invoices[i].sageId),
                paid: this.invoices[i].paid,
                id: this.invoices[i].id,
            })
        }
    },
    methods: {
      print(item) {
        this.$emit('print', item)
      }
    }
}
</script>
