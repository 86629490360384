<template>
  <div class="container">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10 tscs" style="overflow: scroll; height: 600px;">
        <p class="mt-5 mb-0">This Agreement is made on {{ dateFormat(new Date()) }} (the "Effective Date"), by and between:
        </p>
        <p>Vella Wozniak LLP (t/a Expressions Agency) of 71-75 Shelton Street, London, WC2H 9JQ (the “Agent”) and The
          Undersigned (the "Talent")</p>
        <p>The Talent wishes to appoint the Agent to act on its behalf to procure opportunities for commercial
          performance work (hereafter referred to as “Work”) and the Agent wishes to accept that appointment. Therefore,
          in consideration of the promises, covenants and conditions contained herein, the Parties agree as follows:</p>

        <p class="bold mb-0">1. Appointment</p>
        <p><b>1.1</b> The Talent hereby appoints the Agent to act as their agent to identify, arrange and
          enter into engagements for Work on their behalf (“Engagements”), and to act in connection with all related
          and/or ancillary matters, including the corresponding with third parties, arranging, negotiating and entering
          into agreements and contracts, and promoting, marketing and advertising the services of the Talent, in
          relation thereto.</p>

        <p class="bold mb-0">2. Responsibilities</p>
        <p><b>2.1</b> Whilst the Agent shall use reasonable efforts to arrange Work for the Talent and promote the
          Talent, in relation to any potential Engagement, the Agent shall inform the Talent of the:</p>
        <ul>
          <li>identity of the person who wishes to engage the Talent</li>
          <li>nature of the Work</li>
          <li>start date and expected duration of the Engagement</li>
          <li>expected remuneration relating to the Engagement</li>
          <li>location where the Work is to be performed and the hours and dates during which it is to be performed</li>
          <li>any known risks to health or safety pertaining to the Work</li>
          <li>experience and such like that the Talent is required to possess</li>
          <li>how and by whom the Talent’s expenses will be met</li>
          <li>any other benefits which shall be provided to the Talent</li>
          <li>intervals at which they shall be paid</li>
          <li>any conditions relating to the Talent’s ability to terminate the Engagement prior to its completion</li>
          <li>any requirements arising pursuant to applicable law relating to the Engagement such as requirements to
            have immigration or working permits or consents</li>
        </ul>
        <p>However, if the Agent fails to identify any
          of the foregoing the Agent shall not be liable for such omission, it being the sole responsibility of the
          Talent to check, acquire professional advice upon, and ensure that all agreements relating to Engagements are
          acceptable to and fit the needs of the Talent. Where the Talent has any particular limitations, conditions or
          restrictions or requirements as regards the nature of Engagements that it will agree to, including in relation
          to any of the foregoing items or the requirements of any professional body or union of which the Talent is a
          member, the Talent must promptly notify the Agent so as to ensure the Agent does not seek Work that the Talent
          does not wish to undertake. Due to the nature of the Work, the Agent makes and/or gives no guarantees,
          representations, conditions or warranties that the Talent will be hired for Work or that the Agent shall be
          able to procure any Engagements.</p>
        <p><b>2.2</b> The Agent shall conduct business and perform this agreement in accordance with the Personal
          Managers’ Association Approved Code of Practice for Agents Representing Artists (the “Code of Practice”) and
          applicable laws. In an event of a conflict between the terms of this agreement and the Code of Practice, the
          Code of Practice shall take precedence.</p>
        <p><b>2.3</b> The Talent shall provide to the Agent all information and material, confirmations and other
          assistance as reasonably required by the Agent from time to time. </p>
        <p><b>2.4</b> The Talent shall promptly inform the Agent of any matter which arises from time to time and which
          may materially affect its ability to perform Work or Engagements.</p>

        <p class="bold mb-0">3. Responsibilities</p>
        <p><b>3.1</b> The Agent shall be entitled to receive a percentage (the "Agent's Commission" or simply the
          “Commission”) of all sums paid or payable to the Talent from, for or as a result of any Work or Engagement
          arranged or performed during the term of this agreement, including but not limited to commercial buyouts and
          option payments (the “Payments”).</p>
        <p><b>3.2</b> Payment of the Commission post-termination of this Agreement will be determined by section 6 and
          sections 8-11 of the Code of Practice.</p>
        <p><b>3.3</b> The rate of the Commission will be 20% of the gross payment.</p>

        <p class="bold mb-0">4. Termination</p>
        <p><b>4.1</b> Either party may terminate this Agreement with immediate effect by notice to the other party.</p>
        <p><b>4.2</b> Notwithstanding the termination of this agreement, the confidentiality and compensation provisions
          shall survive, along with any other provision of this agreement which is intended to survive termination.
          Termination of this agreement shall not affect the accrued rights or liabilities of the parties.</p>
        <p><b>4.3</b> Upon termination, the Agent will provide the Talent or their new representation with all relevant
          information as detailed in section 7 of the Code of Conduct.</p>

        <p class="bold mb-0">5. Confidentiality</p>
        <p><b>5.1</b> During the term of this Agreement and for one (1) year after termination thereof, the Talent shall
          not disclose the names of clients of the Agent or the name of other contractors or employees of the Agent to
          any third party without the prior written consent of the Agent.</p>
        <p><b>5.2</b> Any and all information, personal, professional or otherwise, given by the Talent to the Agent
          shall be treated as confidential by the Agent, and shall not be disclosed to any third party except as
          required by law or as reasonably required pursuant to the arrangement or performance of any Engagements. The
          restrictions in the foregoing clause shall not apply to any information already available to the public, or
          provided to the Agent from a third party.</p>

        <p class="bold mb-0">6. Independence</p>
        <p><b>6.1</b> The Agent is not an employment business for the purposes of the Employment Agencies Act 1973, The
          Conduct of Employment Agencies and Employment Businesses Regulations 2003, or other applicable law. The
          relationship between the parties will be that of independent contractor and nothing in this agreement shall
          render a party (or any person engaged by it) an employee, worker, of the other party and each party shall not
          hold itself out as such (and shall ensure and procure that no person engaged by it does the same). This
          agreement constitutes a contract for the provision of services and not a contract of employment and
          accordingly the Talent shall be fully responsible for the payment of any tax, contributions or other amounts
          due to any authority in connection with and arising from the performance of any Work, Engagement or this
          agreement and the receipt of any sums by or on behalf of the Talent. The Talent indemnifies and shall keep
          indemnified the Agent against all liability (whether actual or alleged) for payment of such taxes,
          contributions or amounts.</p>
        <p><b>6.2</b> The Talent warrants that it is registered with all relevant taxation authorities as having the
          status set out in this clause, and that it has paid (and shall pay by their due date) all taxes, contributions
          and other amounts due to such authorities, and that it is not in dispute or default with any such authorities.
          Where the Agent believes that the Talent may be considered an employee of the Agent, the Agent may immediately
          terminate this agreement, and the Talent indemnifies and shall keep indemnified the Agent against all loss,
          cost, expense, damage, claim or other liability (including claims for unfair dismissal, payment of taxation,
          social security or other amounts) arising by reason of that purported employment relationship or the
          termination thereof.</p>

        <p class="bold mb-0">7. Miscellaneous</p>
        <p><b>7.1</b> This agreement constitutes the entire agreement between the parties and supersedes and
          extinguishes all previous agreements, promises, assurances, warranties, representations and understandings
          between them, whether written or oral, relating to its subject matter. </p>
        <p><b>7.2</b> Each party agrees that it shall have no remedies in respect of any statement, representation,
          assurance or warranty (whether made innocently or negligently) that is not set out in this agreement. Each
          party agrees that it shall have no claim for innocent or negligent misrepresentation or negligent misstatement
          based on any statement in this agreement.</p>
        <p><b>7.3</b> No failure or delay by a party to exercise any right or remedy provided under this agreement or by
          law shall constitute a waiver of that or any other right or remedy, nor shall it prevent or restrict the
          further exercise of that or any other right or remedy. No single or partial exercise of such right or remedy
          shall prevent or restrict the further exercise of that or any other right or remedy.</p>
        <p><b>7.4</b> No variation of this agreement shall be effective unless it is in writing and signed by the
          parties.</p>
        <p><b>7.5</b> If any provision or part-provision of this agreement is or becomes invalid, illegal or
          unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid, legal and
          enforceable. </p>
        <p><b>7.6</b> If such modification is not possible, the relevant provision or part-provision shall be deemed
          deleted. Any modification to or deletion of a provision or part-provision under this clause shall not affect
          the validity and enforceability of the rest of this agreement. If one party gives notice to the others of the
          possibility that any provision or part-provision of this agreement is invalid, illegal or unenforceable, the
          parties shall negotiate in good faith to amend such provision so that, as amended, it is legal, valid and
          enforceable, and, to the greatest extent possible, achieves the intended commercial result of the original
          provision.</p>
        <p><b>7.7</b> No one other than a party to this agreement, their successors and permitted assigns, shall have
          any right to enforce any of its terms.</p>
        <p><b>7.8</b> This Agreement shall be governed by and construed in accordance with the laws of England & Wales.
          The parties irrevocably agree that the courts of England shall have exclusive jurisdiction to settle any
          dispute or claim that arises out of or in connection with this agreement or its subject matter or formation
          (including non-contractual disputes or claims).</p>
        <p><b>7.9</b> Without prejudice to any other rights or remedies that a party may have, each party acknowledges
          and agrees that damages alone would not be an adequate remedy for any breach of the terms of this agreement by
          the other party. Accordingly, each party shall be entitled to the remedies of interdict, injunction, specific
          performance or other equitable or discretionary relief for any threatened or actual breach of the terms of
          this agreement.</p>
        <p><b>7.10</b> This agreement may be executed in any number of counterparts and by each of the parties on
          separate counterparts, all of which taken together will constitute one single agreement between the parties.
          An electronic copy of a signature received in ‘Portable Document Format’ (PDF) or a copy of a signature
          received via a fax machine shall be deemed to be of the same force and effect as an original signature on an
          original executed document.</p>
        <p><b>7.11</b> All notices under this Agreement shall be served if sent by overnight courier, to the addresses
          set out in this agreement, or by email to the addresses set out herein, or such other place as either party
          may provide by prior written notice to the other party at the address above. Notices shall be deemed served on
          expiry of a period of five (5) days into the care of the courier or (if earlier) at the date the delivery
          receipt is signed, and if sent by email on despatch provided always that a successful routing and (if
          available) delivery receipt is obtained, and no failure message is issued, in relation to the email</p>
      </div>
      <div class="col-md-1"></div>
    </div>
    <br/>
    <div class="row">
      <div class="col-md-9"></div>
      <div class="col-md-2">
        <button class="btn btn-success" style="width: 100%;" @click="$emit('accept')">Accept</button>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
      accept() {

      },
      cancel() {

      }
    }
  }
</script>

<style>
.tscs p,
.tscs ul {
  font-size: 1.6vh !important;
}
</style>
