<template>
  <card :style="`background-color: ${colour};`">
    <h4>{{ title }}</h4>
    <a :href="`/admin/jobs/profile?id=${invoice.jobId}`"
      ><small>Go to Job</small></a
    >
    <hr />
    <h5>Due: {{ dateFormat(invoice.dueDate) }}</h5>
    <br />
    <h6>Total: {{ moneyFormat(invoice.total, "GBP") }}</h6>
    <h6>Paid: {{ moneyFormat(invoice.totalPaid, "GBP") }}</h6>
    <br />
    <h5>
      Remaining: {{ moneyFormat(invoice.total - invoice.totalPaid, "GBP") }}
    </h5>
    <hr />
    <div v-if="invoice.type === 0">
      <button
        class="btn btn-info"
        @click="printingInv = true"
        style="width: 100%"
      >
        Send
      </button>
    </div>
    <div v-if="!invoice.sageId">
      <button
        class="btn btn-success"
        @click="openValidationModal(invoice)"
        style="width: 100%"
      >
        Load to Sage
      </button>
    </div>
    <div v-else>
      <button
        v-if="!invoice.paid"
        @click="setInvoiceToPay(invoice)"
        class="btn btn-primary"
        style="width: 100%"
      >
        Pay
      </button>
      <br />
      <a
        :href="`https://accounts-extra.sageone.com/invoicing/sales_invoices/${invoice.sageId}`"
        target="_blank"
        rel="noopener"
      >
        <button class="btn btn-warning" style="width: 100%">View</button>
      </a>
    </div>
    <el-dialog :visible.sync="printingInv">
      <button class="btn btn-success" style="width: 100%" @click="sendInvoice">
        Send
      </button>
      <button class="btn btn-warning" style="width: 100%" @click="printInvoice">
        Print
      </button>
      <br />
      <br />
      <Invoice id="invoice" :inv="invoice.id" v-if="printingInv"></Invoice>
    </el-dialog>
  </card>
</template>
<script>
import { Dialog } from "element-ui";
import Invoice from "../components/Documents/Invoice.vue";

export default {
  components: {
    [Dialog.name]: Dialog,
    Invoice,
  },
  props: {
    invoice: Object,
    title: String,
    colour: String,
  },
  data() {
    return {
      printingInv: false,
    };
  },
  methods: {
    openValidationModal(invoice) {
      this.$emit("validate", invoice);
    },
    setInvoiceToPay(invoice) {
      this.$emit("pay", invoice);
    },
    printInvoice() {
      setTimeout(() => {
        const e = document.getElementById("invoice");
        this.createDocument(e, "h", `${this.invoice.job.name} - Invoice`);
        this.printingInv = false;
      }, 1500);
    },
    async sendInvoice() {
      const client = await this.get(`Client/Id/${this.invoice.clientId}`);
      const buyer = await this.get(`Buyer/Id/${this.invoice.job.buyerId}`);

      if (buyer.email === "" || buyer.email === null) {
        await this.$toastr.e("No buyer email available!");
        return;
      }

      var agentCC = {};
      if (client.type === 4) {
        agentCC =  {
              email: "hello@expression-agency.com",
              name: "Expression Commercials Agency"
            };
      } else {
        const agent = await this.get(`Staff/Id/${client.agentId}`);
        agentCC = {
          email: agent.username,
          name: `${agent.firstName} ${agent.lastName}`
        }
      }

      this.send(
        4,
        [{
          email: buyer.email,
          name: buyer.name
        }],
        [
        {
          email: "finance@vwtalent.com",
          name: "Finance Team"
        },
        agentCC],
        [],
        {
          CLIENT: client.stageName,
          INVNO: `#${this.invoice.id
            .split("-")[1]
            .toUpperCase()}${this.invoice.id.split("-")[2].toUpperCase()}`,
          PROJECT: this.invoice.job.name,
          TOTAL: this.moneyFormat(this.invoice.total, "GBP"),
          URL: `${window.location.origin}/public/invoice?id=${this.invoice.id}`
        }
      );

      await this.$toastr.s("Success");
    },
  },
};
</script>
