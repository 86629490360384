<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <stats-card :title="submissions.length.toString()" subTitle="Submissions">
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-chart text-warning"></i>
          </div>
          <template slot="footer">
            <i class="fa fa-refresh"></i>Updated now
          </template>
        </stats-card>
      </div>
      <div class="col-xl-9 col-md-6">
        <Table :columns="columns" :data="submissions"></Table>
      </div>
    </div>
    <el-dialog title="Set Up A New Password" :visible.sync="changePassword">
      <label for="old-password">Old Password:&nbsp;&nbsp;</label>
      <input id="old-password" type="password" v-model="oldPassword" style="width: 100%" />
      <br />
      <br />
      <label for="new-password">New Password:&nbsp;&nbsp;</label>
      <input id="new-password" type="password" v-model="newPassword" style="width: 100%" />
      <br />
      <br />
      <label for="confirm-password">Confirm Password:&nbsp;&nbsp;</label>
      <input id="confirm-password" type="password" v-model="confirmPassword" style="width: 100%" />
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-success" @click="setNewPassword">Confirm</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  StatsCard,
  Table,
} from "src/components/index";
import { Dialog } from 'element-ui'

export default {
  components: {
    StatsCard,
    Table,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      columns: ["id", "name", "role", "status"],
      submissions: [],
      changePassword: false,
    }
  },
  async mounted() {
    if (this.getCookie("al") > 0) {
      this.$router.push("admin/overview")
    }

    await this.get(`Password/Reset/Latest?userId=${this.getCookie('id')}`)
    .catch(() => {
      this.changePassword = true
    });

    var subs = await this.get(`Job/Client/${this.getCookie('id')}`)
    const statuses = await this.get('Job/Statuses');
    
    for (var i = 0; i < subs.length; i++) {
      this.submissions.push({
        id: subs[i].id,
        name: subs[i].name,
        role: subs[i].role,
        status: statuses[subs[i].status]
      })
    }
  },
  methods: {
    async setNewPassword() {
      if (this.newPassword.length < 5) {
        await this.$toastr.e("Password must be longer than 5 characters!")
        return
      }

      if (this.newPassword === this.oldPassword) {
        await this.$toastr.e("Password must not be the same as the old one!")
        return
      }

      if (this.newPassword !== this.confirmPassword) {
        await this.$toastr.e("Passwords do not match!")
      } else {
        var u = await this.postVoid(`Client/Password/New?userId=${this.getCookie('id')}&oldPassword=${this.oldPassword}&newPassword=${this.newPassword}`)
        if (u) {
          var r = await this.postVoid(`Password/Record?userId=${this.getCookie('id')}`)
          await this.$toastr.s("Password Updated!")
          this.changePassword = false;
        } else {
          await this.$toastr.e('Your old password is incorrect')
        }
      }
    }
  }
}
</script>