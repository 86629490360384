<template>
  <div class="container" style="color:black; margin-left: 0.5%; margin-right: 1%;">
          <div class="row">
            <div class="col-md-4"></div>
            <div class="col-md-4" style="text-align: center;">
              <h5 class="bold">Booking Form - {{ job.name }}</h5>
            </div>
            <div class="col-md-4"></div>
          </div>
          <div class="row" style="border: 1px solid black">
            <div class="col-md-4" style="background-color: lightgrey;border: 1px solid black;">
              <b>Project Name</b>
              <br />{{ job.name }}
              <br />
              &nbsp;
            </div>
            <div class="col-md-4" style="background-color: lightgrey;border: 1px solid black;">
              <b>Client Name</b>
              <br />{{ client.stageName }}
              <br />
              &nbsp;
            </div>
            <div class="col-md-4" style="background-color: lightgrey;border: 1px solid black;">
              <b>Dates</b>
              <br />{{ dateFormat(job.startDate) }} - {{ dateFormat(job.endDate) }}
              <br />
              &nbsp;
            </div>
          </div>
          <div class="row" style="border: 1px solid black">
            <div class="col-md-3" style="border: 1px solid black">
              <b>Project Description</b>
            </div>
            <div class="col-md-9" style="border: 1px solid black">
              <span>This is a test description. This is a test description. This is a test description. This is a test
                description. This is a test description. This is a test description. This is a test description. </span>
              <br />
              &nbsp;
            </div>
          </div>
          <div class="row" style="border: 1px solid black">
            <div class="col-md-3" style="border: 1px solid black">
              <b>Financials</b>
            </div>
            <div class="col-md-9" style="border: 1px solid black; text-wrap:pretty">
              <span>
                The Agency will be entitled to collect 20% of the contract value, excluding certain exempt fees
                intended to cover costs including but not limited to recall and wardrobe fees and recouped costs such as
                travel and, where specified, subsistence.
              </span>
              <br />
              <br />
              <span>
                All fees will be payable on receipt of payment into the Agency's Client Trust Account (the "Account")
                and will then
                be remitted to the Artist.
              </span>
              <br />
              &nbsp;
            </div>
          </div>
        </div>
</template>
<script>
export default {
  props: {
    job: Object,
    client: Object,
  }
}
</script>
