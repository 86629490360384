<template>
  <div>
    <Spinner v-if="!loaded"></Spinner>
    <Table filtered v-else :columns="columns" :data="jobs" @edit="openJob"></Table>
  </div>
</template>
<script>
import { Spinner, Table } from "../../components";

export default {
  components: {
    Table,
    Spinner,
  },
  props: {
    type: String,
  },
  data() {
    return {
      columns: ["name", "client", "buyer", "role", "startDate", "endDate", "status", "edit"],
      jobs: [],
      loaded: false,
      minStatus: 0,
      maxStatus: 0,
      jobType: ''
    };
  },
  async created() {
    this.jobType = this.$route.params.type
    await this.setList();
  },
  watch: {
    '$route.params.type': async function(newType, oldType) {
      this.loaded = false
      this.jobType = newType
      await this.setList()
    }
  },
  methods: {
    openJob(e) {
      window.location.replace(`profile?id=${e.id}`)
    },
    async setList() {
      const statuses = await this.get('Job/Statuses');
      var clients = await this.get('Client/Names')

      switch(this.jobType) {
        case 'auditions':
          this.minStatus = 1;
          this.maxStatus = 4;
          break;
        case 'current':
          this.minStatus = 5;
          this.maxStatus = 6;
          break;
        case 'completed':
          this.minStatus = 7;
          this.maxStatus = 8;
          break;
        default:
          return;
      }
      
      await this.get(`Job/Status?min=${this.minStatus}&max=${this.maxStatus}`).then(async (data) => {
        for (var i = 0; i < data.length; i++) {
          data[i].client = clients[data[i].clientId];
          data[i].status = statuses[data[i].status]
        }
        this.jobs = data;

        this.loaded = true;
      })
    }
  }
};
</script>
