<template>
  <router-view v-slot="route">
    <transition :name="route.meta.transition || 'fade'" mode="out-in" >
      <keep-alive>
        <suspense>
          <template #default>
            <component
              :is="Component"
              :key="$route.name"
            />
          </template>
          <template #fallback> Loading... </template>
        </suspense>
      </keep-alive>
    </transition>
  </router-view>
</template>

<script>
//import NotificationSample from 'src/pages/Dashboard/Components/NotificationSample';
export default {
  created() {
    if (this.$route.name && this.$route.name.includes('Public-')) {
      return
    }

    if (this.getCookie("lg") || this.$route.name === "Reset Password" || this.$route.name === "Login") {
    } else {
      this.$router.push("/login");
    }
  }
};
</script>
