<template>
    <div>
        <div class="row">
            <div class="col-md-3">
                <b>Invoice Date:</b>
            </div>
            <div class="col-md-3">
                <span>{{ dateFormat(invoice.date) }}</span>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-3">
                <b>Reference:</b>
            </div>
            <div class="col-md-3">
                <span>{{ invoice.reference }}</span>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-3">
                <b>Customer:</b>
            </div>
            <div class="col-md-9">
                <select v-model="validatingContact" style="max-width: 100%;">
                    <option></option>
                    <option v-for="customer in customers" :key="customer.id" :value="customer.id">{{
                        customer.displayed_as }}</option>
                </select>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-3">
                <b>Lines:</b>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-2">
                <b>Quant.</b>
            </div>
            <div class="col-md-3">
                <b>Description</b>
            </div>
            <div class="col-md-3">
                <b>Account</b>
            </div>
            <div class="col-md-2">
                <b>Net</b>
            </div>
            <div class="col-md-2">
                <b>Total</b>
            </div>
        </div>
        <div class="row" v-for="line in invoice.lines" :key="line.description">
            <div class="col-md-2">
                <span>{{ line.quantity }}</span>
            </div>
            <div class="col-md-3">
                <span>{{ line.description }}</span>
            </div>
            <div class="col-md-3">
                <select v-model="line.sageAccount" style="max-width: 100%;">
                    <option v-for="account in accounts" :key="account.id" :value="account.id">{{
                        account.displayed_as }}</option>
                </select>
            </div>
            <div class="col-md-2">
                <span>{{ line.net }}</span>
            </div>
            <div class="col-md-2">
                <span>{{ line.total }}</span>
            </div>
        </div>
        <hr />
        <div class="row">
            <button :disabled="validatingContact === ''" @click="validateInvoice()" class="btn btn-success"
                style="width: 100%;">Validate</button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        invoice: Object,
        customers: Array,
        accounts: Array,
    },
    data() {
        return {
            validatingContact: "",
        }
    },
    methods: {
        async validateInvoice() {
            var lines = []

            for (var i = 0; i < this.invoice.lines.length; i++) {
                lines.push({
                    quantity: this.invoice.lines[i].quantity,
                    description: this.invoice.lines[i].description,
                    sageAccount: this.invoice.lines[i].sageAccount,
                    net: this.invoice.lines[i].net.toFixed(2),
                    vat: this.invoice.lines[i].vat.toFixed(2)
                })
            }

            await fetch(`${process.env.VUE_APP_API}Invoice/Validate/${this.invoice.id}?contactId=${this.validatingContact}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "AT": process.env.VUE_APP_TOKEN,
                    "SAT": this.getCookie('sg')
                },
                body: JSON.stringify({
                    lines: lines,
                })
            })
                .then((response) => response.json())
                .then((d) => {
                    if (d.id) {
                        this.$toastr.s('Success');
                        window.location.reload();
                    } else {
                        for (var i = 0; i < d.length; i++) {
                            this.$toastr.e(d[i].$message)
                        }
                    }
                })
        },
    }
}
</script>
