<template>
  <div class="container">
    <h4>Submissions for {{ project.projectName }}</h4>
    <Spinner v-if="loading"></Spinner>
    <div v-else class="row">
      <div class="col-md-3" v-for="client in project.clients" :key="client.clientId">
        <card>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <img class="headshot" :src="headshot(client.clientId)" />
            </div>
            <div class="col-md-2"></div>
          </div>
          <br />
          <span>{{ client.name }}</span>
          <br />
          <br />
          <button class="btn btn-primary" @click="view(client)">View</button>&nbsp;
          <a :href="`mailto:hello@expression-agency.com?subject=${client.name.toUpperCase()} Requested for ${project.projectName.toUpperCase()}`"
            target="_blank" rel="noopener">
            <button class="btn btn-success">Request</button>
          </a>
        </card>
      </div>
    </div>
    <el-dialog :title="selectedClient.stageName" :visible.sync="viewModal">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <img class="headshot" :src="headshot(selectedClient.id)" />
          </div>
          <div class="col-md-8">
            <div class="row">
              <div class="col-md-6"><b>Bases:</b></div>
              <div class="col-md-6">
                <div class="container">
                  <div class="row" v-for="location in selectedClient.details.locations" :key="location">
                    {{ location }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">&nbsp;</div>
            </div>
            <div class="row">
              <div class="col-md-6"><b>Gender:</b></div>
              <div class="col-md-6">{{ selectedClient.details.gender }}</div>
            </div>
            <div class="row">
              <div class="col-md-6"><b>Playing age:</b></div>
              <div class="col-md-6">{{ selectedClient.details.playingAgeLow }} to {{
      selectedClient.details.playingAgeHigh }}</div>
            </div>
            <div class="row">
              <div class="col-md-6"><b>Height:</b></div>
              <div class="col-md-6">{{ selectedClient.details.height }}cm</div>
            </div>
            <div class="row">
              <div class="col-md-6"><b>Ethnicity:</b></div>
              <div class="col-md-6">{{ selectedClient.details.ethnicity }}</div>
            </div>
            <div class="row">
              <div class="col-md-6"><b>Eye Colour:</b></div>
              <div class="col-md-6">{{ selectedClient.details.eyeColour }}</div>
            </div>
            <div class="row">
              <div class="col-md-6"><b>Hair Colour:</b></div>
              <div class="col-md-6">{{ selectedClient.details.hairColour }}</div>
            </div>
            <div class="row" v-if="selectedClient.spotlightPIN || selectedClient.details.cv">
              <div class="col-md-6"><b>CV:</b></div>
              <div class="col-md-6" v-if="selectedClient.spotlightPIN">
                <a :href="`https://app.spotlight.com/${selectedClient.spotlightPIN}`" target="_blank"
                  rel="noopener">Spotlight</a>
              </div>
              <div class="col-md-6" v-else>
                <a v-if="selectedClient.details.cv" :href="selectedClient.details.cv" target="_blank"
                  rel="noopener">Link</a>
              </div>
            </div>
            <br />
            <hr />
            <div class="row">
              <div class="col-md-12">
                <a :href="`mailto:hello@expression-agency.com?subject=${selectedClient.stageName.toUpperCase()} Requested for ${project.projectName.toUpperCase()}`"
                  target="_blank" rel="noopener">
                  <button class="btn btn-success" style="width:100%;">Request</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Spinner from '../../components/Spinner.vue'
import { Dialog } from 'element-ui'
export default {
  components: {
    Spinner,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      loading: true,
      project: {},
      selectedClient: {
        stageName: "",
        details: {}
      },
      viewModal: false,
    }
  },
  props: {
    id: String
  },
  async mounted() {
    this.project = await this.get(`Submission/Commercial?id=${this.id}`)

    for (var i = 0; i < this.project.clients.length; i++) {
      this.project.clients[i].name = await this.simpleGet(`Client/Name?id=${this.project.clients[i].clientId}`)
    }

    this.loading = false;

    fetch("https://api.ipify.org/")
      .then((res) => res.text())
      .then(async (ip) => {
        await this.post(`PageView/${ip}/Submission`);
      });
  },
  methods: {
    headshot(id) {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${id}`
    },
    async clientName(id) {
      return
    },
    async view(client) {
      this.selectedClient = await this.get(`Client/Id/${client.clientId}`);
      this.selectedClient.details = await this.get(`ClientDetails/${client.clientId}`);
      this.viewModal = true;
    }
  }
}
</script>
