<template>
    <div class="container">
        <div class="row">
            <el-collapse style="width: 100%">
                <el-collapse-item v-for="month in months" :key="month[0].month" :title="month[0].month + ' 2023'" :name="month[0].month">
                <br/>
                <div class="row">
                    <div v-for="payslip in month" :key="payslip.reference" class="col-md-3">
                        <card>
                            <h5>{{ agentName(payslip.agentId) }}</h5>
                            <button class="btn btn-warning" @click="$router.push(`payslip?id=${payslip.agentId}&y=2023&m=${payslip.reference.split('-')[1]}`)" style="width: 100%">Open</button>
                        </card>
                    </div>
                </div> 
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>
<script>
import { Collapse, CollapseItem } from "element-ui";
export default {
    components: {
        [Collapse.name]: Collapse,
        [CollapseItem.name]: CollapseItem,
    },
    data() {
        return {
            months: [],
            staff: [],
        }
    },
    async mounted() {
        this.staff = await this.get('Staff');
        this.months = await this.get('Commission/Payslip?year=2023')
    },
    methods: {
        agentName(id) {
            var agent = this.filterList(id, "id", this.staff)[0];

            return `${agent.firstName} ${agent.lastName}`
        }
    }
}
</script>