<template>
    <div class="container">
        <div class="row">
            <button class="btn btn-primary" style="width: 100%;" @click="modal = true">Add New Staff Member</button>
        </div>
        <div class="row">
            <div v-for="s in staff" :key="s.id" class="col-md-4">
                <staff-card :s="s"></staff-card>
                <el-dialog title="Create a New Staff Account" :visible.sync="modal">
                    <staff-creator></staff-creator>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
import { Dialog } from 'element-ui'
import StaffCreator from '../../components/StaffCreator.vue';
import StaffCard from '../../components/Cards/StaffCard.vue';
export default {
    components: {
        [Dialog.name]: Dialog,
        StaffCreator,
        StaffCard,
    },
    data() {
        return {
            staff: [],
            modal: false,
        }
    },
    async mounted() {
        this.staff = await this.get('Staff');
    },
}
</script>