<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <span class="demonstration">Invoice Date</span>
        <el-date-picker v-model="newInvoice.date" type="date" placeholder="Datetime picker here"
          :picker-options="pickerOptions1">
        </el-date-picker>
      </div>

      <div class="col-md-4">
        <span class="demonstration">Due Date</span>
        <el-date-picker v-model="newInvoice.dueDate" type="date" :picker-options="pickerOptions1">
        </el-date-picker>
      </div>

      <div class="col-md-4">
        <span class="demonstration">Reference</span>
        <input type="text" placeholder="Reference" class="form-control" v-model="newInvoice.reference" />
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-md-6">
        <h5>Lines</h5>
      </div>

      <div class="col-md-4"></div>

      <div class="col-md-1">
        <button class="btn btn-primary" @click="addLine()">+</button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-1"></div>

      <div class="col-md-1">
        <span class="demonstration">Quantity</span>
      </div>

      <div class="col-md-4">
        <span class="demonstration">Description</span>
      </div>

      <div class="col-md-2">
        <span class="demonstration">Net</span>
      </div>

      <div class="col-md-2">
        <span class="demonstration">VAT</span>
      </div>

      <div class="col-md-2">
        <span class="demonstration">Total</span>
      </div>
    </div>
    <div class="row" v-for="line in newInvoice.lines" :key="line.number">
      <div class="col-md-1" style="text-align: right;">
        <Checkbox v-model="line.commission"></Checkbox>
      </div>

      <div class="col-md-1">
        <input type="text" placeholder="Quantity" class="form-control" v-model="line.quantity" />
      </div>

      <div class="col-md-4">
        <input type="text" placeholder="Description" class="form-control" v-model="line.description" />
      </div>

      <div class="col-md-2">
        <input type="text" placeholder="Net" class="form-control" v-model="line.net" />
      </div>

      <div class="col-md-2">
        <span class="form-control">{{ moneyFormat(lineVat(line.net), "GBP") }}</span>
      </div>

      <div class="col-md-2">
        <span class="form-control">{{ moneyFormat(lineTotal(line.quantity, line.net, lineVat(line.net)), "GBP") }}</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-10"></div>
      <div class="col-md-2">
        <span class="form-control">{{ moneyFormat(invoiceTotal, "GBP") }}</span>
      </div>
    </div>
    <br />
    <div v-if="misc" class="row">
      <div class="col-md-12">
        <Checkbox label="Invoice for agents?" v-model="directIncome"></Checkbox>
      </div>
    </div>
    <br />
    <br />
    <div v-if="!misc || directIncome" class="row">
      <div class="col-md-6">
        <h5>Commission</h5>
      </div>
    </div>
    <div v-if="!misc" class="row">
      <div class="col-md-6">
        <input type="text" disabled v-model="commissionValue" placeholder="Amount" class="form-control" />
      </div>
      <div class="col-md-6">
        <select class="form-control" v-model="commissionRate">
          <option :value="0">Rate</option>
          <option v-for="(t, i) in commissionRates" :key="i" :value="t">{{ t }}%</option>
        </select>
      </div>
    </div>
    <div v-if="misc && directIncome">
      <div v-if="remainingCommission > 0" class="row">
        <div class="col-md-12">
          <button class="btn btn-primary" @click="addAgentCommission">Add Commission</button>
        </div>
      </div>
      <div class="row" v-for="commission in commissions" :key="commission.agentId">
        <div class="col-md-6">
          <select class="form-control" v-model="commission.agentId">
            <option value="">Select Agent</option>
            <option v-for="agent in agents" :key="agent.id" :value="agent.id">{{ agent.firstName }} {{ agent.lastName }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <input v-model="commission.rate" class="form-control" />
        </div>
      </div>
    </div>
    <br />
    <hr />
    <div class="row">
      <div class="col-md-8"></div>
      <div class="col-md-4">
        <button class="btn btn-primary" @click="createInvoice">Create</button>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker } from 'element-ui'
import Checkbox from './Inputs/Checkbox.vue'

export default {
  components: {
    [DatePicker.name]: DatePicker,
    Checkbox,
    Checkbox
  },
  props: {
    id: String,
    title: String,
    cId: String,
    client: String,
    misc: Boolean,
  },
  data() {
    return {
      newInvoice: {
        date: new Date(),
        dueDate: new Date(),
        jobId: this.id,
        clientId: this.cId,
        reference: "",
        type: 0,
        lines: [
          {
            commission: true,
            quantity: 1,
            description: "",
            net: 0.00,
          }
        ]
      },
      pickerOptions1: {
        shortcuts: [{
          text: 'Today',
          onClick(picker) {
            picker.$emit('pick', new Date())
          }
        },
        {
          text: 'Yesterday',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        },
        {
          text: 'A week ago',
          onClick(picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      commissionRate: 0,
      commissionRates: [10, 20, 100],
      directIncome: false,
      commissionAgent: "",
      agents: [],
      commissions: [
        {
          agentId: "",
          rate: 100
        }
      ]
    }
  },
  async mounted() {
    var d = this.newInvoice.dueDate.setMonth(this.newInvoice.dueDate.getMonth() + 1);
    this.newInvoice.dueDate = new Date(d);

    if (this.misc) {
      this.agents = await this.get('Staff')
    }
  },
  computed: {
    commissionValue() {
      let value = 0;
      for (var i = 0; i < this.newInvoice.lines.length; i++) {
        if (this.newInvoice.lines[i].commission) {
          value += this.lineTotal(this.newInvoice.lines[i].quantity, this.newInvoice.lines[i].net, this.lineVat(this.newInvoice.lines[i].net))
        }
      }

      return this.moneyFormat((Math.round(((parseFloat(value) * (parseInt(this.commissionRate) / 100)) + Number.EPSILON) * 100) / 100), "GBP");
    },
    invoiceTotal() {
      let value = 0;

      for (var i = 0; i < this.newInvoice.lines.length; i++) {
        value += this.lineTotal(this.newInvoice.lines[i].quantity, this.newInvoice.lines[i].net, this.lineVat(this.newInvoice.lines[i].net))
      }

      return value;
    },
    remainingCommission() {
      if (this.commissions.length == this.agents.length) {
        return 0;
      }

      var total = 100;
      for (var i = 0; i < this.commissions.length; i++) {
        total = total - Number(this.commissions[i].rate);
      }

      return total;
    }
  },
  methods: {
    addLine() {
      this.newInvoice.lines.push({
        commission: true,
        quantity: 0,
        description: "",
        net: 0.00,
      })
    },
    lineVat(net) {
      return Math.round(((net * 0) + Number.EPSILON) * 100) / 100
    },
    lineTotal(quantity, net, vat) {
      return (parseFloat(net) + parseFloat(vat)) * quantity
    },
    async createInvoice() {
      for (var i = 0; i < this.newInvoice.lines.length; i++) {
        if (this.newInvoice.lines[i].quantity === 0) {
          await this.$toastr.e("Quantity must be more than 0")
          return
        }

        if (this.newInvoice.lines[i].description === "") {
          await this.$toastr.e("All descriptions must contain information")
          return
        }

        if (!this.misc) {
          this.newInvoice.lines[i].description = `${this.client} - ${this.newInvoice.lines[i].description}`
        }
      }

      if (this.misc) {
        this.newInvoice.type = 2

        if (this.commissions.reduce((a, b) => a + (b["rate"] || 0), 0) < 100) {
          this.$toastr.e("The total commission must be 100%")
          return
        }
      }

      var invoice = await this.postBody('Invoice', this.newInvoice)

      if (this.commissionRate > 0) {
        await this.postBodyVoid('Commission/Create', {
          amount: parseFloat(this.commissionValue.replace('£ ', '')),
          reference: this.title,
          invoiceId: invoice.id,
          agentId: this.getCookie('id')
        })
      }

      if (!this.misc) {
        var clientInvoice = this.newInvoice;
        clientInvoice.linkedInvoice = invoice.id;

        if (this.commissionRate !== 100) {
          clientInvoice.type = 1;

          for (var i = 0; i < clientInvoice.lines.length; i++) {
            clientInvoice.lines[i].description = clientInvoice.lines[i].description.replace(`${this.client} - `, '').trim()

            if (clientInvoice.lines[i].commission) {
              clientInvoice.lines[i].net -= (clientInvoice.lines[i].net * (this.commissionRate / 100))
            }
          }

          await this.postBody('Invoice', clientInvoice)
        }
      }

      if (this.directIncome) {
        for (var i = 0; i < this.commissions.length; i++) {
          await this.postBodyVoid('Commission/Create', {
            amount: parseFloat(this.invoiceTotal * (this.commissions[i].rate / 100)),
            reference: "",
            invoiceId: invoice.id,
            agentId: this.commissions[i].agentId
          })
        }

      }

      await this.$toastr.s("Success");
      window.location.reload();
    },
    addAgentCommission() {
      this.commissions.push({
        agentId: "",
        rate: this.remainingCommission
      })

      this.$forceUpdate()
    }
  },
}
</script>
