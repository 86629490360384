<template>
    <div>
        <br/>
        <div style="position: sticky; top:0; z-index:99; background-color: white;">
            <div class="row">
                <div class="col-md-1" style="padding: none;">
                    <div :style="`background-color: ${statusColour}; height: 100%; width: 75%; border-radius: 10px;`">&nbsp;</div> 
                </div>
                <div class="col-md-10">
                    <h4>#{{ticket.ticket}} - {{ ticket.title }}</h4>
                </div>
                <div class="col-md-1"></div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <span><b>{{ clients[ticket.userId] || staff[ticket.userId] }}</b> reported {{ daysSinceLogged === 0 ? 'today' : `${(daysSinceLogged === 1 ? '1 day' : `${daysSinceLogged} days`)} ago (${dateFormat(ticket.created)})`}}</span>
                </div>
                <div class="col-md-1"></div>
            </div>
            <br/>
            <br/>
        </div>
        <br/>
        <br/>
        <div class="row">
            <div class="col-md-8">
                <card style="background-color: #E5F2FD; border: 1px solid #BBDCFE; border-radius: 10px;">
                    <b>Description</b>
                    <br/>
                    <br/>
                    <span>{{ ticket.content }}</span>
                </card>
                <div v-if="ticket.updates.length <= 3 || expandedNotes">
                    <br/>
                    <card :style="`background-color: ${update.isPublic ? 'white' : '#FEF1E1; border: 1px solid #FDDBB5'}; border-radius: 10px;`" v-for="update in ticket.updates" :key="update.posted">
                        <span>{{ update.text }}</span>
                        <br/>
                        <br/>
                        <b>{{ dateFormat(update.posted) }} - {{ clients[update.userId] || staff[update.userId] }}</b>
                    </card>
                    <br/>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <button @click="expandedNotes = true" class="btn btn-warning" style="width: 100%;">{{ ticket.updates.length - 1 }} notes</button>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <br/>
                    <card :style="`background-color: ${ticket.updates[ticket.updates.length - 1].isPublic ? 'white' : '#FEF1E1; border: 1px solid #FDDBB5'}; border-radius: 10px;`" :key="ticket.updates[ticket.updates.length - 1].posted">
                        <span>{{ ticket.updates[ticket.updates.length - 1].text }}</span>
                        <br/>
                        <br/>
                        <b>{{ dateFormat(ticket.updates[ticket.updates.length - 1].posted) }} - {{ clients[ticket.updates[ticket.updates.length - 1].userId] || staff[ticket.updates[ticket.updates.length - 1].userId] }}</b>
                    </card>
                </div>
                
                <card style="background-color: lightgrey;">
                    <div v-if="publicNote || privateNote">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-10">
                                <textarea @input="this.autosize" v-model="noteText" :style="`width: 100%; background-color: ${textboxColour}`"></textarea>
                            </div>
                            <div class="col-md-1"></div>
                        </div>
                        <br/>
                        <div class="row">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">
                                <button @click="addNote" class="btn btn-primary" style="width: 100%;">Add Note</button>
                            </div>
                            <div class="col-md-3"></div>
                        </div>
                        <br/>
                    </div>
                    
                    <div class="row">
                        <div class="col-md-4">
                            <button @click="privateNote = false; publicNote = true" style="width: 100%;" class="btn">Reply</button>
                        </div>
                        <div class="col-md-4">
                            <button v-if="access > 0" @click="publicNote = false; privateNote = true" style="width: 100%;" class="btn">Add Note</button>                        </div>
                    </div>
                </card>
            </div>
            <div class="col-md-4">
                <card style="position: sticky; top:0; z-index:99; background-color: white;">
                    <h5>Status</h5>
                    <select v-if="access > 0" style="width:100%" v-model="ticket.status">
                        <option v-for="(status, i) in statuses" :key="status" :value="i">{{ status }}</option>
                    </select>
                    <b v-else>{{ statuses[ticket.status] }}</b>
                    <br/>
                    <br/>
                    <h5>Priority</h5>
                    <select v-if="access > 0" style="width:100%" v-model="ticket.priority">
                        <option v-for="(priority, i) in priorities" :key="priority" :value="i">{{ priority }}</option>
                    </select>
                    <b v-else>{{ priorities[ticket.priority] }}</b>

                    <br/>
                    <br/>
                    <h5>Assigned Support</h5>
                    <select v-if="access > 0" style="width:100%" v-model="ticket.assignedSupport">
                        <option value="00000000-0000-0000-0000-000000000000">Unassigned</option>
                        <option v-for="(s, id, i) in staff" :key="i" :value="id">{{ s }}</option>
                    </select>
                    <b v-else>{{ staff[ticket.assignedSupport] }}</b>
                    <br/>
                    <br/>
                    <button  v-if="access > 0" @click="updateTicket" class="btn btn-primary" style="width: 100%;">Save Changes</button>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        id: String,
    },
    data() {
        return {
            clients: [],
            staff: [],
            ticket: {},
            statuses: {},
            priorities: {},
            publicNote: false,
            privateNote: false,
            noteText: '',
            access: this.getCookie("al"),
            expandedNotes: false,
        }
    },
    async mounted() {
        this.clients = await this.get('Client/Names')
        this.staff = await this.get('Staff/Names')
        this.ticket = await this.get(`Support/${this.id}`)
        this.statuses = await this.get('Support/Statuses')
        this.priorities = await this.get('Support/Priorities')

        for (var i = 0; i < this.ticket.updates.length; i++) {
            if (this.access === 0 && !this.ticket.updates[i].isPublic) {
                this.ticket.updates.splice(i, 1)
            }
        }
    },
    computed: {
        textboxColour() {
            if (this.publicNote) {
                return '#E5F2FD'
            }

            if (this.privateNote) {
                return '#FEF1E1'
            }

            return ''
        },
        statusColour() {
            switch(this.ticket.priority) {
                case 0:
                    return '#2DCCFF';
                case 1:
                    return '#56F000';
                case 2:
                    return '#FCE83A';
                case 3:
                    return '#FFB302';
                case 4:
                    return '#FF3838';
                default:
                    return '#A4ABB6';
            }
        },
        daysSinceLogged() {
            var Difference_In_Time = new Date().getTime() - new Date(this.ticket.created).getTime();
            return Math.round(Difference_In_Time / (1000 * 3600 * 24));
        }
    },
    methods: {
        async addNote() {
            await this.postBodyVoid(`Support/Update/${this.id}`, {
                text: this.noteText,
                userId: this.getCookie('id'),
                isPublic: this.publicNote,
                posted: new Date()
            })

            window.location.reload();
        },
        async updateTicket() {
            this.ticket.status = Number(this.ticket.status)
            this.ticket.priority = Number(this.ticket.priority)
            await this.postBodyVoid(`Support/Edit/${this.id}`, this.ticket)
            await this.$toastr.s('Success')
        },
    }
}
</script>