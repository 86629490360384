<template>
  <div>
    <ChartCard :chart-data="auditionStatistics" chart-type="Pie">
      <template slot="header">
        <h4 class="card-title">Audition Statistics</h4>
      </template>
      <template slot="footer">
        <div class="legend" style="text-align: center;">
          <i class="fa fa-circle" style="color: #9368E9;"></i> Completed
          <i class="fa fa-circle text-info"></i> Booked
          <i class="fa fa-circle text-warning"></i> Auditioned
          <i class="fa fa-circle text-danger"></i> Submitted
        </div>
      </template>
    </ChartCard>
  </div>
</template>
<script>
import {
  ChartCard,
} from "src/components/index";
export default {
  components: {
    ChartCard,
  },
  props: {
    jobs: Array,
  },
  data() {
    return {
      loading: true,
      auditionStatistics: {
        labels: ["0%", "0%", "0%", "0%"],
        series: [0, 0, 0, 0],
      },
    }
  },
  mounted() {
    const jobsGrouped = this.groupBy(this.jobs, 'status')
    const submitted = Math.round(((jobsGrouped[0] || []).length / this.jobs.length) * 100);
    const auditioned = Math.round((((jobsGrouped[1] || []).length + (jobsGrouped[2] || []).length + (jobsGrouped[3] || []).length + (jobsGrouped[4] || []).length) / this.jobs.length) * 100);
    const booked = Math.round((((jobsGrouped[5] || []).length + (jobsGrouped[6] || []).length + (jobsGrouped[7] || []).length) / this.jobs.length) * 100);
    const completed = Math.round(((jobsGrouped[8] || []).length / this.jobs.length) * 100);

    this.auditionStatistics.labels = [`${booked}%`, `${submitted}%`, `${auditioned}%`, `${completed}%`];
    this.auditionStatistics.series = [booked, submitted, auditioned, completed];
    this.loading = false;
  }
}
</script>
