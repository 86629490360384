var map = {
  a: "q",
  b: "w",
  c: "e",
  d: "r",
  e: "t",
  f: "y",
  g: "u",
  h: "i",
  i: "o",
  j: "p",
  k: "a",
  l: "s",
  m: "d",
  n: "f",
  o: "g",
  p: "h",
  q: "j",
  r: "k",
  s: "l",
  t: "z",
  u: "x",
  v: "c",
  w: "v",
  x: "b",
  y: "n",
  z: "m",
  A: "Q",
  B: "W",
  C: "E",
  D: "R",
  E: "T",
  F: "Y",
  G: "U",
  H: "I",
  I: "O",
  J: "P",
  K: "A",
  L: "S",
  M: "D",
  N: "F",
  O: "G",
  P: "H",
  Q: "J",
  R: "K",
  S: "L",
  T: "Z",
  U: "X",
  V: "C",
  W: "V",
  X: "B",
  Y: "N",
  Z: "M",
  0: "9",
  1: "8",
  2: "7",
  3: "6",
  4: "5",
  5: "4",
  6: "3",
  7: "2",
  8: "1",
  9: "0",
  "!": ")",
  "£": "(",
  "$": "*",
  "%": "&",
  "^": "^",
  "&": "%",
  "*": "$",
  "(": "£",
  ")": "!",
  "-": "_",
  "_": "-",
  "+": "=",
  "=": "+",
  "[": "{",
  "{": "[",
  "]": "}",
  "}": "]",
  ";": ":",
  ":": ";",
  "'": "@",
  "@": "'",
  "#": "~",
  "~": "#",
  ",": "<",
  "<": ",",
  ".": ">",
  ">": ".",
  "/": "?",
  "?": "/",
  "\\": "\\",
  '"': '"',
  " ": " "
};

export default {
  encrypt(text) {
    return text
      .split("")
      .reverse()
      .filter(function(v) {
        // Filter out characters that are not in our list
        return map.hasOwnProperty(v.replace('/'));
      })
      .map(function(v) {
        // Replace old character by new one
        return map[v];
      })
      .join("");
  },
  decrypt(text) {
    return text
      .split("")
      .reverse()
      .filter(function(v) {
        // Filter out characters that are not in our list
        return map.hasOwnProperty(v);
      })
      .map(function(v) {
        var x;
        for (const [key, value] of Object.entries(map)) {
          if (value === v) {
            x = key;
          }
        }

        return x;
      })
      .join("");
  },
};
