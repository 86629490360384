<template>
    <div>
        <div class="row">
            <div class="col-md-5">
                <card>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <img class="col-md-4" :src="profilePic"/>
                        <div class="col-md-4"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <span class="col-md-4">{{ user.firstName }} {{ user.lastName }}</span>
                        <div class="col-md-4"></div>
                    </div>
                    <div class="row">
                        <div class="col-md-4"></div>
                        <span class="col-md-4">Joined: {{ this.dateFormat(user.created) }}</span>
                        <div class="col-md-4"></div>
                    </div>
                </card>
            </div>
            <div class="col-md-7">
                <card>
                    <div class="row">
                        <h4 class="col-md-9">User Details</h4>
                        <div class="col-md-3">
                            <button @click="editDetails()" style="width: 100%;" :class="`btn btn-${editingDetails ? 'success' : 'primary'}`">
                            {{ editingDetails ? 'Save' : 'Edit' }}
                        </button>
                        </div>
                    </div>
                    
                    <hr/>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Username:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingDetails" v-model="user.username" style="width: 100%;" />
                            <span v-else>{{ user.username }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            Password:
                        </div>
                        <div class="col-md-8">
                            <span> {{ user.password.text.replace(/./g, '*') }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            Phone Number:
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingDetails" v-model="user.phoneNumber" style="width: 100%;" />
                            <span v-else>{{ user.phoneNumber }}</span>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Stage Name:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingDetails" v-model="user.stageName" style="width: 100%;" />
                            <span v-else>{{ user.stageName }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            Spotlight PIN:
                        </div>
                        <div class="col-md-8">
                            <span> {{ user.spotlightPIN }}</span>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Citizenship:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingDetails" v-model="user.rightToWork.citizenship" style="width: 100%;" />
                            <span v-else>{{ user.rightToWork.citizenship }}</span>
                        </div>
                        
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Right To Work?</span>
                        </div>
                        <div class="col-md-2">
                            <Checkbox :disabled="!editingDetails" v-model="user.rightToWork.hasRightToWork" />
                        </div>
                        <div class="col-md-4">
                            <span>Need Work Visa?</span>
                        </div>
                        <div class="col-md-2">
                            <Checkbox :disabled="!editingDetails" v-model="user.rightToWork.requiresWorkVisa" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Has Work Visa?</span>
                        </div>
                        <div class="col-md-2">
                            <Checkbox :disabled="!editingDetails" v-model="user.rightToWork.hasWorkVisa" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Visa Type:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingDetails" v-model="user.rightToWork.visaType" style="width: 100%;" />
                            <span v-else>{{ user.rightToWork.visaType }}</span>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-md-4">
                            <span>NINO:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingDetails" v-model="user.rightToWork.nino" style="width: 100%;" />
                            <span v-else>{{ user.rightToWork.nino }}</span>
                        </div>
                    </div>
                    <br/>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Self-Employed?</span>
                        </div>
                        <div class="col-md-8">
                            <Checkbox :disabled="!editingDetails" v-model="user.rightToWork.selfEmployed" />
                        </div>
                    </div>
                </card>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <card>
                    <div class="row">
                        <h4 class="col-md-9">Address</h4>
                        <div class="col-md-3">
                            <button @click="editAddress()" style="width: 100%;" :class="`btn btn-${editingAddress ? 'success' : 'primary'}`">
                                {{ editingAddress ? 'Save' : 'Edit' }}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Line 1:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingAddress" v-model="user.address.line1" style="width: 100%;" />
                            <span v-else>{{ user.address.line1 }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Line 2:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingAddress" v-model="user.address.line2" style="width: 100%;" />
                            <span v-else>{{ user.address.line2 }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Line 3:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingAddress" v-model="user.address.line3" style="width: 100%;" />
                            <span v-else>{{ user.address.line3 }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Town:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingAddress" v-model="user.address.town" style="width: 100%;" />
                            <span v-else>{{ user.address.town }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>County:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingAddress" v-model="user.address.county" style="width: 100%;" />
                            <span v-else>{{ user.address.county }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Postcode:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingAddress" v-model="user.address.postcode" style="width: 100%;" />
                            <span v-else>{{ user.address.postcode }}</span>
                        </div>
                    </div>
                </card>
            </div>
            <div class="col-md-7">
                <card>
                    <div class="row">
                        <h4 class="col-md-9">Emergency Contact</h4>
                        <div class="col-md-3">
                            <button @click="editEC()" style="width: 100%;" :class="`btn btn-${editingEC ? 'success' : 'primary'}`">
                                {{ editingEC ? 'Save' : 'Edit' }}
                            </button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>First Name:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingEC" v-model="user.emergencyContact.firstName" style="width: 100%;" />
                            <span v-else>{{ user.emergencyContact.firstName }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Last Name:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingEC" v-model="user.emergencyContact.lastName" style="width: 100%;" />
                            <span v-else>{{ user.emergencyContact.lastName }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Phone Number:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingEC" v-model="user.emergencyContact.phoneNumber" style="width: 100%;" />
                            <span v-else>{{ user.emergencyContact.phoneNumber }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <span>Relationship:</span>
                        </div>
                        <div class="col-md-8">
                            <input v-if="editingEC" v-model="user.emergencyContact.relationship" style="width: 100%;" />
                            <span v-else>{{ user.emergencyContact.relationship }}</span>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import { Checkbox } from '../../components'

export default {
    data() {
        return {
            user: {},
            editingAddress: false,
            editingDetails: false,
            editingEC: false,
        };
    },
    async mounted() {
        this.user = await this.get(`Client/Id/${this.getCookie('id')}`);
    },
    computed: {
        profilePic() {
            return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${this.user.id}`;
        }
    },
    methods: {
        async editAddress() {
            if (this.editingAddress) {
                await this.postBody('Client/Update', this.user);
                await this.$toastr.s('Success');
            }
            this.editingAddress = !this.editingAddress;
        },
        async editEC() {
            if (this.editingEC) {
                await this.postBody('Client/Update', this.user);
                await this.$toastr.s('Success');
            }
            this.editingEC = !this.editingEC;
        },
        async editDetails() {
            if (this.editingDetails) {
                await this.postBody('Client/Update', this.user);
                await this.$toastr.s('Success');
            }
            this.editingDetails = !this.editingDetails;
        }
    },
    components: { Checkbox }
}
</script>