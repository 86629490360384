<template>
  <div v-if="!loading" style="width: 100%" class="container">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img
          src="../../../public/static/img/logo.png"
          alt="Logo"
          style="margin: auto; width: 50%"
        />
      </div>
      <div class="col-md-4"></div>
    </div>
    <br />
    <div class="row ml-4">
      <div class="col-md-7">
        <span class="mt-0" style="font-size: x-large">
          Invoice
        </span>
      </div>
      <div class="col-md-5">
        <br />
        <div class="row mt-0">
          <div class="col-md-12 mt-0">
            <b>Inv #: </b
            ><span
              >{{ invoice.id.split("-")[1].toUpperCase()
              }}{{ invoice.id.split("-")[2].toUpperCase() }}</span
            ><br />
            <b>Client Name: </b><span>{{ invoice.clientName }}</span
            ><br />
            <b>Reference: </b><span>{{ invoice.reference }}</span
            ><br />
            <b>Date: </b><span>{{ dateFormat(invoice.date) }}</span
            ><br />
          </div>
        </div>
      </div>
    </div>
    <br />
    <br />
    <div class="row ml-5 mr-4">
      <div class="col-md-4" style="border: 1px solid black">
        <span>To:</span><br />
        <b>{{invoice.buyer.name}}</b><br />
        <span>{{invoice.buyer.address.line1}}</span><br />
        <div v-if="invoice.buyer.address.line2"><span>{{invoice.buyer.address.line2}}</span><br /></div>
        <div v-if="invoice.buyer.address.line3"><span>{{invoice.buyer.address.line3}}</span><br /></div>
        <span>{{invoice.buyer.address.town}}</span><br />
        <div v-if="invoice.buyer.address.county"><span>{{invoice.buyer.address.county}}</span><br /></div>
        <span>{{invoice.buyer.address.postcode}}</span><br />
        <br />
        <br />
      </div>
      <div class="col-md-3"></div>
      <div class="col-md-4" style="border: 1px solid black">
        <span>From:</span><br />
        <b>Vella Wozniak LLP</b><br />
        <span>9-10 St Andrew Square</span><br />
        <span>Edinburgh</span><br />
        <span>EH2 2AF</span>
        <br />
        <br />
      </div>
    </div>
    <br />
    <br />
    <div class="row ml-4 mr-4">
      <div class="col-md-2">
        <b>Quantity</b>
      </div>
      <div class="col-md-6">
        <b>Description</b>
      </div>
      <div class="col-md-2">
        <b>Net</b>
      </div>
      <div class="col-md-2">
        <b>Total</b>
      </div>
    </div>
    <div
      class="row ml-4 mr-4"
      v-for="line in invoice.lines"
      :key="line.description"
      style="max-height: 100%"
    >
      <div class="col-md-2">
        <span>{{ line.quantity }}</span>
      </div>
      <div class="col-md-6">
        <span>{{ line.description }}</span>
      </div>
      <div class="col-md-2">
        <span>{{ moneyFormat(line.net, "GBP") }}</span>
      </div>
      <div class="col-md-2">
        <span>{{ moneyFormat(line.total, "GBP") }}</span>
      </div>
    </div>
    <br />
    <hr class="ml-4 mr-4 mb-0" />
    <div class="row ml-2 mr-4">
      <div class="col-md-10"></div>
      <div class="col-md-2 mt-0">
        <b style="font-size: 12pt">{{ moneyFormat(invoice.total, "GBP") }}</b>
      </div>
    </div>
    <br />
    <br />
    <div class="row ml-4 mr-4" style="border: 1px solid black">
      <div class="col-md-3" style="border: 1px solid black">
        <b>£</b><br />
        <span>Account No: 37952447</span><br />
        <span>Sort Code: 04-00-75</span><br />
        <br />
        <br />
      </div>
      <div class="col-md-5" style="border: 1px solid black">
        <b>€</b><br />
        <span>IBAN: GB95 REVO 0099 6971 7019 14</span><br />
        <span>BIC: REVOGB21</span><br />
        <br />
        <br />
      </div>
      <div class="col-md-4" style="border: 1px solid black">
        <b>$</b><br />
        <span>Account No: 253819552483</span><br />
        <span>ACH Routing No: 026014928</span><br />
        <br />
        <br />
      </div>
    </div>
    <div class="row mt-5 ml-2 mr-4">
      <div class="col-md-12">
          <b>Terms:</b><br />
          <span
            >Payment is due within 30 days from the date of invoice.
            Interest may be charged on all outstanding balances after
            the due date.</span
          >
      </div>
    </div>
    <div class="row mt-5 ml-2 mr-4">
      <div class="col-md-12">
        <small
          >Company No. OC441552<br />VAT No. N/A<br />Registered Office: 71-75
          Shelton Street, Covent Garden, London, WC2H 9JQ</small
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    inv: String,
  },
  data() {
    return {
      loading: true,
      invoice: {},
    };
  },
  async mounted() {
    this.loading = true;
    this.invoice = await this.get(`Invoice/${this.inv}`);

    try {
      this.invoice.clientName = await this.simpleGet(
        `Client/Name?id=${this.invoice.clientId}`
      );
    } catch {
      this.invoice.clientName = "";
    }

    this.invoice.job = await this.get(`Job/${this.invoice.jobId}`);

    try {
      this.invoice.buyer = await this.get(`Buyer/Id/${this.invoice.job.buyerId}`)
    } catch {
      this.invoice.buyer = {
        address: {}
      };
    }

    this.$forceUpdate();
    this.loading = false;
  },
};
</script>
