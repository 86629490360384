<template>
    <div>
      <div class="row">
        <div class="col-md-6">
          <div class="row">
            <h4>{{ challenge.title }}</h4>
          </div>
          <div class="row">
            <span v-html="challenge.description.replace(/\n/g, '<br/>')"></span>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            &nbsp;
          </div>
          <br/>
          <div class="row">
            <button :disabled="access > 0" v-if="!uploadReady" @click="uploadReady = true" class="btn btn-success">Upload Your Clip</button>
            <Upload
              v-if="uploadReady && !imageUrl"
              name="uploadingVideo"
              :action="'/'"
              accept="video/mp4"
              :http-request="uploadPic"
              class="avatar-uploader"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
            >
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </Upload>
            <video v-if="imageUrl" width="640" height="264" :options="{ autoplay: true, controls: true, preload: 'poster' }">
              <source :src="imageUrl" />
            </video>
            <button v-if="imageUrl && !uploading" @click="saveVideo" class="btn btn-success" style="width: 100%;">Save</button>
          </div>
        </div>
      </div>
      <br/>
      <hr/>
      <br/>
      <div class="row">
          <div v-for="video in videos" :key="video.id" class="col-md-3">
              <span>{{ video.user }}</span>
              <video-player :options="video.videoOptions" />
              <br/>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import VideoPlayer from '@/components/VideoPlayer.vue';
  import { Upload } from "element-ui";

  export default {
    name: 'VideoExample',
    components: {
      VideoPlayer,
      Upload,
    },
    props: {
      id: String,
    },
    data() {
      return {
        challenge: {},
        videos: [],
        uploadReady: false,
        imageUrl: null,
        uploadingVideo: null,
        access:  this.getCookie("al"),
        uploading: false,
      };
    },
    methods: {
      async handleAvatarSuccess(res, file) {
        this.uploadingVideo = file;
        this.uploadingVideo.position = 0;
        this.imageUrl = URL.createObjectURL(file.raw);
      },
      async uploadPic() {
        await fetch('/')
      },
      async saveVideo() {
        this.uploading = true;
        const id = await this.get('Settings/Guid');
        this.uploadingVideo.position = 0;

        await fetch(`https://storage.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_PUBLIC_BUCKET}/o?name=${id}&uploadType=media`, {
          headers: new Headers({
            'Content-Type': this.uploadingVideo.raw.type
          }),
          method: "POST",
          body: this.uploadingVideo.raw,
        }).then(async (res) => {
          if (res.status === 200) {
            try
            {
              await this.postVoid(`Challenge/Upload/${id}/${this.id}/${this.getCookie("id")}`);
              this.videos.push({
                  id: id,
                  user: '',
                  videoOptions: {
                  autoplay: false,
                  controls: true,
                  preload: 'poster',
                  sources: [
                    {
                      src:
                        `https://storage.googleapis.com/public-store-castmate/${id}`,
                        type: 'video/mp4'
                    }
                  ]
                }
              })

              this.$toastr.s('Success!')
            }
            catch(e) {
              this.$toastr.e(e)
            }
            finally {
              this.uploading = false;
            }
          }
        });
      }
    },
    async mounted() {
      this.challenge = await this.get(`Challenge/${this.id}`);
      var users = await this.get('Client')
      for (var i = 0; i < this.challenge.submissions.length; i++) {
        for (var x = 0; x < users.length; x++) {
          if (users[x].id === this.challenge.submissions[i].userId) {
            this.videos.push({
                id: this.challenge.submissions[i].id,
                user: users[x].stageName,
                videoOptions: {
                autoplay: false,
                controls: true,
                preload: 'poster',
                sources: [
                  {
                    src:
                      `https://storage.googleapis.com/public-store-castmate/${this.challenge.submissions[i].id}`,
                      type: 'video/mp4'
                  }
                ]
              }
            })
          }
        }
      }
    }
  };
  </script>