<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <stats-card :title="clientCount.toString()" subTitle="Clients">
          <div slot="header" class="icon-warning">
            <i class="nc-icon nc-puzzle-10"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="`£${revenueTotal.toString()}`" subTitle="Revenue">
          <div slot="header" class="icon-success">
            <i class="nc-icon nc-money-coins"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="openJobs.toString()" subTitle="Open Jobs">
          <div slot="header" class="icon-danger">
            <i class="nc-icon nc-lock-circle-open"></i>
          </div>
        </stats-card>
      </div>

      <div class="col-xl-3 col-md-6">
        <stats-card :title="outstandingInvoices.toString()" subTitle="Outstanding Invoices">
          <div slot="header" class="icon-info">
            <i class="nc-icon nc-bank"></i>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <Spinner v-if="loading"></Spinner>
        <AuditionStats v-else :jobs="jobs"></AuditionStats>
      </div>
      <div class="col-md-2">
        <ShortcutButtons></ShortcutButtons>
      </div>
      <div class="col-md-2">
        <Payslips></Payslips>
      </div>
      <div class="col-md-4">
        <CommissionCard></CommissionCard>
      </div>
    </div>

    <el-dialog title="Set Up A New Password" :visible.sync="changePassword">
      <PasswordChange></PasswordChange>
    </el-dialog>
  </div>
</template>
<script>
import {
  StatsCard,
} from "src/components/index";
import Spinner from "../../../components/Spinner.vue";
import { Dialog, Button, Collapse, CollapseItem } from 'element-ui'
import PasswordChange from "../Components/PasswordChange.vue";
import CommissionCard from "../Components/CommissionCard.vue";
import ShortcutButtons from "../Components/ShortcutButtons.vue";
import Payslips from "../Components/Payslips.vue";
import AuditionStats from "../Components/AuditionStats.vue";

export default {
  components: {
    StatsCard,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    PasswordChange,
    CommissionCard,
    ShortcutButtons,
    Payslips,
    AuditionStats
},
  data() {
    return {
      loading: true,
      changePassword: false,
      clientCount: 0,
      revenueTotal: 0,
      openJobs: 0,
      outstandingInvoices: 0,
      jobs: [],
    };
  },
  async created() {
    await fetch(`${process.env.VUE_APP_API}Client`, {
      headers: {
        "AT": process.env.VUE_APP_TOKEN,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then(async (data) => {
        this.clientCount = data.length;
      });

    await fetch(`${process.env.VUE_APP_API}Job/All`, {
      headers: {
        "AT": process.env.VUE_APP_TOKEN,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then(async (data) => {
        this.jobs = data;
        this.openJobs = data.length;
      });

    await this.get(`Password/Reset/Latest?userId=${this.getCookie('id')}`)
      .catch(() => this.changePassword = true);

    this.outstandingInvoices = (await this.get('Invoice/Incoming')).length

    this.staff = await this.get('Staff');

    this.loading = false;
  },
  methods: {
    agentName(id) {
      var agent = this.filterList(id, "id", this.staff)[0];

      return `${agent.firstName} ${agent.lastName}`
    },
  }
};
</script>
<style></style>
