<template>
  <div>
    <ValidationObserver ref="form">
      <form @submit.prevent="validate">
        <div class="row">
          <div class="col-md-2">
            <span>Project:</span>
          </div>
          <div class="col-md-10">
            <ValidationProvider name="projectName" rules="required" v-slot="{ passed, failed }">
              <fg-input name="projectName" :error="failed ? 'This field is required' : null" :hasSuccess="passed"
                v-model="projectName">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <span>Role:</span>
          </div>
          <div class="col-md-10">
            <ValidationProvider name="role" rules="required" v-slot="{ passed, failed }">
              <fg-input name="role" :error="failed ? 'This field is required' : null" :hasSuccess="passed"
                v-model="role">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <span>Recipient:</span>
          </div>
          <div class="col-md-10">
            <ValidationProvider name="recipient" rules="required" v-slot="{ passed, failed }">
              <fg-input name="recipient" :error="failed ? 'This field is required' : null" :hasSuccess="passed"
                v-model="recipient">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <span>Email:</span>
          </div>
          <div class="col-md-10">
            <ValidationProvider name="email" rules="required|email" v-slot="{ passed, failed }">
              <fg-input name="email" :error="failed ? 'This field must be a valid email address' : null"
                :hasSuccess="passed" v-model="to">
              </fg-input>
            </ValidationProvider>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-12">
            <span>Clients</span>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ClientSelection commercial :canSubmit="false" @list-changed="setClients"></ClientSelection>
          </div>
        </div>
        <div class="row">
          <div class="col-md-8"></div>
          <div class="col-md-4">
            <button @click="submit" class="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import ClientSelection from './ClientSelection.vue';
import { extend } from "vee-validate";
import { required, email, regex } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("regex", regex);

export default {
  components: {
    ClientSelection
  },
  data() {
    return {
      selectedClients: [],
      to: "",
      recipient: "",
      projectName: "",
      role: "",
    }
  },
  methods: {
    setClients(list) {
      this.selectedClients = list;
    },
    async submit() {
      this.$refs.form.validate().then(async () => {
        if (this.projectName === "") {
          await this.$toastr.e('Project Name must be filled in!')
          return;
        }
        if (this.role === "") {
          await this.$toastr.e('Role must be filled in!')
          return;
        }
        if (this.to === "") {
          await this.$toastr.e('To must be filled in!')
          return;
        }
        if (this.recipient === "") {
          await this.$toastr.e('Recipient must be filled in!')
          return;
        }

        var submissions = {}

        for (var i = 0; i < this.selectedClients.length; i++) {
          await this.postBody('Job/Submission', {
            project: this.projectName,
            role: this.role,
            clientId: this.selectedClients[i],
            description: ""
          })

          submissions[this.selectedClients[i]] = ""
        }

        var data = await this.postBody(`Submission/Commercial/Create?name=${this.projectName}`, submissions)

        var clientList = [];

        for (var i = 0; i < this.selectedClients.length; i++) {
          clientList.push({
            NAME: await this.simpleGet(`Client/Name?id=${this.selectedClients[i]}`),
            HEADSHOT: `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${this.selectedClients[i]}`,
            NOTES: ""
          })
        }

        this.send(5, [
          {
            email: this.to,
            name: this.recipient
          }
        ], [
          {
            email: 'hello@expression-agency.com',
            name: 'Expression Commercials Agency'
          }
        ], [], {
          CASTING: this.recipient.split(' ')[0],
          ROLE: this.role,
          PROJECT: this.projectName,
          CLIENTS: JSON.stringify(clientList),
          URL: `https://castmate.expression-agency.com/public/commercial/project?id=${data.id}`
        })

        await this.$toastr.s('Success')

        this.to = ""
        this.recipient = ""
        this.projectName = ""
        this.role = ""
      })
    }
  }
}
</script>
