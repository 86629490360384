<template>
  <div>
    <label for="old-password">Old Password:&nbsp;&nbsp;</label>
    <input id="old-password" type="password" v-model="oldPassword" style="width: 100%" />
    <br />
    <br />
    <label for="new-password">New Password:&nbsp;&nbsp;</label>
    <input id="new-password" type="password" v-model="newPassword" style="width: 100%" />
    <br />
    <br />
    <label for="confirm-password">Confirm Password:&nbsp;&nbsp;</label>
    <input id="confirm-password" type="password" v-model="confirmPassword" style="width: 100%" />
    <span slot="footer" class="dialog-footer">
      <button class="btn btn-success" @click="setNewPassword">Confirm</button>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    }
  },
  methods: {
    async setNewPassword() {
      if (this.newPassword.length < 5) {
        await this.$toastr.e("Password must be longer than 5 characters!")
        return
      }

      if (this.newPassword === this.oldPassword) {
        await this.$toastr.e("Password must not be the same as the old one!")
        return
      }

      if (this.newPassword !== this.confirmPassword) {
        await this.$toastr.e("Passwords do not match!")
      } else {
        var u = await this.postVoid(`Staff/Password/New?userId=${this.getCookie('id')}&oldPassword=${this.oldPassword}&newPassword=${this.newPassword}`)
        if (u) {
          await this.postVoid(`Password/Record?userId=${this.getCookie('id')}`)
          await this.$toastr.s("Password Updated!")
          this.changePassword = false;
        } else {
          await this.$toastr.e('Your old password is incorrect')
        }
      }
    },
  }
}
</script>
