<template>
    <div>
        <span v-if="loading">Loading</span>
        <Table v-else  @edit="openBreakdown" filtered :columns="columns" :data="breakdowns"> </Table>
    </div>
</template>
<script>
import { Table } from "../../components/index";
export default {
    data() {
        return {
            loading: true,
            columns: [ "id", "project", "roles", "edit" ],
            breakdowns: []
        }
    },
    components: {
        Table,
    },
    async mounted() {
        var res = (await this.get('Breakdown')).reverse()
        
        for (var i = 0; i < res.length; i++) {
            this.breakdowns.push({
                id: res[i].id,
                project: res[i].project,
                roles: res[i].roles.length.toString(),
            })
        }

        this.loading = false;
    },
    methods: {
        openBreakdown(e) {
            this.$router.push(e.id)
        }
    }
}
</script>