<template>
  <table style="max-width: 100% !important;" class="table">
    <thead>
      <slot name="columns">
        <th v-for="(column, i) in columns" :hidden="isId(column) ? true : false" :key="i">{{ headerName(column) }}</th>
      </slot>
    </thead>
    <tbody>
      <tr v-if="filtered">
        <td v-for="(column, i) in columns" :key="i">
          <input v-if="!buttons.some(b => column === b)" @input="filterTable($event.target.value, column)" />
        </td>
      </tr>
      <tr :style="rowHighlight(item)" v-for="item in items" :key="item.id">
        <slot :row="item">
          <template v-for="(column, i) in columns">
            <td v-if="column === 'edit'" :key="i">
              <l-button @click="edit(item)" type="primary">Edit</l-button>
            </td>
            <td v-if="column === 'delete'" :key="i">
              <l-button @click="edit(item)" type="danger">Delete</l-button>
            </td>
            <td v-if="column === 'accept'" :key="i">
              <l-button @click="edit(item)" type="success">Accept</l-button>
            </td>
            <td v-if="column === 'view'" :key="i">
              <l-button @click="edit(item)" type="primary">View</l-button>
            </td>
            <td v-if="column === 'print'" :key="i">
              <l-button @click="print(item)" type="warning">Print</l-button>
            </td>
            <td :hidden="isId(column)" v-if="hasValue(item, column)" :key="i" v-html="itemValue(item, column)"></td>
            <td :hidden="isId(column)" v-if="isImage(column)" :key="i" width="220px">
              <img class="headshot square" :src="cdn + itemValue(item, column)" />
            </td>
            <td :hidden="isId(column)" v-if="isSpotlight(column)" :key="i" style="text-align: center;">
              <i v-if="itemValue(item, column) === '' || itemValue(item, column) === undefined">Client not on
                Spotlight</i>
              <a v-else class="spotlight-logo" :href="`https://spotlight.com/${itemValue(item, column)}`" target="_blank">
                <img class="spotlight-logo" style="background-color: whitesmoke; border-radius: 100%;"
                  src="../../public/static/img/spotlight.png" height="100px" width="100px" />
              </a>
            </td>
          </template>
        </slot>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: 'l-table',
  props: {
    columns: Array,
    data: Array,
    filtered: Boolean,
  },
  data() {
    return {
      items: [],
      filter: {},
      buttons: ['edit', 'delete', 'accept', 'view', 'print']
    }
  },
  mounted() {
    this.items = this.data;
  },
  computed: {
    cdn() {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/`;
    }
  },
  methods: {
    edit(item) {
      this.$emit('edit', item)
    },
    print(item) {
      this.$emit('print', item)
    },
    hasValue(item, column) {
      if (!this.isImage(column) && !this.isSpotlight(column)) {
        return item[column] !== 'undefined'
      } else {
        return false
      }
    },
    isId(column) {
      if (column === 'id') {
        return true
      } else {
        return false
      }
    },
    isImage(column) {
      if (column === 'headshot') {
        return true
      } else {
        return false
      }
    },
    isSpotlight(column) {
      if (column === 'spotlight') {
        return true
      } else {
        return false
      }
    },
    itemValue(item, column) {
      if (item[column.toLowerCase()] === true) {
        return '<i class="nc-icon nc-check-2" style="font-size: 5rem; color: lightgreen; font-weight:bolder;"></i>'
      } else if (item[column.toLowerCase()] === false) {
        return '<i class="nc-icon nc-check-2" style="font-size: 5rem; color: lightgrey; font-weight:bolder;"></i>'
      }

      return item[column.toLowerCase()]
    },
    headerName(name) {
      var arr = name.split('')

      for (var i = 0; i < arr.length; i++) {
        if (arr[i] === arr[i].toUpperCase()) {
          arr.splice(i, 0, ' ')
          i++
        }
      }

      arr[0] = arr[0].toUpperCase()

      return arr.join('')
    },
    filterTable(text, column) {
      if (text !== "") {
        this.filter[column] = text
        this.items = this.filterByObject(this.data, this.filter)
      } else {
        delete this.filter[column]
        this.items = this.filterByObject(this.data, this.filter)
      }
    },
    rowHighlight(item) {
      if (item.type === 0) {
        return 'background-color: aliceblue;'
      }
      else if (item.type === 1) {
        return 'background-color: lightcoral'
      }
    }
  }
}
</script>
<style>
.table {
  text-align: center;
}

.spotlight-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.headshot.square {
  height: 15vw;
  width: 15vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  object-position: center top;
}
</style>
