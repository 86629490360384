<template>
    <div>
        <div class="row" style="background-color: darkblue; color:white;">
            <div class="col-md-3">
                <b>Reference</b>
            </div>
            <div class="col-md-3">
                <b>Agent Name</b>
            </div>
            <div class="col-md-3">
                <b>Job Title</b>
            </div>
            <div class="col-md-3">
                <b>Pay Date</b>
            </div>
        </div>
        <div class="row" style="background-color: aliceblue;">
            <div class="col-md-3">
                {{ payslip.reference }}
            </div>
            <div class="col-md-3">
                {{ agent.firstName }} {{ agent.lastName }}
            </div>
            <div class="col-md-3">
                {{ agent.jobTitle }}
            </div>
            <div class="col-md-3">
                1/{{ Number(this.month) + 1 }}/{{ this.year }}
            </div>
        </div>
        <br/>
        <div class="row">
            <div class="col-md-4">
                <b>Project</b>
            </div>
            <div class="col-md-2">
                <b>Income</b>
            </div>
            <div class="col-md-4">
                <b>Deductions</b>
            </div>
        </div>
        <div v-for="line in payslip.commissions" :key="line.id" class="row">
            <div class="col-md-4">
                {{ line.reference }}
            </div>
            <div class="col-md-2">
                £{{ line.amount }}
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                Agency Expenses
            </div>
            <div class="col-md-4">
                -£100
            </div>
        </div>
        <br/>
        <hr/>
        <div class="row">
            <div class="col-md-4">
                <b>Total</b>
            </div>
            <div class="col-md-4">
                £{{ total }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        agentId: String,
        year: Number,
        month: Number,
    },
    data() {
        return {
            payslip: {},
            agent: {},
            total: -100
        }
    },
    async mounted() {
        this.payslip = await this.get(`Commission/Payslip/${this.agentId}?year=${this.year}&month=${this.month}`)
        this.agent = await this.get(`Staff/Id/${this.agentId}`)

        for (var i = 0; i < this.payslip.commissions.length; i++) {
            this.total += this.payslip.commissions[i].amount
        }
    }
}

</script>