var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('h5',{staticClass:"text-center"},[_vm._v("New job? Well done! Let's get down some details...")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"jobName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"jobName","error":failed ? 'The Job Name field is required' : null,"hasSuccess":passed,"placeholder":"Job Name (required)","addon-left-icon":"nc-icon nc-single-02"},model:{value:(_vm.jobName),callback:function ($$v) {_vm.jobName=$$v},expression:"jobName"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"buyer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"buyer","error":failed ? 'The Buyer field is required' : null,"hasSuccess":passed,"placeholder":"Buyer (required)","addon-left-icon":"nc-icon nc-single-02"},model:{value:(_vm.buyer),callback:function ($$v) {_vm.buyer=$$v},expression:"buyer"}})]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"name":"role","error":failed ? 'The Role field is required' : null,"hasSuccess":passed,"placeholder":"Role (required)","addon-left-icon":"nc-icon nc-single-02"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }