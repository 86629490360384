import Vue from "vue";
import VueRouter from "vue-router";
import LightBootstrap from "./light-bootstrap-main";
// Plugins
import App from "./App.vue";

import 'video.js/dist/video-js.css';
// router setup
import routes from "./routes/routes";
import VCalendar from 'v-calendar';
import VueToastr from "vue-toastr";

// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(VCalendar);
Vue.use(VueToastr);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active",
  mode: "history",
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router
});
