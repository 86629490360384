<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <card style="max-width: 100%;">
                    <div class="row">
                        <div class="col-md-10">
                            <h4>Tickets</h4>
                        </div>
                        <div class="col-md-2">
                            <button @click="$router.push('/support/ticket/new')" class="btn btn-primary" style="width: 100%;">
                                New Ticket
                            </button>
                        </div>
                    </div>
                    <hr/>
                    <br/>
                    <Table @edit="openTicket" style="max-width: 100% !important; width: 100% !important;" filtered :columns="columns" :data="tickets"></Table>
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import { Table } from '../../components'
export default {
    components: {
        Table,
    },
    data() {
        return {
            columns: ["id", "ticket", "title", "created", "status", "edit"],
            tickets: [],
            access: this.getCookie("al"),
        }
    },
    async mounted() {
        var res = await this.get( (access > 0) ? 'Support' : `Support?userId=${this.getCookie('id')}`)
        var statuses = await this.get('Support/Statuses')

        for (var i = 0; i < res.length; i++) {
            this.tickets.push({
                id: res[i].id,
                ticket: res[i].ticket,
                title: res[i].title,
                created: this.dateFormat(res[i].created),
                status: statuses[res[i].status]
            })
        }
    },
    methods: {
        openTicket(e) {
            this.$router.push(`/support/ticket?id=${e.id}`)
        }
    }
}
</script>