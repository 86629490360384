<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <ValidationObserver v-slot="{ handleSubmit }">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <form @submit.prevent="handleSubmit(submit)">
            <fade-render-transition>
              <card v-if="!resetComplete">
                <div slot="header">
                  <h3 class="card-title text-center">Set A New Password</h3>
                </div>
                <div>
                  <ValidationProvider
                    name="password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="failed ? 'The Password field is required and must be more than 5 characters' : null"
                      :hasSuccess="passed"
                      name="password"
                      label="New Password"
                      v-model="password"
                    >
                    </fg-input>
                  </ValidationProvider>

                  <ValidationProvider
                    name="confirm-password"
                    rules="required|min:5"
                    v-slot="{ passed, failed }"
                  >
                    <fg-input
                      type="password"
                      :error="failed ? 'The Confirm Password field is required' : null"
                      :hasSuccess="passed"
                      name="confirm-password"
                      label="Confirm Password"
                      v-model="confirmPassword"
                    >
                    </fg-input>
                  </ValidationProvider>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn btn-fill btn-info btn-round btn-wd"
                  >
                    Reset
                  </button>
                </div>
              </card>
              <card v-else>
                <h2>This password reset has expired</h2>
              </card>
            </fade-render-transition>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import {
  FadeRenderTransition,
} from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  components: {
    FadeRenderTransition,
    AuthLayout,
  },
  props: {
    id: String
  },
  data() {
    return {
      password: "",
      confirmPassword: "",
      userId: "",
      resetComplete: false,
    };
  },
  async mounted() {
      var r = await this.get(`Password/Reset/${this.id}`)
      .catch(() => this.$router.push('/login'));

      if (r.reset !== null) {
        this.resetComplete = true;
      }

      if (r.status !== undefined && r.status !== 200) {
        this.$router.push('/login')
      }

  },
  methods: {
    async submit() {
      if (this.password.length < 5) {
        await this.$toastr.e("Password must be longer than 5 characters!")
        return
      }

      if (this.password !== this.confirmPassword) {
        await this.$toastr.e("Passwords do not match!")
      } else {
        try {
          await this.post(`Client/Password/Reset/${this.id}?Password=${this.password}`)
          await this.$toastr.s("Password Updated!")
          this.$router.push("/login");
        } catch(e) {
          await this.$toastr.e(e)
        }
      }
    },
  }
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
