<template>
  <div v-if="!loading" style="width: 100%;" class="container">
    <div class="row">
      <div class="col-md-4"></div>
      <div class="col-md-4">
        <img src="../../../public/static/img/logo.png" alt="Logo" style="margin: auto; width: 50%;" />
      </div>
      <div class="col-md-4"></div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-7">
        <span class="mt-0" style="font-size: x-large;">
          Remittance - {{ invoice.job.name }}
        </span>
      </div>
      <div class="col-md-5">
        <br/>
        <div class="row mt-0">
          <div class="col-md-12 mt-0">
            <b>Client Name: </b><span>{{ invoice.clientName }}</span><br/>
            <br/>
            <b>Payment From:</b><br />
            <span>Vella Wozniak LLP</span><br />
            <span>9-10 St Andrew Square</span><br />
            <span>Edinburgh</span><br />
            <span>EH2 2AF</span>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-4">
        <b>Total Payment (minus commission):</b>
      </div>
      <div class="col-md-8">
        <span>{{ moneyFormat(invoice.totalPaid, "GBP") }}</span>
      </div>
    </div>
    <br />
    <br />
    <div class="row">
      <div class="col-md-12">
        <span style="font-size: large;">Original Invoice</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-2">
        <b>Quantity</b>
      </div>
      <div class="col-md-6">
        <b>Description</b>
      </div>
      <div class="col-md-2">
        <b>Net</b>
      </div>
      <div class="col-md-2">
        <b>Total</b>
      </div>
    </div>
    <div class="row" v-for="line in invoice.originalInvoice.lines" :key="line.description" style="max-height: 100%;">
      <div class="col-md-2">
        <span>{{ line.quantity }}</span>
      </div>
      <div class="col-md-6">
        <span>{{ line.description.replace(`${invoice.clientName} - `, '') }}</span>
      </div>
      <div class="col-md-2">
        <span>{{ moneyFormat(line.net, "GBP") }}</span>
      </div>
      <div class="col-md-2">
        <span>{{ moneyFormat(line.total, "GBP") }}</span>
      </div>
    </div>
    <br />
    <hr />
    <div class="row">
      <div class="col-md-5">
        <b>Total Invoiced:</b>
      </div>
      <div class="col-md-7">
        <span>{{ moneyFormat(invoice.originalInvoice.total, "GBP") }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <b>Commission Deducted:</b>
      </div>
      <div class="col-md-7">
        <span>{{ moneyFormat(invoice.commissionInvoice.amount, "GBP") }}</span>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-5">
        <b>Total:</b>
      </div>
      <div class="col-md-7">
        <b>{{ moneyFormat(invoice.total, "GBP") }}</b>
      </div>
    </div>
    <br />
    <br />
    <div class="row mt-5">
      <div class="col-md-12">
        <small>Company No. OC441552<br />VAT No. N/A<br />Registered Office: 71-75 Shelton Street, Covent Garden, London,
          WC2H 9JQ</small>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    invId: String,
  },
  data() {
    return {
      loading: true,
      invoice: {}
    }
  },
  async mounted() {
    this.invoice = await this.get(`Invoice/${this.invId}`)

    this.invoice.paying = false

    try {
      this.invoice.originalInvoice = await this.get(`Invoice/${this.invoice.linkedInvoice}`)
      this.invoice.originalInvoice.paying = false
    }
    catch
    {
      await this.$toastr.e('Could not find original invoice')
    }

    try {
      this.invoice.commissionInvoice = await this.get(`Commission/Invoice/${this.invoice.id}`)
      this.invoice.commissionInvoice.paying = false
    }
    catch {
      this.invoice.commissionInvoice = {
        amount: this.invoice.originalInvoice.total - this.invoice.total
      }
    }

    try {
      this.invoice.clientName = await this.simpleGet(`Client/Name?id=${this.invoice.clientId}`)
    } catch {
      this.invoice.clientName = ""
    }

    try {
      this.invoice.job = await this.get(`Job/${this.invoice.jobId}`)
    }
    catch {
      await this.$toastr.e('Could not find job record for this invoice')
    }
    this.loading = false
  }
}
</script>
