<template>
  <div class="c-performer-grid-card" :id="client.id">
    <div class="c-performer-grid-card__base-content">
      <div class="c-performer-grid-card__main-info">
        <div class="c-performer-grid-card__image-container">
          <div class="c-performer-grid-card__image"><img
              :src="headshot"
              :alt="client.stageName.toUpperCase()"></div>
        </div>
      </div>
      <div class="c-performer-grid-card__content"><a :href="`https://www.spotlight.com/${client.spotlightPIN}`" target="_blank"
          class="c-performer-grid-card__name g-alternative-link g-alternative-link--md" tabindex="0">{{ client.stageName.toUpperCase() }}</a>
      </div>
    </div>
    <div class="c-checkbox c-performer-grid-card__select">
      <!-- eslint-disable-next-line vue/no-mutating-props -->
      <div class="c-checkbox__wrapper c-checkbox__additionalMargin"><input type="checkbox" name="selectCard" @change="tick" v-model="client.selected"
          id="performerGridSelect" aria-label="selectCard">
        <div class="c-checkbox__box"></div>
      </div>
    </div>
  </div>
</template>
<script>
//import { Checkbox } from 'src/components/index'
export default {
  components: {
    //Checkbox,
  },
  props: {
    client: Object,
  },
  methods: {
    tick(e) {
      this.$emit('checked', {
        checked: this.client.selected,
        clientId: this.client.id,
      })
    }
  },
  computed: {
    headshot() {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${this.client.id}`
    },
  }
}
</script>
<style>
.c-performer-grid-card {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .2);
  grid-column: span 2;
  height: 220px;
  position: relative;
  transition-duration: .2s;
  transition-property: background-color, height;
}

@media (min-width: 721px) and (max-width: 1200px) {
  .c-performer-grid-card {
    grid-column: span 3;
  }
}

@media (max-width: 720px) {
  .c-performer-grid-card {
    grid-column: span 12;
    height: auto;
    min-height: 220px;
  }

  .c-performer-grid-card__base-content {
    height: auto;
}
}

.g-col-lg-12 {
    grid-column: span 12;
}


.c-performer-grid-card__content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.c-performer-grid-card__image-container {
    display: block !important;
    height: 100px !important;
    position: relative !important;;
    width: 100% !important;
}

.c-performer-grid-card__base-content {
  height: 200px;
}

.c-performer-grid-card__select {
  cursor: pointer;
  font-size: 30px;
  max-width: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.c-performer-grid-card__name {
  display: block;
}

a.g-alternative-link {
  color: #26247b;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
}

.c-performer-grid-card__content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.c-checkbox__additionalMargin {
  margin: -8px -10px;
}

.c-checkbox {
  display: flex;
}

.c-checkbox__wrapper {
  cursor: pointer;
  display: inline-flex;
  padding: 8px 10px;
  position: relative;
}

.c-performer-grid-card__image {
    height: 100px !important;
    position: relative !important;
    width: 80px !important;
}

.c-performer-grid-card__content span {
  font-size: 12px;
}

.c-performer-grid-card__artist-type {
  color: #28292a;
  font-size: 12px;
}

.c-checkbox input {
  cursor: pointer;
  height: calc(100% - 3px);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}

.g-bg-default .c-checkbox .c-checkbox__box {
  background-color: #f5f4f9;
}

.c-checkbox__box {
  border: 1px solid #75797e;
  display: inline-block;
  flex: 0 0 25px;
  height: 25px;
  position: relative;
  width: 25px;
}

.c-checkbox__box:before {
  bottom: 3px;
  content: " ";
  left: 3px;
  position: absolute;
  right: 3px;
  top: 3px;
}

.c-checkbox input:checked+.c-checkbox__box:before {
  background-color: #50e3c2;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

img, video {
    height: 100%;
    max-width: 100%;
}

audio, canvas, embed, iframe, img, object, svg, video {
    display: block;
    vertical-align: middle;
}
</style>