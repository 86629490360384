<template>
  <div>
    <div class="row bg-onboarding">
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
          <img :src="logo" style="width:25%; margin-left: 25%; margin-right: 25%;" />
        </div>
        <div class="col-md-4"></div>
      </div>
      <br/>
    <div v-if="client.type === 4 && !signed">
        <h4 class="mt-0 ml-5">Terms & Conditions</h4>
      <div class="row bg-onboarding">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <ContractSign @accept="signed = true"></ContractSign>
        </div>
        <div class="col-md-2"></div>
      </div>
    </div>
    <div v-else>
      <div class="m-5 row bg-onboarding">
        <div class="col-md-2">

        </div>
        <div v-if="loaded" class="col-md-8">
          <h3>Personal Details</h3>
          <card>
            <h5>Full Legal Name</h5>
            <input class="input-onboarding" placeholder="First Name" v-model="client.firstName"
              @input="fillStageName" />
            <input class="input-onboarding" placeholder="Last Name" v-model="client.lastName" @input="fillStageName" />
          </card>
          <card>
            <h5>Stage Name</h5>
            <input class="input-onboarding" v-model="client.stageName" />
          </card>
          <card>
            <h5>Date Of Birth</h5>
            <DatePicker v-model="client.dateOfBirth" style="width: 100%"></DatePicker>
          </card>
          <h3>Contact Details</h3>
          <card>
            <h5>Address</h5>
            <input class="input-onboarding" placeholder="Line 1" v-model="client.address.line1" />
            <input class="input-onboarding" placeholder="Line 2" v-model="client.address.line2" />
            <input class="input-onboarding" placeholder="Line 3" v-model="client.address.line3" />
            <input class="input-onboarding" placeholder="Town" v-model="client.address.town" />
            <input class="input-onboarding" placeholder="County" v-model="client.address.county" />
            <input class="input-onboarding" placeholder="Postcode" v-model="client.address.postcode" />
          </card>
          <card>
            <h5>Phone Number</h5>
            <input class="input-onboarding" v-model="client.phoneNumber" />
          </card>
          <h3>Emergency Contact</h3>
          <card>
            <h5>Name</h5>
            <input class="input-onboarding" placeholder="First Name" v-model="client.emergencyContact.firstName" />
            <input class="input-onboarding" placeholder="Last Name" v-model="client.emergencyContact.lastName" />
          </card>
          <card>
            <h5>Phone Number</h5>
            <input class="input-onboarding" v-model="client.emergencyContact.phoneNumber" />
          </card>
          <card>
            <h5>Relationship To You</h5>
            <input class="input-onboarding" v-model="client.emergencyContact.relationship" />
          </card>
          <h3>Right to Work</h3>
          <card>
            <h5>Citizenship</h5>
            <select v-model="client.rightToWork.citizenship">
              <option v-for="country in countries" :key="country.name.common" :value="country.name.common">
                {{ country.name.common }}
              </option>
            </select>
          </card>
          <card>
            <h5>Do you have the right to work in the UK?</h5>
            <Checkbox v-model="client.rightToWork.hasRightToWork"></Checkbox>
          </card>
          <card>
            <h5>Do you require a work visa?</h5>
            <Checkbox v-model="client.rightToWork.requiresWorkVisa"></Checkbox>
          </card>
          <card v-if="client.rightToWork.requiresWorkVisa">
            <h5>Do you currently have a work visa?</h5>
            <Checkbox v-model="client.rightToWork.hasWorkVisa"></Checkbox>
          </card>
          <card v-if="client.rightToWork.requiresWorkVisa">
            <h5>Type of visa you have/have applied for</h5>
            <input class="input-onboarding" v-model="client.rightToWork.visaType" />
          </card>
          <card>
            <h5>National Insurance Number</h5>
            <input class="input-onboarding" v-model="client.rightToWork.NINO" />
          </card>
          <card>
            <h5>Are you registered as self-employed for tax purposes?</h5>
            <Checkbox v-model="client.rightToWork.selfEmployed"></Checkbox>
          </card>
          <h3>Travel & Licences</h3>
          <card>
            <h5>Do you currently have a valid passport?</h5>
            <Checkbox v-model="client.travel.validPassport"></Checkbox>
          </card>
          <card v-if="client.travel.validPassport">
            <h5>Passport Expiry Date</h5>
            <DatePicker v-model="client.travel.passportExpiry" style="width: 100%"></DatePicker>
          </card>
          <card v-if="client.travel.validPassport">
            <h5>Passport Number</h5>
            <input class="input-onboarding" v-model="client.travel.passportNumber" />
          </card>
          <card>
            <h5>Do you currently have a valid UK driving licence?</h5>
            <Checkbox v-model="client.travel.validUKDrivingLicence"></Checkbox>
          </card>
          <card v-if="client.travel.validUKDrivingLicence">
            <h5>Do you currently have a vehicle?</h5>
            <Checkbox v-model="client.travel.hasVehicle"></Checkbox>
          </card>
          <card v-if="client.travel.hasVehicle">
            <h5>Do you have business insurance for your vehicle?</h5>
            <Checkbox v-model="client.travel.hasBusinessInsurance"></Checkbox>
          </card>
          <h3>Criminal History</h3>
          <card>
            <h5>Do you have any unspent criminal convictions?</h5>
            <Checkbox v-model="client.criminalHistory.unspentCriminalConvictions"></Checkbox>
          </card>
          <card v-if="client.criminalHistory.unspentCriminalConvictions">
            <h5>Please detail the nature of your unspent criminal conviction(s) in as much detail as you are
              comfortable
              with</h5>
            <input class="input-onboarding" v-model="client.criminalHistory.detail" />
          </card>
          <card>
            <h5>Are you currently awaiting a court appearance?</h5>
            <Checkbox v-model="client.criminalHistory.awaitingCourtAppearance"></Checkbox>
          </card>
          <card>
            <h5>Do you currently have a valid DBS/PVG/Disclosure Scotland/other background check?</h5>
            <Checkbox v-model="client.criminalHistory.hasBackgroundCheck"></Checkbox>
          </card>
          <h3>Bank Details</h3>
          <card>
            <h5>Account Number</h5>
            <input class="input-onboarding" maxlength="8" v-model="bankDetails.accountNo" />
          </card>
          <card>
            <h5>Sort Code</h5>
            <input class="input-onboarding" maxlength="6" v-model="bankDetails.sortCode" />
          </card>
          <button class="btn btn-primary" style="width: 100%" @click="submit">Submit</button>
          <br />
          <br />
        </div>
        <div v-else class="col-md-8">
          <Spinner></Spinner>
        </div>
        <div class="col-md-2">

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { DatePicker } from 'element-ui'
import { Checkbox, Spinner } from "../../components";
import ContractSign from '../../components/ContractSign.vue';
export default {
  components: {
    DatePicker,
    Checkbox,
    Spinner,
    ContractSign,
  },
  data() {
    return {
      client: {},
      bankDetails: {
        accountNo: "",
        sortCode: ""
      },
      loaded: false,
      countries: [],
      signed: false,
    }
  },
  methods: {
    async submit() {
      if (this.isFilledIn()) {
        this.client.onboarded = true;
        await this.postBody('Client/Update', this.client);
        await this.postBody(`Bank/Counterparty?clientId=${this.client.id}`, {
          individual_name: {
            first_name: this.client.firstName,
            last_name: this.client.lastName
          },
          account_no: this.bankDetails.accountNo,
          sort_code: this.bankDetails.sortCode
        })

        await this.$toastr.s('Success');
        this.$router.push('/')
      }
    },
    fillStageName(e) {
      this.client.stageName = `${this.client.firstName} ${this.client.lastName}`
    },
    isFilledIn() {
      if (this.client.firstName) {
        if (this.client.lastName) {
          if (this.client.stageName) {
            if (this.client.dateOfBirth) {
              if (this.client.address.line1) {
                if (this.client.address.town) {
                  if (this.client.address.postcode) {
                    if (this.client.phoneNumber) {
                      if (this.client.emergencyContact.firstName || this.client.emergencyContact.lastName || this.client.emergencyContact.phoneNumber || this.client.emergencyContact.relationship) {
                        if (this.client.rightToWork.citizenship) {
                          if (this.client.rightToWork.NINO) {
                            if (this.bankDetails.accountNo) {
                              if (this.bankDetails.sortCode) {
                                return true
                              } else {
                                this.$toastr.e('Please fill in your bank sort code')
                              }
                            } else {
                              this.$toastr.e('Please fill in your bank account number')
                            }
                          } else {
                            this.$toastr.e('Please fill in your National Insurance Number')
                          }
                        } else {
                          this.$toastr.e('Please fill in your citizenship')
                        }
                      } else {
                        this.$toastr.e('Please fill in your emergency contact details')
                      }
                    } else {
                      this.$toastr.e('Please fill in your phone number')
                    }
                  } else {
                    this.$toastr.e('Please fill in the postcode of your address')
                  }
                } else {
                  this.$toastr.e('Please fill in the town in your address')
                }
              } else {
                this.$toastr.e('Please fill in the first line of your address')
              }
            } else {
              this.$toastr.e('Please fill in your date of birth')
            }
          } else {
            this.$toastr.e('Please fill in your stage name (if this is the same as your legal name please leave this filled in)')
          }
        } else {
          this.$toastr.e('Please fill in your last name')
        }
      } else {
        this.$toastr.e('Please fill in your first name')
      }

      return false
    }
  },
  async mounted() {
    this.client = await this.get(`Client/Id/${this.getCookie('id')}`)
    await fetch('https://restcountries.com/v3.1/all')
      .then(async (data) => this.countries = (await data.json()).sort(function (a, b) {
        if (a.name.common < b.name.common) { return -1; }
        if (a.name.common > b.name.common) { return 1; }
        return 0;
      }))
    this.loaded = true;
  },
  computed: {
    logo() {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/logo/Thin.png`
    }
  }
}
</script>
<style>
.bg-onboarding {
  background-color: lightgray;
}

.input-onboarding {
  border: none;
  border-bottom: dotted 1px;
  width: 100%;
}
</style>
