import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue'
import PublicLayout from 'src/pages/Dashboard/Layout/PublicLayout.vue'
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue'
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue'
import ClientDashboard from 'src/pages/ClientDashboard/ClientDashboard.vue'
// Pages
import CreateJobWizard from 'src/pages/Jobs/JobCreation/CreateJobWizard.vue'
import AddClientWizard from 'src/pages/Clients/AddClientWizard.vue'
import ClientList from 'src/pages/Clients/ClientList.vue'
import CommercialClientList from 'src/pages/Clients/CommercialClientList.vue'
import CommercialApplicationList from 'src/pages/Clients/Applications.vue'
import FullClientList from 'src/pages/Clients/FullClientList.vue'
import StaffList from 'src/pages/Staff/StaffList.vue'
import JobList from 'src/pages/Jobs/JobList.vue'
import ForumAdmin from 'src/pages/Forum/Admin.vue'
import CreateSubmission from 'src/pages/Jobs/CreateSubmission.vue'
import BreakdownList from 'src/pages/Jobs/BreakdownList.vue'
import BreakdownProfile from 'src/pages/Jobs/Breakdown.vue'
import SubmissionList from 'src/pages/Jobs/SubmissionList.vue'
import JobProfile from 'src/pages/Jobs/JobProfile.vue'
import ClientProfile from 'src/pages/Clients/ClientProfile.vue'
import Onboarding from 'src/pages/Clients/Onboarding.vue'

import CommercialSubmission from 'src/pages/Public/CommercialSubmission.vue'
import TapeView from 'src/pages/Public/TapeView.vue'
import ClientInvoice from 'src/pages/Public/ClientInvoice.vue'

import User from 'src/pages/Dashboard/Pages/UserProfile.vue'
import Settings from 'src/pages/GeneralViews/Settings.vue'
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue'
import Login from 'src/pages/Dashboard/Pages/Login.vue'
import StaffLogin from 'src/pages/Dashboard/Pages/StaffLogin.vue'
import ResetPassword from 'src/pages/Dashboard/Pages/ForgotPassword.vue'
import SetNewPassword from 'src/pages/Dashboard/Pages/SetNewPassword.vue'
import SupportHub from 'src/pages/SupportHub/Overview.vue'
import Ticket from 'src/pages/SupportHub/Ticket.vue'
import NewTicket from 'src/pages/SupportHub/NewTicket.vue'

import FinanceHub from 'src/pages/Finance/FinanceHub.vue'
import Customers from 'src/pages/Finance/Customers.vue'
import Payroll from 'src/pages/Finance/Payroll.vue'
import Payslip from 'src/pages/Finance/Payslip.vue'
import CommissionList from 'src/pages/Finance/CommissionList.vue'
import ClientFinance from 'src/pages/Finance/ClientFinance.vue'
import AccountMatching from 'src/pages/Finance/AccountMatching.vue'
import BankAuth from 'src/pages/Finance/BankAuth.vue'

import SageCallback from 'src/pages/SageCallback.vue'

import ChallengesGallery from 'src/pages/Challenges/Gallery.vue'
import Challenges from 'src/pages/Challenges/MainView.vue'

// Calendar
const Calendar = () => import('src/pages/Calendar/NewCalendar.vue')
// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue')

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/pages/user',
  children: [
    {
      path: 'user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let staffLoginPage = {
  path: '/login/staff',
  name: 'Login',
  component: StaffLogin,
  props: route => ({
    code: route.query.code,
  })
}

let resetPassword = {
  path: '/reset-password',
  name: 'Reset Password',
  component: ResetPassword
}

let setNewPassword = {
  path: '/new-password',
  name: 'Reset Password',
  component: SetNewPassword,
  props: route => ({
    id: route.query.id,
  })
}

let onboardingForm = {
  path: '/onboarding',
  name: 'Client Onboarding',
  component: Onboarding,
}

let calendar = {
  path: '',
  name: '',
  component: DashboardLayout,
  children: [
    {
      path: '/calendar',
      name: 'Calendar',
      component: Calendar
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
    }
  ]
}

const routes = [
  {
    path:'/public/',
    component: PublicLayout,
    children: [
      {
        path: 'commercial/project',
        name: 'Public- Submission',
        component: CommercialSubmission,
        props: route => ({
          id: route.query.id,
        })
      },
      {
        path: 'submission/tape',
        name: 'Public- Tape Submission',
        component: TapeView,
        props: route => ({
          id: route.query.id,
        })
      },
      {
        path: 'invoice',
        name: 'Public- Invoice',
        component: ClientInvoice,
        props: route => ({
          id: route.query.id,
        })
      }
    ]
  },
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: ClientDashboard
      },
      {
        path: '/challenges',
        name: 'Challenges',
        component: Challenges
      },
      {
        path: '/challenge-gallery',
        name: 'Challenge Gallery',
        component: ChallengesGallery,
        props: route => ({
          id: route.query.id,
        })
      },
      {
        path: '/finance',
        name: 'Finance Portal',
        component: ClientFinance,
      },
      {
        path: '/support',
        name: 'Support Hub',
        component: SupportHub,
      },
      {
        path: '/support/ticket',
        name: 'Support Ticket',
        component: Ticket,
        props: route => ({
          id: route.query.id
        })
      },
      {
        path: '/support/ticket/new',
        name: 'New Ticket',
        component: NewTicket,
      },
      {
        path: 'bankauth',
        name: 'Bank Auth',
        component: BankAuth,
        props: route => ({
          code: route.query.code
        }),
      }
    ]
  },
  onboardingForm,
  pagesMenu,
  calendar,
  loginPage,
  staffLoginPage,
  resetPassword,
  setNewPassword,
  {
    path: '/sage/callback',
    component: SageCallback,
    props: route => ({
      code: route.query.code,
      country: route.query.country
    }),
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/overview',
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      },
      {
        path: 'breakdowns/list',
        name: 'Breakdowns',
        component: BreakdownList,
      },
      {
        path: 'breakdowns/:id',
        name: 'Breakdowns',
        component: BreakdownProfile,
      },
      {
        path: 'submissions/list',
        name: 'Submissions',
        component: SubmissionList,
      },
      {
        path: 'submissions/log',
        name: 'Log Submission',
        component: CreateSubmission,
      },
      {
        path: 'jobs/create',
        name: 'Create Job',
        component: CreateJobWizard
      },
      {
        path: 'jobs/profile',
        name: 'Job Profile',
        component: JobProfile,
        props: route => ({
          id: route.query.id,
        }),
      },
      {
        path: 'clients/add',
        name: 'Add Client',
        component: AddClientWizard,
        props: route => ({
          code: route.query.code,
        })
      },
      {
        path: 'clients/list',
        name: 'Clients',
        component: ClientList
      },
      {
        path: 'clients/commercial/list',
        name: 'Commercial Clients',
        component: CommercialClientList
      },
      {
        path: 'clients/commercial/applications',
        name: 'Commercial Applications',
        component: CommercialApplicationList,
      },
      {
        path: 'clients/detail',
        name: 'Client Details',
        component: FullClientList,
      },
      {
        path: 'clients/profile',
        name: 'Client Profile',
        component: ClientProfile,
        props: route => ({
          id: route.query.id,
        }),
      },
      {
        path: 'staff',
        name: 'Staff',
        component: StaffList,
      },
      {
        path: 'jobs/:type',
        name: 'Job List',
        component: JobList
      },
      {
        path: 'forum',
        name: 'Forum',
        component: ForumAdmin
      },
      {
        path: 'finance/hub',
        name: 'Finance Hub',
        component: FinanceHub,
      },
      {
        path: 'finance/customers',
        name: 'Customers',
        component: Customers,
      },
      {
        path: 'finance/payroll',
        name: 'Payroll',
        component: Payroll,
      },
      {
        path: 'finance/payslip',
        name: 'Payslip',
        component: Payslip,
        props: route => ({
          agentId: route.query.id,
          year: route.query.y,
          month: route.query.m,
        }),
      },
      {
        path: 'finance/commission',
        name: 'Unpaid Commission',
        component: CommissionList,
      },
      {
        path: 'finance/account-matching',
        name: 'Account Matching',
        component: AccountMatching,
      }
    ]
  },
  {path: '*', component: NotFound}
]

export default routes
