<template>
    <div></div>
</template>
<script>
export default {
    props: {
        code: String
    },
    async mounted() {
        var tokenData = await this.get(`Bank/Auth/${this.code}`);
        this.setCookie("rat", tokenData["access_token"], tokenData["expires_in"])
        this.setCookie("ratr", tokenData["refresh_token"])

        this.$router.push('/admin/finance/hub')
    }
}
</script>