<template>
    <div>
      <video v-on:contextmenu.prevent ref="videoPlayer" class="video-js"></video>
    </div>
  </template>

  <script>
  import videojs from 'video.js';

  export default {
    name: 'VideoPlayer',
    props: {
      options: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        player: null
      }
    },
    mounted() {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.log('onPlayerReady', this);
      });
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    }
  }
  </script>
  <style>
  video {
    max-height: 100%;
  }
  .video-js:hover .vjs-big-play-button {
    opacity: 0% !important;
    transition-duration: 1s;
  }
  .video-js {
    max-width: 100%;
  }
  .bigvid .video-js {
    max-height: 50vh;
  }
  .medvid .video-js {
    max-height: 25vh;
  }
  .vjs-big-play-button {
    background-color: green !important;
    opacity: 50% !important;
    transition-duration: 1s;
  }
  .vjs-big-play-button:hover {
    opacity: 0% !important;
    transition-duration: 1s;
  }
</style>
