import { v4 } from "uuid";
import cookies from "./cookies";
import { jsPDF } from "jspdf";

const GlobalFunctions = {
  install(Vue) {
    Vue.mixin({
      methods: {
        guid: () => {
          return v4();
        },
        toBase64: (file) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          }),
        setCookie: (c, v, e) => cookies.setCookie(c, v, e),
        getCookie: (c) => cookies.getCookie(c),
        removeCookie: (c) => cookies.removeCookie(c),
        removeAllCookies: async () => await cookies.removeAllCookies(),
        getSessionStorage: (k) => cookies.getSessionStorage(k),
        setSessionStorage: (k, v) => cookies.setSessionStorage(k, v),
        get: async (e) => {
          var res;
          await fetch(`${process.env.VUE_APP_API}${e}`, {
            headers: {
              AT: process.env.VUE_APP_TOKEN,
            },
          }).then(async (response) => {
            res = response.json();
          });

          return res;
        },
        simpleGet: async (e) => {
          var res;
          await fetch(`${process.env.VUE_APP_API}${e}`, {
            headers: {
              AT: process.env.VUE_APP_TOKEN,
            },
          }).then(async (response) => {
            res = response.text();
          });

          return res;
        },
        post: async (e) => {
          await fetch(`${process.env.VUE_APP_API}${e}`, {
            method: "POST",
            headers: {
              AT: process.env.VUE_APP_TOKEN,
            },
          });
        },
        postBody: async (e, b) => {
          var r;

          await fetch(`${process.env.VUE_APP_API}${e}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              AT: process.env.VUE_APP_TOKEN,
            },
            body: JSON.stringify(b),
          }).then(async (response) => {
            r = response.json();
          });

          return r;
        },
        postVoid: async (e) => {
          var r;

          await fetch(`${process.env.VUE_APP_API}${e}`, {
            method: "POST",
            headers: {
              AT: process.env.VUE_APP_TOKEN,
            },
          }).then(async (response) => {
            r = response.json();
          });

          return r;
        },
        postBodyVoid: async (e, b) => {
          var r;

          await fetch(`${process.env.VUE_APP_API}${e}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              AT: process.env.VUE_APP_TOKEN,
            },
            body: JSON.stringify(b),
          }).then(async (response) => {
            r = response;
          });

          return r;
        },
        delete: async (e) => {
          return await fetch(`${process.env.VUE_APP_API}${e}`, {
            method: "DELETE",
            headers: {
              AT: process.env.VUE_APP_TOKEN,
            },
          });
        },
        hash: (s) => {
          //set variable hash as 0
          var hash = 0;
          // if the length of the string is 0, return 0
          if (s.length == 0) return hash;
          for (var i = 0; i < s.length; i++) {
            var ch = s.charCodeAt(i);
            hash = (hash << 5) - hash + ch;
            hash = hash & hash;
          }
          return hash;
        },
        filterByObject(array, obj) {
          return array.filter((e) =>
            Object.entries(obj).every(([k, v]) => {
              if (e[k] === undefined) {
                e[k] = "";
              }
              switch (true) {
                case e[k].toString().toLowerCase().includes(v.toLowerCase()): // primitives equalition
                  return true;
                case Array.isArray(v): // arrays equaltion
                  return v.every((x) => e[k].includes(x));
                case typeof v === "object": // recursive repeat if object
                  return filterByObject(Object.entries(e[k]), v.toLowerCase());
                default:
                  return false; // unhandled case (maybe there is any)
              }
            })
          );
        },
        datePostFormat(d) {
          var date = new Date(d);
          var year = date.getFullYear();
          var month = date.getMonth();
          var day = date.getDate();

          return new Date(year, month, day);
        },
        dateFormat(d) {
          if (d) {
            if (typeof d === "object") {
              d = d.toString();
            }
            var date = new Date(d);
            var year = date.getFullYear();
            var month = (1 + date.getMonth()).toString();
            month = month.length > 1 ? month : "0" + month;
            var day = date.getDate().toString();
            day = day.length > 1 ? day : "0" + day;

            return `${day}/${month}/${year}`;
          }
        },
        moneyFormat(x, v) {
          let f = new Intl.NumberFormat("en-GB", {
            style: "currency",
            currency: v,
          });

          return f.format(x);
        },
        groupBy(xs, key) {
          return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {});
        },
        filterList(text, key, list) {
          var filter = {};
          if (text !== "") {
            filter[key] = text;
            return this.filterByObject(list, filter);
          } else {
            delete filter[key];
            return this.filterByObject(list, filter);
          }
        },
        autosize(l) {
          var el = l.srcElement;
          setTimeout(function () {
            el.style.cssText = el.style.cssText + "height: auto";
            el.style.cssText =
              el.style.cssText +
              "height:" +
              (el.scrollHeight + 3) +
              "px; overflow: hidden;";
          }, 0);
        },
        createDocument(s, o, n) {
          const d = new jsPDF(o, "pt", "a4");
          const w = d.internal.pageSize.getWidth();
          const h = d.internal.pageSize.getHeight();

          s.style.fontSize = "7pt";
          s.style.width = w - 150 + "pt";

          d.html(s, {
            width: w,
            autoPaging: "text",
            callback: function (d) {
              for (var i = 1; i < d.internal.getNumberOfPages(); i++) {
                d.setPage(i);
                d.setFontSize(10);
                d.text(
                  `Page ${i} of ${d.internal.getNumberOfPages()} - ${n.replace(
                    "_",
                    " "
                  )}`,
                  w + 10,
                  0
                );
              }

              d.save(`${n}.pdf`);
            },
            x: 0,
            y: 10,
          });
        },
        send(id, to, cc, bcc, parameters) {
          this.postBody(`Comms`, {
            id: id,
            to: to,
            cc: cc,
            bcc: bcc,
            parameters: parameters
          });
        },
      },
    });
  },
};

export default GlobalFunctions;
