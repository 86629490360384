<template>
  <div>
    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 c-search">
          <div class="c-input c-search__input c-search__without-label">
            <div class="c-input-wrapper">
              <div class="c-input-label"></div>
              <div class="c-input__container c-input__container--large"><input
                  @input="items = filterList($event.target.value, 'stageName', clientList)" type="text"
                  placeholder="Search client name…" class="c-search__input c-search__without-label" autocomplete="off"
                  value="">
                <div class="c-search__children-wrapper"></div>
              </div>
            </div>
          </div>
          <div class="c-search__button-wrapper"><a href="#/" role="button"
              class="c-button c-button__primary c-button__with-icon" aria-label="Search performer name…"><i class="nc-icon nc-zoom-split"></i></a></div>
        </div>
        <div class="col-md-2"></div>
      </div>
      <br />
      <div class="c-make-suggestions-grid__inner g-bg-default g-col-lg-12 g-inner-grid">
        <ClientCard v-for="client in items" :key="client.id" :client="client" @checked="selectClient"></ClientCard>
      </div>
      <div v-if="canSubmit" class="c-make-suggestions__buttons-set g-col-lg-12 g-inner-grid">
        <div class="g-col-lg-4 g-col-md-1"></div>
        <div class="c-make-suggestions__selected-counter-outer g-col-lg-2 g-col-md-3"><span
            class="c-make-suggestions__selected-counter">{{ this.selectedClients.length }} profiles selected:</span></div>
        <div class="g-col-lg-3 g-col-md-4"><a @click="cancel" role="button" class="c-button c-button__secondary"><span
              class="c-button__text">Cancel</span></a></div>
        <div class="g-col-lg-3 g-col-md-4"><a @click="submit" role="button" class="c-button c-button__primary"><span
              class="c-button__text">Submit profiles</span></a></div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientCard from './Cards/ClientCard.vue';
import Spinner from './Spinner.vue';
export default {
  components: {
    ClientCard,
    Spinner
  },
  props: {
    preSelected: {
      type: Array,
      default: () => {return []},
    },
    commercial: Boolean,
    canSubmit: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      loading: true,
      clientList: [],
      selectedClients: [],
      items: [],
      filter: {},
      pages: [],
    }
  },
  async mounted() {
    await fetch(this.commercial ? `${process.env.VUE_APP_API}Client/Type/4` : `${process.env.VUE_APP_API}Client/Agent/${this.getCookie('id')}`, {
      headers: {
        "AT": process.env.VUE_APP_TOKEN,
      },
    })
      .then((res) => res.json())
      .then((d) => {
        this.clientList = d;

        if (this.preSelected.length > 0) {
          for (var i = 0; i < this.preSelected.length; i++) {
            this.selectedClients.push(this.preSelected[i].clientId)
          }
        }

        for (var i = 0; i < this.clientList.length; i++) {
          this.clientList[i].selected = this.selectedClients.includes(this.clientList[i].id)
        }

        this.items = this.clientList;
        this.loading = false;
      })
  },
  methods: {
    selectClient(e) {
      if (e.checked) {
        this.selectedClients.push(e.clientId)
      } else {
        const i = this.selectedClients.indexOf(e.clientId);
        this.selectedClients.splice(i, 1);
      }

      this.$emit('list-changed', this.selectedClients);
    },
    reset() {
      this.selectedClients = [];
    },
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      for (var i = 0; i < this.preSelected.length; i++) {
        for (var x = 0; x < this.selectedClients.length; x++) {
          if (this.selectedClients[x] === this.preSelected[i].clientId) {
            this.selectedClients.splice(x, 1)
          }
        }
      }

      this.$emit('submit', this.selectedClients)
    }
  },
}
</script>
<style>
.g-inner-grid-12,
.g-inner-grid.g-col-lg-12 {
  grid-template-columns: repeat(12, 1fr);
}

.g-col-lg-12 {
  grid-column: span 12;
}

.g-col-lg-4 {
    grid-column: span 4;
}

.g-col-lg-3 {
    grid-column: span 3;
}

.g-col-lg-2 {
    grid-column: span 2;
}

@media (max-width: 1200px) {
  .g-col-md-4 {
    grid-column: span 4 !important;
  }

  .g-col-md-3 {
    grid-column: span 3 !important;
  }

  .g-col-md-1 {
    grid-column: span 1;
  }
}


@media (max-width: 720px) {
  .c-make-suggestions-grid__inner {
    grid-row-gap: 10px;
  }

  .g-content-area,
  .g-inner-grid {
    grid-column-gap: 0;
  }
}

.c-make-suggestions-grid__inner {
  grid-column-gap: 20px !important;
  grid-row-gap: 20px;
  grid-template-rows: -webkit-min-content;
  grid-template-rows: min-content;
  padding: 10px 0;
}

.g-content-area,
.g-inner-grid {
  grid-column-gap: 1.6666666667rem;
  display: grid;
}

.c-search {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.c-search__input {
  flex-grow: 1;
}

.c-search__input .c-input-wrapper {
  margin: 0;
}

.c-input-wrapper {
  margin-bottom: 40px;
}

.c-input-search-icon__container,
.c-input-wrapper {
  position: relative;
}

.g-bg-default .c-input-wrapper input,
.g-bg-default .c-input-wrapper textarea {
  background-color: #f5f4f9;
}

.c-search__input .c-input-wrapper input {
  padding-right: 45px;
}

.c-input input {
  height: 50px;
  padding: 0 12px;
}

.c-make-suggestions__selected-counter {
    color: #26247b;
    display: table-cell;
    font-size: 18px;
    vertical-align: middle;
}

.c-input-wrapper input,
.c-input-wrapper textarea {
  background-color: #f5f4f9;
  border: none;
  color: #28292a;
  display: block;
  font-family: inherit;
  font-size: 18px;
  outline: 1px solid #75797e;
  outline-offset: -1px;
  width: 100%;
}

.c-make-suggestions__buttons-set {
    padding: 15px;
}

.g-content-area, .g-inner-grid {
    grid-column-gap: 1.6666666667rem;
    display: grid;
}

.c-make-suggestions__selected-counter-outer {
    display: table;
    height: 100%;
    text-align: right;
}

[role=button], button {
    cursor: pointer;
}

.c-button {
    align-items: center;
    border: 3px solid;
    border-radius: 1px;
    display: flex;
    font-family: Source Sans Pro,sans-serif;
    font-size: 18.66px;
    font-weight: 600;
    justify-content: center;
    line-height: 18px;
    min-height: 50px;
    padding: 12px;
    transition: none .2s ease-in-out;
    width: 100%;
}

.c-button__primary {
    background: #ea5d5a;
    border-color: #ea5d5a;
    color: #fff;
}

.c-button__secondary {
    background: transparent;
    border-color: #ea5d5a;
    color: #ea5d5a;
}</style>