<template>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <h3>Add a Buyer</h3>
      <hr />
      <label>Name</label>
      <input style="width: 100%" v-model="model.name" />
      <br />
      <br />
      <label>Email</label>
      <input style="width: 100%" v-model="model.email" />
      <br />
      <br />
      <label>Address 1</label>
      <input style="width: 100%" v-model="model.address.line1" />
      <br />
      <br />
      <label>Address 2</label>
      <input style="width: 100%" v-model="model.address.line2" />
      <br />
      <br />
      <label>Address 3</label>
      <input style="width: 100%" v-model="model.address.line3" />
      <br />
      <br />
      <label>Town</label>
      <input style="width: 100%" v-model="model.address.town" />
      <br />
      <br />
      <label>County</label>
      <input style="width: 100%" v-model="model.address.county" />
      <br />
      <br />
      <label>Postcode</label>
      <input style="width: 100%" v-model="model.address.postcode" />
      <br />
      <br />
      <button class="btn btn-primary" @click="$emit('add', model)">Add</button>
    </div>
    <div class="col-md-1"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      model: {
        name: "",
        email: "",
        address: {
          line1: "",
          line2: "",
          line3: "",
          town: "",
          county: "",
          postcode: "",
        },
      },
    };
  },
};
</script>
