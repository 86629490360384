<template>
  <div>
    <Table :columns="columns" :data="data"></Table>
  </div>
</template>

<script>
import { Table } from "../../components";
export default {
  components: {
    Table
  },
  data() {
    return {
      columns: [
        "Title",
        "Text",
        "Posted",
        "Edit"
      ],
      data: [
        {
          title: "This is a test",
          text: "This post is a test post to see what happens when I put this into the...",
          posted: "01/02/2022"
        }
      ]
    }
  }
}
</script>

<style>

</style>
