<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-8">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard
            shape="tab"
            @on-complete="wizardComplete"
            error-color="#FB404B"
            color="#35495E"
            title="Add Client"
            subtitle="Creating a new client in the system"
          >
            <tab-content
              title="Client Name"
              class="col-12"
              :before-change="() => validateStep('clientName')"
              icon="nc-icon nc-badge"
            >
              <client-name
                :can-store-image="code !== ''"
                ref="clientName"
                @on-validated="onStepValidated"
              ></client-name>
            </tab-content>

            <tab-content
              title="Contact Info"
              class="col-12"
              :before-change="() => validateStep('basicDetails')"
              icon="nc-icon nc-notes"
            >
              <basic-details
                ref="basicDetails"
                @on-validated="onStepValidated"
              ></basic-details>
            </tab-content>

            <tab-content
              title="Last step"
              class="col-12"
              icon="nc-icon nc-check-2"
            >
              <div>
                <h2 class="text-center text-space">Now let's finish up...</h2>
                <h4 class="text-center text-space">
                  Click finish to submit the client and send them a contract
                </h4>

                <Checkbox
                  v-model="contractSigned"
                  class="text-center text-space"
                  label="Has this client already signed a contract?"
                ></Checkbox>
              </div>
            </tab-content>

            <button
              slot="prev"
              class="btn btn-default btn-fill btn-wd btn-back"
            >
              Back
            </button>
            <button
              slot="next"
              class="btn btn-default btn-fill btn-wd btn-next"
            >
              Next
            </button>
            <button
              :disabled="submitting"
              slot="finish"
              class="btn btn-success btn-fill btn-wd"
            >
              Finish
            </button>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import ClientName from "./ClientName.vue";
import BasicDetails from "./BasicDetails.vue";
import Swal from "sweetalert2";
import { Checkbox } from "../../components";

export default {
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      wizardModel: {},
      submitting: false,
      contractSigned: false,
    };
  },
  components: {
    FormWizard,
    TabContent,
    ClientName,
    BasicDetails,
    Checkbox,
  },

  methods: {
    validateStep(ref) {
      if (this.getCookie("token")) {
      } else {
        this.$router.push("/login");
      }
      return this.$refs[ref].validate();
    },
    onStepValidated(validated) {
      this.wizardModel = { ...this.wizardModel, ...validated };
    },
    generatePassword() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = " ";
      const length = 8;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }

      return result;
    },
    async wizardComplete() {
      this.submitting = true;

      var body = {
        username: this.wizardModel.email,
        firstName: this.wizardModel.firstName,
        lastName: this.wizardModel.lastName,
        spotlightPIN: this.wizardModel.spotlightPin,
        clientType: Number(this.wizardModel.type),
        password: this.generatePassword(),
        agentId: this.getCookie("id"),
      };

      try {
        const userId = await this.postBody(
          `Client?contractSigned=${this.contractSigned}`,
          body
        );

        this.wizardModel.profilePic.position = 0;
        await fetch(
          `https://storage.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_BUCKET}/o?name=headshots/${userId}&uploadType=media`,
          {
            headers: new Headers({
              Authorization: `Bearer ${this.getCookie("token")}`,
              "Content-Type": this.wizardModel.profilePic.raw.type,
            }),
            method: "POST",
            body: this.wizardModel.profilePic.raw,
          }
        );

        var emailId = (body.clientType === 4) ? 6 : 2;
        var url = (body.clientType === 4) ? 'https://castmate.expression-agency.com/' : window.location.origin;

        this.send(emailId, [{
          email: body.username,
          name: `${body.firstName} ${body.lastName}`
        }], [], [], {
          USERNAME: body.username,
          PASSWORD: body.password,
          URL: url
        });

        Swal.fire({
          title: "Submitted",
          text: this.contractSigned
            ? "Your client has been added to the system!"
            : "The contract has been sent off, please sign your part so the client can sign theirs.",
          type: "success",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false,
        }).then(() => window.location.replace("/admin/clients/list"));
      } catch {
        Swal.fire({
          title: "Submission Failed",
          text: "Something went wrong uploading the client to the system",
          type: "danger",
          confirmButtonClass: "btn btn-danger",
          buttonsStyling: false,
        });

        this.submitting = false;
      }
    },
  },
};
</script>
<style lang="scss">
.vue-form-wizard .wizard-icon-circle.tab_shape {
  background-color: #9a9a9a !important;
  color: white;
}
.vue-form-wizard .wizard-tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .wizard-tab-container {
    display: block;
    animation: fadeIn 0.5s;
  }
}
</style>
