<template>
  <div>
    <button class="btn btn-primary" style="width: 100%" @click=print>Print</button>
    <br />
    <br />
    <Invoice id="invoice" :inv="id" v-if="!loading"></Invoice>
  </div>
</template>
<script>
import Invoice from "@/components/Documents/Invoice.vue";
export default {
  components: {
    Invoice,
  },
  props: {
    id: String,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.loading = false;

    fetch("https://api.ipify.org/")
      .then((res) => res.text())
      .then(async (ip) => {
        await this.post(`PageView/${ip}/Invoice`);
      });
  },
  methods: {
    print() {
      const e = document.getElementById("invoice");
      this.createDocument(e, "h", `Vella Wozniak Invoice`);
    },
  },
};
</script>
