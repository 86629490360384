<template>
  <div>
    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-md-6">
            <stats-card title="£0.00" subTitle="Client Account">
              <div slot="header" class="icon-warning">
                <i class="nc-icon nc-puzzle-10"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-xl-3 col-md-6">
            <stats-card title="£0.00" subTitle="Commission">
              <div slot="header" class="icon-success">
                <i class="nc-icon nc-money-coins"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-xl-3 col-md-6">
            <stats-card :title="salesInvoices.length.toString()" subTitle="Open Invoices">
              <div slot="header" class="icon-danger">
                <i class="nc-icon nc-lock-circle-open"></i>
              </div>
            </stats-card>
          </div>

          <div class="col-xl-3 col-md-6">
            <stats-card title="0" subTitle="Overdue Invoices">
              <div slot="header" class="icon-info">
                <i class="nc-icon nc-bank"></i>
              </div>
            </stats-card>
          </div>
        </div>
        <br />
        <h4>Project Invoices</h4>
        <div class="row">
          <card v-for="invoice in salesInvoices" :key="invoice.id" class="col-md-12">
            <div v-if="canComplete(invoice) || !invoice.sageId" class="row">
              <div class="col-md-10"></div>
              <div class="col-md-2">
                <button v-if="canComplete(invoice)" @click="completeInvoices(invoice)" class="btn btn-success"
                  style="width: 100%;">
                  Mark as Complete
                </button>
                <button v-if="!invoice.sageId" @click="completeInvoices(invoice)" class="btn btn-danger"
                  style="width: 100%;">
                  Delete
                </button>
              </div>
            </div>
            <div class="row">
              <InvoiceCard :title="`From: ${invoice.job.name}`" :invoice="invoice" colour="aliceblue" class="col-md-4"
                @validate="openValidationModal" @pay="setInvoiceToPay"></InvoiceCard>

              <CommissionCard v-if="invoice.commissionInvoice" title="Commission" :invoice="invoice.commissionInvoice"
                colour="white" class="col-md-4"
                :payable="invoice.clientInvoice ? (invoice.clientInvoice.totalPaid === invoice.clientInvoice.total) : true">
              </CommissionCard>

              <InvoiceCard v-if="invoice.clientInvoice" :title="`For: ${invoice.clientName}`"
                :invoice="invoice.clientInvoice" colour="lightcoral" class="col-md-4" @validate="openValidationModal"
                @pay="setInvoiceToPay"></InvoiceCard>
            </div>
          </card>
        </div>
        <h4>Misc. Invoices &nbsp;<button class="btn btn-primary" @click="newModal = true">New</button></h4>
        <div class="row">
          <card v-for="invoice in miscInvoices" :key="invoice.id" class="col-md-3">
            <h4>{{ invoice.reference }}</h4>
            <hr />
            <h5>Due: {{ dateFormat(invoice.dueDate) }}</h5>
            <br />
            <h6>Total: £{{ invoice.total }}</h6>
            <h6>Paid: £{{ invoice.totalPaid }}</h6>
            <br />
            <h5>Remaining: £{{ invoice.total - invoice.totalPaid }}</h5>
            <hr />
            <div v-if="!invoice.sageId">
              <button class="btn btn-success" @click="openValidationModal(invoice)" style="width: 100%">Validate</button>
            </div>
            <div v-else>
              <button @click="setInvoiceToPay(invoice)" class="btn btn-primary" style="width:100%">Pay</button>
            </div>
          </card>
        </div>
        <el-dialog title="Validate Invoice" :visible.sync="validationModal">
          <InvoiceValidation :invoice="validatingInvoice" :customers="customers" :accounts="sageAccounts">
          </InvoiceValidation>
        </el-dialog>
        <el-dialog id="job-invoice" title="Create Invoice" :visible.sync="newModal">
          <InvoiceCreation :misc="true"></InvoiceCreation>
        </el-dialog>
        <el-dialog title="Pay Invoice" :visible.sync="payModal">
          <InvoicePayment :invoice="payingInvoice" :accounts="bankAccounts" :methods="paymentMethods">
          </InvoicePayment>
        </el-dialog>
        <el-dialog title="Client Payment Letter" :visible.sync="letterModal">
          <ClientRemittance id="client-letter"></ClientRemittance>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog } from "element-ui";
import { StatsCard, Spinner } from "src/components/index";
import InvoiceCreation from "../../components/InvoiceCreation.vue";
import InvoiceCard from "../../components/InvoiceCard.vue";
import CommissionCard from "../../components/CommissionCard.vue";
import InvoicePayment from "../../components/InvoicePayment.vue";
import InvoiceValidation from "../../components/InvoiceValidation.vue";
import ClientRemittance from "../../components/Documents/ClientRemittance.vue";

export default {
  components: {
    [Dialog.name]: Dialog,
    Spinner,
    StatsCard,
    InvoiceCreation,
    InvoiceCard,
    CommissionCard,
    InvoicePayment,
    InvoiceValidation,
    ClientRemittance,
  },
  props: {
    code: String,
  },
  data() {
    return {
      loading: true,
      salesInvoices: [],
      miscInvoices: [],
      invoicePayment: 0.00,
      validationModal: false,
      payModal: false,
      newModal: false,
      letterModal: false,
      validatingInvoice: {},
      validatingContact: "",
      sageAccounts: [],
      customers: [],
      bankAccounts: [],
      paymentMethods: [],
      payingInvoice: {},
    }
  },
  async created() {
  },
  async mounted() {
    await this.refresh()
  },
  computed: {

  },
  methods: {
    async tokenReload() {
      if (!this.getCookie('sg')) {
        if (this.getCookie('sgr')) {
          await fetch("https://oauth.accounting.sage.com/token", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              "Accept": "application/json"
            },
            body: encodeURIComponent(`client_id=fcac3868-1c5a-4121-959b-dbc3650c87a8/1b5bc4fb-5f25-4b41-bf73-ee3e753f3714&client_secret=T>?bh.0Xo6Xq//JAO}@q&grant_type=refresh_token&refresh_token=${this.getCookie('sgr')}`)
          }).then(async (res) => res.json())
            .then((data) => {
              this.setCookie("sg", data.access_token, data.expires_in / 86400)
              this.removeCookie('sgr')
            });
        } else {
          window.location.replace(`https://www.sageone.com/oauth2/auth/central?filter=apiv3.1&client_id=fcac3868-1c5a-4121-959b-dbc3650c87a8/1b5bc4fb-5f25-4b41-bf73-ee3e753f3714&response_type=code&redirect_uri=${window.location.origin}/sage/callback`)
        }
      }
    },
    async refresh() {
      this.loading = true;

      this.salesInvoices = await this.get('Invoice/Incoming')
      this.miscInvoices = await this.get('Invoice/Misc')

      for (let i = 0; i < this.salesInvoices.length; i++) {
        this.salesInvoices[i].paying = false

        try {
          this.salesInvoices[i].clientInvoice = await this.get(`Invoice/Linked/${this.salesInvoices[i].id}`)
          this.salesInvoices[i].clientInvoice.paying = false
        }
        catch
        {
          // Do nothing
        }

        try {
          this.salesInvoices[i].commissionInvoice = await this.get(`Commission/Invoice/${this.salesInvoices[i].id}`)
          this.salesInvoices[i].commissionInvoice.paying = false
        }
        catch {
          // Do nothing
        }

        try {
          this.salesInvoices[i].clientName = await this.simpleGet(`Client/Name?id=${this.salesInvoices[i].clientId}`)
        } catch {
          this.salesInvoices[i].clientName = ""
        }

        this.salesInvoices[i].job = await this.get(`Job/${this.salesInvoices[i].jobId}`)
      }

      this.loading = false;
    },
    removeInvoice(invoice) {
      this.salesInvoices.splice(this.salesInvoices.indexOf(invoice), 1)
    },
    canComplete(invoice) {
      if (invoice.paid) {
        if (invoice.clientInvoice && invoice.clientInvoice.paid) {
          return true
        } else if (!invoice.clientInvoice) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async openValidationModal(invoice) {
      await this.tokenReload();
      await fetch(`${process.env.VUE_APP_API}Invoice/${invoice.type !== 1 ? 'SLAccounts' : 'PLAccounts'}`, {
        method: "GET",
        headers: {
          "AT": process.env.VUE_APP_TOKEN,
          "SAT": this.getCookie('sg')
        }
      }).then(async (response) => {
        this.sageAccounts = (await response.json()).$items;
      });

      await fetch(`${process.env.VUE_APP_API}Invoice/${invoice.type !== 1 ? 'Customers' : 'Suppliers'}`, {
        method: "GET",
        headers: {
          "AT": process.env.VUE_APP_TOKEN,
          "SAT": this.getCookie('sg')
        }
      }).then(async (response) => {
        this.customers = (await response.json()).$items;
      });

      for (var i = 0; i < invoice.lines.length; i++) {
        switch (invoice.type) {
          case 0:
            invoice.lines[i].sageAccount = "bf411147bfde11ecb2850279d0cc53c5";
            break;
          case 1:
            invoice.lines[i].sageAccount = "bf4ed414bfde11ecb2850279d0cc53c5";
            break;
        }
      }

      this.validatingInvoice = invoice;
      this.validationModal = true;
    },
    setInvoicePayment(invoice) {
      let i = this.salesInvoices.indexOf(invoice);
      this.invoices[i].paying = true;
      this.invoicePayment = invoice.total;
    },
    async setInvoiceToPay(inv) {
      await this.tokenReload();
      if (this.bankAccounts.length === 0) {
        await fetch(`${process.env.VUE_APP_API}Invoice/BankAccounts`, {
          method: "GET",
          headers: {
            "AT": process.env.VUE_APP_TOKEN,
            "SAT": this.getCookie('sg')
          }
        }).then(async (response) => {
          this.bankAccounts = (await response.json()).$items;
        });
      }

      if (this.paymentMethods.length === 0) {
        await fetch(`${process.env.VUE_APP_API}Invoice/PaymentMethods`, {
          method: "GET",
          headers: {
            "AT": process.env.VUE_APP_TOKEN,
            "SAT": this.getCookie('sg')
          }
        }).then(async (response) => {
          this.paymentMethods = (await response.json()).$items;
        });
      }

      this.payingInvoice = inv;
      this.payModal = true;
    },
    async completeInvoices(invoice) {
      await this.post(`Invoice/Complete/${invoice.id}`)

      if (invoice.clientInvoice) {
        await this.post(`Invoice/Complete/${invoice.clientInvoice.id}`)
      }

      await this.$toastr.s('Success')

      this.salesInvoices.splice(this.salesInvoices.indexOf(invoice), 1)
    }
  }
}
</script>
