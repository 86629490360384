<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <span>Paid: £ &nbsp;</span>
      </div>
      <div class="col-md-6">
        <input type="number" step=".01" min="0" :max="Number(invoice.totalNet)" v-model="invoicePayment" />
      </div>
    </div>
    <br />
    <div class="row">
      <span class="col-md-12">Remaining: £{{ invoice.total - (Number(invoicePayment) +
        invoice.totalPaid) }}</span>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <span>Paid From &nbsp;</span>
      </div>
      <div class="col-md-6">
        <select v-model="payingBankAccount">
          <option></option>
          <option v-for="account in accounts" :key="account.id" :value="account.id">{{
            account.displayed_as }}</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <span>Payment Method &nbsp;</span>
      </div>
      <div class="col-md-6">
        <select v-model="paymentMethod">
          <option></option>
          <option v-for="method in methods" :key="method.id" :value="method.id">{{
            method.displayed_as }}</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-md-6">
        <span>Reference &nbsp;</span>
      </div>
      <div class="col-md-6">
        <input v-model="paymentReference" />
      </div>
    </div>
    <hr />
    <div class="row">
      <button :disabled="Number(invoice.total - (Number(invoicePayment) + invoice.totalPaid)) < 0"
        @click="payInvoice(invoice.id)" class="btn btn-primary" style="width:100%">Confirm</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    invoice: Object,
    accounts: Array,
    methods: Array,
  },
  data() {
    return {
      invoicePayment: 0.00,
      paymentReference: "",
      payingBankAccount: "",
      paymentMethod: "",
    }
  },
  methods: {
    async payInvoice(id) {
      var body = JSON.stringify({
        bankAccountId: this.payingBankAccount,
        paymentMethodId: this.paymentMethod,
        reference: this.paymentReference,
        amount: this.invoicePayment,
        discount: 0.00
      })

      await fetch(`${process.env.VUE_APP_API}Invoice/Pay/${id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "AT": process.env.VUE_APP_TOKEN,
          "SAT": this.getCookie('sg')
        },
        body: body
      })
        .then((response) => response.json())
        .then(async (d) => {
          if (d.id) {
            await this.$toastr.s('Success');
            // eslint-disable-next-line vue/no-mutating-props
            this.$emit('paid', this.invoice, this.invoicePayment)
          } else {
            for (var i = 0; i < d.length; i++) {
              await this.$toastr.e(d[i].$message)
            }
          }

        })
    },
  }
}
</script>