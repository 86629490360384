<template>
    <card>
        <div class="row">
            <div class="col-lg-5 col-md-7 col-sm-5">
                <img class="headshot small" :src="headshot(s.id)" />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h5 class="bold">{{ s.firstName }} {{ s.lastName }}</h5>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-md-6"></div>
            <div class="col-md-6">
                <button v-if="resendInvite" class="btn btn-primary" @click="sendInvite">Resend Invite</button>
                <button v-else class="btn btn-warning" @click="resetPassword">Reset Password</button>
            </div>
        </div>
    </card>
</template>
<script>
export default {
    props: {
        s: Object,
    },
    data() {
        return {
            resendInvite: false,
        }
    },
    async mounted() {
        await this.get(`Password/Reset/Latest?userId=${this.s.id}`)
            .catch(() => this.resendInvite = true);
    },
    methods: {
        headshot(id) {
            return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${id}`
        },
        async sendInvite() {
            const password = await this.postBodyVoid(`Staff/Password?userId=${this.s.id}`, this.s.password);

            this.send(
                  2,
                  [this.s.username],
                  [],
                  [],
                  {
                    USERNAME: this.s.username,
                    PASSWORD: await password.text(),
                    URL: window.location.origin
                  }
                );

            await this.$toastr.s("Success");
        },
        async resetPassword() {
            await fetch(`${process.env.VUE_APP_API}Password/Reset?id=${this.s.id}`, {
                method: 'POST',
                headers: {
                    "AT": process.env.VUE_APP_TOKEN,
                },
            })
                .then((res) => res.text())
                .then(async (id) => {
                    this.send(
                      3,
                      [this.s.username],
                      [],
                      [],
                      {
                        URL: `${window.location.origin}/new-password?id=${id.replace('"', '')}`
                      }
                    );

                    await this.$toastr.s("Success")
                })
        },
    }
}
</script>
