<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">Now let's get some more information...</h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="startDate"
            v-slot="{ passed, failed }"
          >
            <date-picker :success="passed" :error="failed" v-model="startDate"></date-picker>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="endDate"
            v-slot="{ passed, failed }"
          >
            <date-picker :success="passed" :error="failed" v-model="startDate"></date-picker>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div v-for="client in clients" :key="client.id" class="col-md-3">
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { Select, Option, DatePicker } from 'element-ui'
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);

  export default {
    data() {
      return {
        startDate: new Date(),
        email: "",
        endDate: new Date(),
        clients: []
      };
    },
    components: {
      DatePicker
    },
    methods: {
      validate() {
        var item = {
          startDate: this.startDate,
          endDate: this.endDate
        }
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', item)
          return res
        })
      }
    }
  }
</script>
<style>
</style>
