<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
      <user-menu></user-menu>
      <mobile-menu :notifications-count="notifications.length"></mobile-menu>
      <template slot="links">
        <sidebar-item v-if="access > 0" :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/admin/overview'}"></sidebar-item>

        <sidebar-item v-else :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/dashboard'}"></sidebar-item>

        <sidebar-item v-if="access > 0" :link="{ name: 'Clients', icon: 'nc-icon nc-single-02' }">
          <sidebar-item :link="{name: 'List', icon: 'nc-icon nc-single-02', path: '/admin/clients/list'}"></sidebar-item>
          <sidebar-item :link="{name: 'Details', icon: 'nc-icon nc-single-02', path: '/admin/clients/detail'}"></sidebar-item>
          <sidebar-item :link="{name: 'Commercials', icon: 'nc-icon nc-chart-pie-35'}" class="ml-1">
            <sidebar-item class="ml-2" :link="{name: 'Applications', icon: 'nc-icon nc-single-02', path: '/admin/clients/commercial/applications'}"></sidebar-item>
            <sidebar-item class="ml-2" :link="{name: 'List', icon: 'nc-icon nc-single-02', path: '/admin/clients/commercial/list'}"></sidebar-item>
          </sidebar-item>
        </sidebar-item>

        <sidebar-item v-if="access === 4"  :link="{name: 'Staff', icon: 'nc-icon nc-single-02', path: '/admin/staff'}"></sidebar-item>

        <sidebar-item v-if="access > 0" :link="{ name: 'Jobs', icon: 'nc-icon nc-app'}">
          <sidebar-item :link="{name: 'Breakdowns', path: '/admin/breakdowns/list'}"></sidebar-item>
          <sidebar-item :link="{name: 'Submissions', path: '/admin/submissions/list'}"></sidebar-item>
          <sidebar-item :link="{name: 'Auditions', path: '/admin/jobs/auditions'}"></sidebar-item>
          <sidebar-item :link="{name: 'In-Progress', path: '/admin/jobs/current'}"></sidebar-item>
          <sidebar-item :link="{name: 'Completed', path: '/admin/jobs/completed'}"></sidebar-item>
          <hr/>
          <sidebar-item :link="{name: 'Log Submissions', path: '/admin/submissions/log'}"></sidebar-item>
        </sidebar-item>

        <sidebar-item :link="{ name: 'Challenges', icon: 'nc-icon nc-controller-modern', path: '/challenges'}"></sidebar-item>

        <sidebar-item v-if="access === 4"  :link="{name: 'Finance', icon: 'nc-icon nc-money-coins'}">
          <sidebar-item :link="{name: 'Invoices', path: '/admin/finance/hub'}"></sidebar-item>
          <sidebar-item :link="{name: 'Customers', path: '/admin/finance/customers'}"></sidebar-item>
          <sidebar-item :link="{name: 'Payroll', path: '/admin/finance/payroll'}"></sidebar-item>
          <sidebar-item :link="{name: 'Account Matching', path: '/admin/finance/account-matching'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Calendar', icon: 'nc-icon nc-grid-45', path: '/calendar'}"></sidebar-item>
        <sidebar-item v-if="access === 0" :link="{name: 'Finance', icon: 'nc-icon nc-money-coins', path: '/finance'}"></sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar :notifications="notifications"></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './Extra/MobileMenu.vue'
  import UserMenu from './Extra/UserMenu.vue'
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu,
      UserMenu
    },
    data() {
      return {
        name: this.getCookie("name"),
        profilePic: this.getCookie("pic"),
        notifications: [],
        access:  this.getCookie("al"),
        sidebarClosed: this.getCookie('sb')
      }
    },
    async mounted() {
      this.initScrollbar()
      this.notifications = await this.get(`Notification/Unread/${this.getCookie('id')}`);
      this.$sidebar.displaySidebar(this.sidebarClosed)
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      },
      initScrollbar() {
        let docClasses = document.body.classList;
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          // if we are on windows OS we activate the perfectScrollbar function
          initScrollbar('main-panel');

          docClasses.add('perfect-scrollbar-on');
        } else {
          docClasses.add('perfect-scrollbar-off');
        }
      }
    }
  }

</script>
