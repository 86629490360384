<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">New job? Well done! Let's get down some details...</h5>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="jobName"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="jobName"
              :error="failed ? 'The Job Name field is required' : null"
              :hasSuccess="passed"
              placeholder="Job Name (required)"
              v-model="jobName"
              addon-left-icon="nc-icon nc-single-02"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="buyer"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="buyer"
              :error="failed ? 'The Buyer field is required' : null"
              :hasSuccess="passed"
              placeholder="Buyer (required)"
              v-model="buyer"
              addon-left-icon="nc-icon nc-single-02"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider
            name="role"
            rules="required"
            v-slot="{ passed, failed }"
          >
            <fg-input
              name="role"
              :error="failed ? 'The Role field is required' : null"
              :hasSuccess="passed"
              placeholder="Role (required)"
              v-model="role"
              addon-left-icon="nc-icon nc-single-02"
            >
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);

export default {
  components: {
  },
  data() {
    return {
      jobName: "",
      buyer: "",
      role: ""
    };
  },
  methods: {
    validate() {
      var item = {
        jobName: this.jobName,
        buyer: this.buyer,
        role: this.role
      };

      return this.$refs.form.validate().then((res) => {
        this.$emit("on-validated", item);
        return res;
      });
    }
  },
};
</script>
