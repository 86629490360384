import encryption from './encryption';
export default {
  /* COOKIES */
  setCookie(cname, cvalue, exdays) {
    try {
      cvalue = JSON.stringify(cvalue);
      cvalue  = encryption.encrypt(cvalue).replace(/;/g, ":");

      const d = new Date();
      let expires;
      if (exdays > 0) {
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        expires = "expires=" + d.toUTCString();
      } else {
        expires = "expires=Session";
      }

      document.cookie = cname + "=" + cvalue + ";" + expires + ";Secure;path=/";
    } catch {
      //eslint-disable-next-line
      console.error("Something went wrong storing a cookie");
    }
  },
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        var res = c.substring(name.length, c.length).replace(/:/g, ";")
        return JSON.parse(encryption.decrypt(res));
      }
    }
    return "";
  },
  removeCookie(cname) {
    document.cookie = cname + "=;path=/;expires=" + new Date(0).toUTCString();
  },
  async removeAllCookies() {
    var allCookies = document.cookie.split(";");

    // The "expire" attribute of every cookie is
    // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
    for (var i = 0; i < allCookies.length; i++) {
      document.cookie = allCookies[i].split('=')[0] + "=;path=/;expires=" + new Date(0).toUTCString();
    }
  },
  /* END COOKIES */

  /* SESSION STORAGE */
  getSessionStorage(key) {
    var cookie = sessionStorage.getItem(key);
    if (cookie) {
      var result = encryption.decrypt(cookie);
      return result;
    }
    return "";
  },
  setSessionStorage(key, value) {
    var encryptedValue = encryption.encrypt(value);
    sessionStorage.setItem(key, encryptedValue);
  }
  /* END SESSION STORAGE */
};
