<template>
  <div>
    <div class="row d-flex justify-content-center">
      <div class="col-sm-8">
        <div class="card card-wizard" id="wizardCard">
          <form-wizard
            shape="tab"
            @on-complete="wizardComplete"
            error-color="#FB404B"
            color="#35495E"
            title="Add Job"
            subtitle="Creating a new Job in the system"
          >
            <tab-content
              title="Job Name"
              class="col-12"
              :before-change="() => validateStep('jobName')"
              icon="nc-icon nc-badge"
            >
              <job-name
                ref="jobName"
                @on-validated="onStepValidated"
              ></job-name>
            </tab-content>

            <tab-content
              title="Dates"
              class="col-12"
              :before-change="() => validateStep('basicDetails')"
              icon="nc-icon nc-notes"
            >
              <basic-details
                ref="basicDetails"
                @on-validated="onStepValidated"
              ></basic-details>
            </tab-content>

            <tab-content
              title="Client"
              class="col-12"
              :before-change="() => validateStep('clientChoice')"
              icon="nc-icon nc-notes"
            >
              <client-choice
                ref="clientChoice"
                @on-validated="onStepValidated"
              ></client-choice>
            </tab-content>

            <tab-content
              title="Last step"
              class="col-12"
              icon="nc-icon nc-check-2"
            >
              <div>
                <h2 class="text-center text-space">Now let's finish up...</h2>
                <h4 class="text-center text-space">
                  Click finish to submit the Job and send them a contract
                </h4>
              </div>
            </tab-content>

            <button
              slot="prev"
              class="btn btn-default btn-fill btn-wd btn-back"
            >
              Back
            </button>
            <button
              slot="next"
              class="btn btn-default btn-fill btn-wd btn-next"
            >
              Next
            </button>
            <button slot="finish" class="btn btn-success btn-fill btn-wd">
              Finish
            </button>
          </form-wizard>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import JobName from "./JobName.vue";
import BasicDetails from "./BasicDetails.vue";
import ClientChoice from "./ClientChoice.vue";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      wizardModel: {},
    };
  },
  components: {
    FormWizard,
    TabContent,
    JobName,
    BasicDetails,
    ClientChoice
  },
  methods: {
    validateStep(ref) {
      return this.$refs[ref].validate();
    },
    onStepValidated(validated) {
      this.wizardModel = { ...this.wizardModel, ...validated };
    },
    async wizardComplete() {
      var body = {
        name: this.wizardModel.jobName,
        role: this.wizardModel.role,
        buyerId: this.wizardModel.clientId,
        clientId: this.wizardModel.clientId,
        startDate: this.wizardModel.startDate,
        endDate: this.wizardModel.endDate,
        status: 1,
      };

      await fetch(`${process.env.VUE_APP_API}Job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "AT": process.env.VUE_APP_TOKEN,
        },
        body: JSON.stringify(body),
      });

      Swal.fire({
        title: "Submitted",
        text: "The contract has been sent off, please sign your part so the Job can sign theirs.",
        type: "success",
        confirmButtonClass: "btn btn-success",
        buttonsStyling: false,
      });
    },
  },
};
</script>
<style lang="scss">
.vue-form-wizard .wizard-icon-circle.tab_shape {
  background-color: #9a9a9a !important;
  color: white;
}
.vue-form-wizard .wizard-tab-content {
  display: flex; // to avoid horizontal scroll when animating
  .wizard-tab-container {
    display: block;
    animation: fadeIn 0.5s;
  }
}
</style>
