<template>
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <card>
        <h3>Log A Submission</h3>
        <hr />
        <label>Project Name&nbsp;</label>
        <input style="width: 100%" v-model="project" />
        <br />
        <br />
        <label>Role Name&nbsp;</label>
        <input style="width: 100%" v-model="role" />
        <br />
        <br />
        <label>Description&nbsp;</label>
        <textarea
          @input="this.autosize"
          style="width: 100%"
          v-model="description"
        ></textarea>
        <br />
        <br />
        <label>Send email to the clients?&nbsp;&nbsp;</label>
        <Checkbox v-model="sendEmail"></Checkbox>
        <br />
        <br />
        <label>Clients</label>
        <ClientSelection
          @list-changed="setClients"
          @submit="submit()"
        ></ClientSelection>
      </card>
    </div>
    <div class="col-md-1"></div>
  </div>
</template>
<script>
import { Checkbox } from "element-ui";
import ClientSelection from "../../components/ClientSelection.vue";

export default {
  components: {
    ClientSelection,
    Checkbox,
  },
  data() {
    return {
      project: "",
      role: "",
      description: "",
      selectedClients: [],
      sendEmail: true,
    };
  },
  methods: {
    async submit() {
      for (var i = 0; i < this.selectedClients.length; i++) {
        var client = await this.get(`Client/Id/${this.selectedClients[i]}`);

        this.postBody("Job/Submission", {
          project: this.project,
          role: this.role,
          clientId: client.id,
          description: this.description,
        });

        if (this.sendEmail) {
          this.send(
            1,
            [
              {
                email: client.username,
                name: client.stageName,
              },
            ],
            [],
            [],
            {
              ROLE: this.role,
              PROJECT: this.project,
              DESCRIPTION: this.description,
            }
          );
        }
      }

      await this.$toastr.s("Submission Successful");

      window.location.reload();
    },
    setClients(e) {
      this.selectedClients = e;
    },
  },
};
</script>
