<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">Now let's get some contact information...</h5>
      <div class="row">
        <div class="col-md-6">
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
            <fg-input name="email"
                      :error="failed ? 'The Email Address field is required': null"
                      :hasSuccess="passed"
                      placeholder="Email Address (required)"
                      v-model="email"
                      addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider
            name="spotlightPin"
            v-slot="{ passed, failed }"
            :rules="{ regex: /^[0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]-[0-9][0-9][0-9][0-9]$/ }"
          >
            <fg-input name="spotlightPin"
                      :error="failed ? 'Must be a valid Spotlight PIN' : null"
                      :hasSuccess="passed"
                      placeholder="Spotlight PIN"
                      v-model="spotlightPin"
                      addon-left-icon="nc-icon nc-single-02">
            </fg-input>
          </ValidationProvider>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <select style="width: 100%;" v-model="type">
              <option v-for="(t,i) in types" :key="i" :value="i">{{ t }}</option>
          </select>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import { extend } from "vee-validate";
  import { required, email, regex } from "vee-validate/dist/rules";

  extend("required", required);
  extend("email", email);
  extend("regex", regex);

  export default {
    data() {
      return {
        email: "",
        spotlightPin: "",
        type: 0,
        types: {}
      };
    },
    async mounted() {
      this.types = await this.get('Client/Types');
    },
    methods: {
      validate() {
        var item = {
          email: this.email,
          spotlightPin: this.spotlightPin,
          type: this.type
        }
        return this.$refs.form.validate().then(res => {
          this.$emit('on-validated', item)
          return res
        })
      }
    }
  }
</script>
<style>
</style>
