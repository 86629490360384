<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button v-if="!publicPage" class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"></i>
        </button>
      </div>
      <a class="navbar-brand">{{this.$route.name.split('- ')[1]}}</a>
      <button v-if="!publicPage"
              type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-expanded="false"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul v-if="!publicPage" class="navbar-nav">
          <drop-down>
            <template slot="title">
              <i class="nc-icon nc-bell-55"></i>
              <span class="notification">{{notifications.length}}</span>
              <span class="d-lg-none">Notification</span>
            </template>
            <a v-for="notification in notifications" :key="notification.id" class="dropdown-item" :href="notification.id">
              {{notification.title}}
              <br/>
              <small>{{ dateFormat(notification.created) }}</small>
            </a>
          </drop-down>
          <drop-down position="right">
            <i slot="title" class="nc-icon nc-bullet-list-67"></i>

            <a class="dropdown-item" href="#">
              <i class="nc-icon nc-email-85"></i> Messages
            </a>
            <a class="dropdown-item" href="/support">
              <i class="nc-icon nc-support-17"></i> Support
            </a>
            <a class="dropdown-item" href="/settings">
              <i class="nc-icon nc-settings-90"></i> Settings
            </a>
            <a @click="logOut" class="dropdown-item text-danger">
              <i class="nc-icon nc-button-power"></i> Log out
            </a>

          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    props: {
      notifications: {
        type: Array,
        default() {
          return []
        }
      },
      publicPage: Boolean,
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    mounted() {
      if (this.publicPage) {
        this.hideSidebar()
      }
      
    },
    methods: {
      async logOut() {
        await this.removeAllCookies().then(() => {
          window.location.replace('/');
      })
      },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      }
    }
  }

</script>
<style>

</style>
