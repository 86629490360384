<template>
  <div>
    <Spinner v-if="loading"></Spinner>
    <div v-else>
      <div class="row">
        <div class="col-md-4">
          <card style="height: 95%">
            <div class="row">
              <div class="col-md-10">
                <input v-if="editable" v-model="job.name" style="width: 100%" />
                <h3 v-else>{{ job.name }}</h3>
              </div>
              <div class="col-md-2" v-if="status !== 'Completed' && access > 0">
                <button v-if="!editable" class="btn btn-primary" @click="edit">
                  Edit
                </button>
                <button v-else class="btn btn-success" @click="edit">
                  Edit
                </button>
              </div>
            </div>
            <hr />
            <span
              ><b>Role</b>:
              <input
                v-if="editable"
                v-model="job.role"
                style="width: 100%"
              /><span v-else>{{ job.role }}</span></span
            >
            <br />
            <br />
            <span
              ><b>Buyer</b>:
              <div v-if="editable">
                <select class="form-control" v-model="job.buyerId">
                  <option value=""></option>
                  <option v-for="(t, i) in buyers" :key="i" :value="t.id">
                    {{ t.name }}
                  </option>
                </select>
                <button class="btn btn-primary" @click="addBuyer = true">
                  + Create
                </button>
              </div>
              <span v-else>{{ this.buyerName }}</span>
            </span>
            <br />
            <br />
            <span v-if="job.status >= 1 && auditions.length > 0"
              ><b>Audition Date</b>:
              <DatePicker
                v-if="editable && job.status === 1"
                v-model="auditions[0].date"
              ></DatePicker
              ><span v-else>{{
                dateFormat(auditions[0].date) ===
                ("01/01/1970" || "01/01/1" || "31/12/0")
                  ? "Unknown"
                  : dateFormat(auditions[0].date)
              }}</span></span
            >
            <br />
            <span v-if="job.status >= 2 && auditions.length > 1"
              ><b>Recall Date</b>:
              <DatePicker
                v-if="editable && job.status === 2"
                v-model="auditions[1].date"
              ></DatePicker
              ><span v-else>{{
                dateFormat(auditions[1].date) ===
                ("01/01/1970" || "01/01/1" || "31/12/0")
                  ? "Unknown"
                  : dateFormat(auditions[1].date)
              }}</span></span
            >
            <hr />
            <span
              ><b>Start Date</b>:
              <DatePicker v-if="editable" v-model="job.startDate"></DatePicker
              ><span v-else>{{
                dateFormat(job.startDate) ===
                ("01/01/1970" || "01/01/1" || "31/12/0")
                  ? "Unknown"
                  : dateFormat(job.startDate)
              }}</span></span
            >
            <br />
            <span
              ><b>End Date</b>:
              <DatePicker v-if="editable" v-model="job.endDate"></DatePicker
              ><span v-else>{{
                dateFormat(job.endDate) ===
                ("01/01/1970" || "01/01/1" || "31/12/0")
                  ? "Unknown"
                  : dateFormat(job.endDate)
              }}</span></span
            >
            <br />
            <hr />
            <button
              class="btn btn-primary"
              style="width: 100%"
              @click="printBookingForm"
            >
              Print
            </button>
          </card>
        </div>
        <div class="col-md-4">
          <card style="height: 95%">
            <h4>Status</h4>
            <hr />
            <div class="row">
              <div class="col-md-2"></div>
              <b class="col-md-8 text-center">{{ status }}</b>
              <div class="col-md-2"></div>
            </div>
            <div v-if="status !== 'Completed'" class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8 text-center">
                <button @click="moveStatus" v-if="auth" class="btn btn-success">
                  Change to <b>{{ nextStatus }}</b>
                </button>
              </div>
              <div class="col-md-2"></div>
            </div>
            <br />
            <div v-if="auditions.length > 0">
              <div
                v-if="auditions[auditions.length - 1].isSelfTape"
                class="row"
              >
                <div class="col-md-2"></div>
                <div class="col-md-8 text-center">
                  <button
                    v-if="status !== 'Completed' && !uploadReady"
                    @click="uploadReady = true"
                    class="btn btn-primary"
                    :style="`width: ${job.tapeUploaded ? '50' : '100'}%;`"
                  >
                    {{ job.tapeUploaded ? "Update" : "Upload" }} Tape
                  </button>
                  <button
                    v-if="
                      status !== 'Completed' && !uploadReady && job.tapeUploaded
                    "
                    @click="sendTapeModal = true"
                    class="btn btn-warning"
                    style="width: 50%"
                  >
                    Send Tape
                  </button>
                  <Upload
                    v-if="uploadReady && !imageUrl"
                    name="uploadingVideo"
                    :action="'/'"
                    accept="video/mp4"
                    :http-request="uploadPic"
                    class="avatar-uploader"
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                  >
                    <i class="el-icon-plus avatar-uploader-icon"></i>
                  </Upload>
                  <video
                    v-if="imageUrl"
                    width="640"
                    height="264"
                    :options="{
                      autoplay: true,
                      controls: true,
                      preload: 'poster',
                    }"
                  >
                    <source :src="imageUrl" />
                  </video>
                  <button
                    v-if="imageUrl && !uploading"
                    @click="uploadTape"
                    class="btn btn-success"
                    style="width: 100%"
                  >
                    Save
                  </button>
                  <video-player
                    v-if="job.tapeUploaded && !uploadReady"
                    :options="tape.videoOptions"
                    class="medvid"
                  />
                </div>
              </div>
              <div class="row" v-else>
                <span class="col-md-4">Location</span>
                <input
                  v-if="editable && job.status === 1"
                  class="col-md-8"
                  style="width: 100%"
                  v-model="auditions[0].location"
                />
                <b v-else class="col-md-8">{{ auditions[0].location }}</b>
              </div>
              <div class="row" v-if="auditions.length > 1">
                <span class="col-md-4">Recall Location</span>
                <input
                  v-if="editable && job.status === 2"
                  class="col-md-8"
                  style="width: 100%"
                  v-model="auditions[1].location"
                />
                <b v-else class="col-md-8">{{ auditions[1].location }}</b>
              </div>
            </div>
          </card>
        </div>
        <div v-if="access > 0" class="col-md-4">
          <card style="height: 95%">
            <h3>Client</h3>
            <hr />
            <div class="row">
              <div class="col-md-2"></div>
              <img class="headshot col-md-8" :src="headshot" />
              <div class="col-md-2"></div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <span class="col-md-8">{{ client.stageName }}</span>
              <div class="col-md-2"></div>
            </div>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <card style="height: 100%">
            <div class="row">
              <div class="col-md-10">
                <h3>Notes</h3>
              </div>
              <div class="col-md-2">
                <button
                  v-if="status !== 'Completed' && access > 0"
                  @click="noteBoxOpen = !noteBoxOpen"
                  class="btn btn-primary"
                  style="width: 100%"
                >
                  +
                </button>
              </div>
            </div>
            <div v-if="noteBoxOpen">
              <div class="row">
                <div class="col-md-1"></div>
                <textarea
                  v-model="newNote"
                  @input="this.autosize"
                  class="col-md-10"
                  style="width: 100%; height: auto; resize: none"
                ></textarea>
                <div class="col-md-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-1"></div>
                <input
                  type="file"
                  class="col-md-10"
                  @change="setAttachmentFile"
                />
                <div class="col-md-1"></div>
              </div>
              <br />
              <div class="row">
                <div class="col-md-8"></div>
                <div class="col-md-3">
                  <button
                    style="width: 100%"
                    class="btn btn-success"
                    @click="addNote"
                  >
                    Add
                  </button>
                </div>
                <div class="col-md-1"></div>
              </div>
            </div>
            <hr />
            <div v-for="note in job.notes" :key="note.content">
              <span
                v-html="
                  note.content.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>')
                "
              ></span>
              <div v-if="note.attachment">
                <br />
                <i class="nc-icon nc-tablet-2"></i>&nbsp;<a
                  href=""
                  @click="getAttachmentFile(note.attachment)"
                  >{{ note.attachment }}</a
                >
              </div>
              <br v-else />
              <b>{{ note.createdBy }} - {{ dateFormat(note.created) }}</b>
              <hr />
            </div>
          </card>
        </div>
        <div class="col-md-6">
          <card style="height: 100%">
            <div class="row">
              <div class="col-md-9">
                <h3>Financials</h3>
              </div>
              <div class="col-md-2">
                <button
                  v-if="status !== 'Completed' && access > 0"
                  class="btn btn-primary"
                  @click="financeModal = true"
                >
                  New
                </button>
              </div>
            </div>
            <hr />
            <InvoiceTable
              v-if="!loading"
              :invoices="invoices"
              @print="openInvPrinter"
            ></InvoiceTable>
            <div></div>
          </card>
        </div>
      </div>
      <el-dialog
        id="job-invoice"
        :title="'Create Invoice - ' + job.name"
        :visible.sync="financeModal"
      >
        <InvoiceCreation
          :id="id"
          :title="job.name"
          :cId="client.id"
          :client="client.stageName"
        ></InvoiceCreation>
      </el-dialog>
      <el-dialog id="send-tape" title="Send Tape" :visible.sync="sendTapeModal">
      </el-dialog>
      <el-dialog :visible.sync="printingBF">
        <BookingForm id="booking-form"></BookingForm>
      </el-dialog>
      <el-dialog :visible.sync="printingInv">
        <button
          class="btn btn-success"
          style="width: 100%"
          @click="sendInvoice"
        >
          Send
        </button>
        <button
          class="btn btn-warning"
          style="width: 100%"
          @click="printInvoice"
        >
          Print
        </button>
        <br />
        <br />
        <Invoice
          id="invoice"
          :inv="selectedInvoice"
          v-if="printingInv"
        ></Invoice>
      </el-dialog>
      <el-dialog :visible.sync="addBuyer">
        <AddBuyer @add="createBuyer"></AddBuyer>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { Dialog, DatePicker, Upload } from "element-ui";
import InvoiceCreation from "../../components/InvoiceCreation.vue";
import InvoiceTable from "../../components/InvoiceTable.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import Spinner from "@/components/Spinner.vue";
import BookingForm from "../../components/Documents/BookingForm.vue";
import Invoice from "../../components/Documents/Invoice.vue";
import AddBuyer from "@/components/AddBuyer.vue";

export default {
  components: {
    [Dialog.name]: Dialog,
    DatePicker,
    InvoiceCreation,
    InvoiceTable,
    Upload,
    VideoPlayer,
    Spinner,
    Invoice,
    AddBuyer,
  },
  props: {
    id: String,
  },
  data() {
    return {
      job: {},
      client: {},
      status: "",
      nextStatus: "",
      invoices: [],
      financeModal: false,
      sendTapeModal: false,
      auditions: [],
      statuses: [],
      buyers: [],
      editable: false,
      loading: true,
      newNote: "",
      noteBoxOpen: false,
      attachmentFile: null,
      access: this.getCookie("al"),
      uploadingVideo: null,
      uploading: false,
      uploadReady: false,
      imageUrl: null,
      tape: {},
      printingBF: false,
      printingInv: false,
      selectedInvoice: "",
      addBuyer: false,
    };
  },
  computed: {
    headshot() {
      return `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/headshots/${this.client.id}`;
    },
    auth() {
      if (this.access > 0) {
        return this.client.agentId === this.getCookie("id");
      } else {
        return false;
      }
    },
    buyerName() {
      try {
        return this.buyers.find((b) => b.id === this.job.buyerId).name;
      } catch {
        return "";
      }
    },
    buyerEmail() {
      try {
        return this.buyers.find((b) => b.id === this.job.buyerId).email;
      } catch {
        return "";
      }
    },
  },
  async mounted() {
    this.job = await this.get(`Job/${this.id}`);

    if (this.access == 0 && this.job.clientId !== this.getCookie("id")) {
      this.$router.push("/dashboard");
    }

    this.statuses = await this.get("Job/Statuses");

    this.job.notes = this.job.notes.reverse();

    this.client = await this.get(`Client/Id/${this.job.clientId}`);
    this.buyers = await this.get("Buyer");
    this.status = this.statuses[this.job.status];
    this.nextStatus = this.statuses[this.job.status + 1];
    this.invoices = await this.get(`Invoice/Job/${this.id}`);
    this.auditions = await this.get(`Audition?jobId=${this.id}`);

    if (this.job.tapeUploaded) {
      this.tape = {
        id: this.job.id,
        videoOptions: {
          autoplay: false,
          controls: true,
          preload: "poster",
          sources: [
            {
              src: `https://storage.googleapis.com/public-store-castmate/tapes/${this.job.id}`,
              type: "video/mp4",
            },
          ],
        },
      };
    }

    this.loading = false;
  },
  methods: {
    async edit() {
      this.editable = !this.editable;

      if (!this.editable) {
        if (this.job.status === 1) {
          await this.post(
            `Audition/Update/${this.auditions[0].id}?date=${new Date(
              this.auditions[0].date
            ).toISOString()}&location=${this.auditions[0].location}`
          );
        } else if (this.job.status === 2) {
          await this.post(
            `Audition/Update/${this.auditions[1].id}?date=${new Date(
              this.auditions[1].date
            ).toISOString()}&location=${this.auditions[1].location}`
          );
        }

        await this.post(`Job/Update/Name?id=${this.id}&value=${this.job.name}`);
        await this.post(`Job/Update/Role?id=${this.id}&value=${this.job.role}`);
        await this.post(
          `Job/Update/BuyerId?id=${this.id}&value=${this.job.buyerId}`
        );
        await this.post(
          `Job/Update/Date/StartDate?id=${this.id}&value=${new Date(
            this.job.startDate
          ).toISOString()}`
        );
        await this.post(
          `Job/Update/Date/EndDate?id=${this.id}&value=${new Date(
            this.job.endDate
          ).toISOString()}`
        ).then(() => {
          this.$toastr.s("Success");
        });
      }
    },
    async moveStatus() {
      this.job.status++;

      await this.post(
        `Job/Status/Update?id=${this.id}&status=${this.job.status}`
      );

      if (this.job.status === 2) {
        var audition = {
          jobId: this.id,
          isSelfTape: false,
          location: "",
          date: new Date(),
        };

        await this.postBodyVoid("Audition", audition);
        this.auditions.push(audition);
      }

      this.status = this.statuses[this.job.status];
      this.nextStatus = this.statuses[this.job.status + 1];
    },
    async addNote() {
      this.noteBoxOpen = false;
      var agent = await this.get(`Staff/Id/${this.getCookie("id")}`);
      var body = {
        content: this.newNote,
        createdBy: `${agent.firstName} ${agent.lastName}`,
      };

      if (this.attachmentFile) {
        body.attachment = this.attachmentFile.name;
      }

      await this.postBodyVoid(`Job/Notes/Update?id=${this.id}`, body).then(
        async () => {
          if (this.attachmentFile) {
            await fetch(
              `https://storage.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_BUCKET}/o?name=attachments/${this.attachmentFile.name}&uploadType=media`,
              {
                headers: new Headers({
                  Authorization: `Bearer ${this.getCookie("token")}`,
                  "Content-Type": this.attachmentFile.type,
                }),
                method: "POST",
                body: this.attachmentFile,
              }
            );
          }

          this.$toastr.s("Success");

          body.created = new Date();

          this.job.notes.unshift(body);

          this.newNote = "";
        }
      );
    },
    setAttachmentFile(e) {
      this.attachmentFile = e.target.files[0];
    },
    getAttachmentFile(f) {
      window.open(
        `https://storage.googleapis.com/${process.env.VUE_APP_BUCKET}/attachments/${f}`
      );
    },
    async handleAvatarSuccess(res, file) {
      this.uploadingVideo = file;
      this.uploadingVideo.position = 0;
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    async uploadPic() {
      await fetch("/");
    },
    async uploadTape() {
      this.uploading = true;
      this.uploadingVideo.position = 0;
      this.loading = true;

      await fetch(
        `https://storage.googleapis.com/upload/storage/v1/b/${process.env.VUE_APP_PUBLIC_BUCKET}/o?name=tapes/${this.job.id}&uploadType=media`,
        {
          headers: new Headers({
            "Content-Type": this.uploadingVideo.raw.type,
          }),
          method: "POST",
          body: this.uploadingVideo.raw,
        }
      )
        .then(async (d) => {
          const es = await d.json();
          if (d.status !== 200) {
            for (var i = 0; i < es.error.errors.length; i++) {
              await this.$toastr.e(es.error.errors[i].message);
            }
          } else {
            await this.post(
              `Job/Update/TapeUploaded?id=${this.job.id}&value=true`
            );

            this.tape = {
              id: this.job.id,
              videoOptions: {
                autoplay: false,
                controls: true,
                preload: "poster",
                sources: [
                  {
                    src: `https://storage.googleapis.com/public-store-castmate/tapes/${this.job.id}`,
                    type: "video/mp4",
                  },
                ],
              },
            };

            await this.$toastr.s("Success!");
          }

          this.imageUrl = null;
          this.uploadReady = false;
          this.uploading = false;
          this.loading = false;
        })
        .catch((e) => {
          this.$toastr.e(e);
          this.uploading = false;
        });
    },
    printBookingForm() {
      this.printingBF = true;

      setTimeout(() => {
        const e = document.getElementById("booking-form");
        this.createDocument(e, "h", `${this.job.name} - Booking Form`);
        this.printingBF = false;
      }, 1400);
    },
    openInvPrinter(inv) {
      if (inv.type === 1) {
        this.$toastr.w("Cannot print client invoices!");
        return;
      }

      if (this.buyerName === "") {
        this.$toastr.e("A Buyer must be attached to this job to send invoices");
        return;
      }

      this.printingInv = true;
      this.selectedInvoice = inv.id;
    },
    printInvoice() {
      setTimeout(() => {
        const e = document.getElementById("invoice");
        this.createDocument(e, "h", `${this.job.name} - Invoice`);
        this.printingInv = false;
      }, 1500);
    },
    async sendInvoice() {
      const inv = await this.get(`Invoice/${this.selectedInvoice}`);

      var agentCC = {};
      if (client.type === 4) {
        agentCC =  {
              email: "hello@expression-agency.com",
              name: "Expression Commercials Agency"
            };
      } else {
        const agent = await this.get(`Staff/Id/${client.agentId}`);
        agentCC = {
          email: agent.username,
          name: `${agent.firstName} ${agent.lastName}`
        }
      }

      this.send(
        4,
        [{
          email: this.buyerEmail,
          name: this.buyerName,
        }],
        [
        {
          email: "finance@vwtalent.com",
          name: "Finance Team"
        },
        agentCC],
        [],
        {
          parameters: {
            CLIENT: this.client.stageName,
            INVNO: `#${inv.id.split("-")[1].toUpperCase()}${inv.id
              .split("-")[2]
              .toUpperCase()}`,
            PROJECT: this.job.name,
            TOTAL: this.moneyFormat(inv.total, "GBP")
          },
        }
      );

      await this.$toastr.s("Success");
    },
    async createBuyer(model) {
      const newBuyer = await this.postBody("Buyer", model);
      this.buyers.push(newBuyer);
      this.job.buyerId = newBuyer.id;
      await this.$toastr.s("Success");
    },
  },
};
</script>
<style>
#job-invoice .el-dialog {
  width: 100%;
}
</style>
