<template>
  <div>
    <div class="row">
      <button
        @click="createModal = true"
        style="width: 100%"
        class="btn btn-primary"
      >
        Create
      </button>
    </div>
    <div class="row">
      <div v-for="customer in customers" :key="customer.id" class="col-md-3">
        <card>
          <button @click="edit(customer)" class="btn btn-warning">Edit</button>
          <h4>{{ customer.name }}</h4>
          <h6>Email: {{ customer.email }}</h6>
          <br />
          <small v-for="email in customer.moreEmails" :key="email"
            >{{ email }}<br
          /></small>
          <br />
          <span
            >{{ customer.address.line1 }}, {{ customer.address.line2 }},
            {{ customer.address.line3 }}, {{ customer.address.town }},
            {{ customer.address.county }}, {{ customer.address.postcode }}</span
          >
        </card>
      </div>
    </div>
    <el-dialog title="Create New Person" :visible.sync="createModal">
      <div class="row">
        <div class="col-md-6">
          <span>Customer Name: &nbsp;</span>
        </div>
        <div class="col-md-6">
          <input style="width: 100%" v-model="newCustomer.name" />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-md-6">
          <span>Customer Code: &nbsp;</span>
        </div>
        <div class="col-md-6">
          <input v-model="customerCode" />
        </div>
      </div>
      <br />
      <br />
      <div class="row">
        <h4 class="col-md-12">Address</h4>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>Line 1: &nbsp;</span>
        </div>
        <div class="col-md-6">
          <input style="width: 100%" v-model="newCustomer.address.line1" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>Line 2: &nbsp;</span>
        </div>
        <div class="col-md-6">
          <input style="width: 100%" v-model="newCustomer.address.line2" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>City: &nbsp;</span>
        </div>
        <div class="col-md-6">
          <input style="width: 100%" v-model="newCustomer.address.city" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <span>Postcode: &nbsp;</span>
        </div>
        <div class="col-md-6">
          <input style="width: 100%" v-model="newCustomer.address.postcode" />
        </div>
      </div>
      <hr />
      <div class="row">
        <button @click="create()" style="width: 100%" class="btn btn-primary">
          Create
        </button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="editModal" title="Edit Customer" width="30%">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <label>Name</label>
          <input style="width: 100%" v-model="selectedCustomer.name" />
          <br />
          <br />
          <label>Email</label>
          <input style="width: 100%" v-model="selectedCustomer.email" />
          <br />
          <br />
          <label>More Emails</label>
          <div
            class="row"
            v-for="email in selectedCustomer.moreEmails"
            :key="email"
          >
            <div class="col-md-12">
              <span
                >{{ email }}
                <a
                  href="#"
                  @click="
                    selectedCustomer.moreEmails.splice(
                      selectedCustomer.moreEmails.indexOf(email),
                      1
                    );
                    $forceUpdate();
                  "
                  >X</a
                ></span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input
                v-model="newEmail"
                @keyup.enter="
                  selectedCustomer.moreEmails.push(newEmail);
                  newEmail = '';
                  $forceUpdate();
                "
                style="width: 100%"
              />
            </div>
          </div>
          <br />
          <br />
          <label>Address 1</label>
          <input style="width: 100%" v-model="selectedCustomer.address.line1" />
          <br />
          <br />
          <label>Address 2</label>
          <input style="width: 100%" v-model="selectedCustomer.address.line2" />
          <br />
          <br />
          <label>Address 3</label>
          <input style="width: 100%" v-model="selectedCustomer.address.line3" />
          <br />
          <br />
          <label>Town</label>
          <input style="width: 100%" v-model="selectedCustomer.address.town" />
          <br />
          <br />
          <label>County</label>
          <input
            style="width: 100%"
            v-model="selectedCustomer.address.county"
          />
          <br />
          <br />
          <label>Postcode</label>
          <input
            style="width: 100%"
            v-model="selectedCustomer.address.postcode"
          />
          <br />
          <br />
          <button class="btn btn-primary" style="width: 100%" @click="update">
            Update
          </button>
        </div>
        <div class="col-md-1"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { Dialog } from "element-ui";
export default {
  components: {
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      createModal: false,
      editModal: false,
      customers: [],
      newCustomer: {
        name: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          postcode: "",
        },
      },
      newEmail: "",
      selectedCustomer: {
        address: {},
      },
    };
  },
  async mounted() {
    await this.getPeople();
  },
  methods: {
    async getPeople() {
      this.customers = await this.get("Buyer");
    },
    async create() {
      var data = {
        name: this.newCustomer.name,
        contact_type_ids: ["CUSTOMER"],
        reference: this.customerCode,
        default_sales_ledger_account_id: "bf411147bfde11ecb2850279d0cc53c5",
        main_address: {
          address_line_1: this.newCustomer.address.line1,
          address_line_2: this.newCustomer.address.line2,
          city: this.newCustomer.address.city,
          postal_code: this.newCustomer.address.postcode,
        },
      };

      await fetch(`${process.env.VUE_APP_API}Invoice/Customers/Create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          AT: process.env.VUE_APP_TOKEN,
          SAT: this.getCookie("sg"),
        },
        body: JSON.stringify(data),
      })
        .then(async (res) => {
          if (res.status === 201) {
            await this.$toastr.s("Success");
            window.location.reload();
          } else {
            var e = await res.json();
            this.$toastr.e(e[0].$message);
          }
        })
        .catch(async (res) => {
          await this.$toastr.e(res);
        });
    },
    edit(customer) {
      this.selectedCustomer = customer;
      this.editModal = true;
    },
    async update() {
      await this.postBody("Buyer/Edit", this.selectedCustomer);
      await this.$toastr.s("Success");
    },
  },
  computed: {
    customerCode() {
      var res = "";
      if (this.newCustomer.name.length > 0) {
        var words = this.newCustomer.name.split(" ");

        for (var i = 0; i < words.length; i++) {
          res += words[i].split("")[0] || "";
        }

        return `${res}001`.toUpperCase();
      }

      return "";
    },
  },
};
</script>
