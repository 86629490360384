<template>
  <div class="form-check" :class="[inlineClass, {disabled: disabled}]">
    <label style="font-size: 20px" :for="cbId" class="form-check-label">
      <input :id="cbId"
             class="form-check-input"
             type="checkbox"
             :disabled="disabled"
             v-model="model"
             @change="ticked" />
      <span class="form-check-sign"></span>
      <slot>&nbsp;&nbsp;{{label}}</slot>
    </label>
    <br />
  </div>
</template>
<script>
import { randomUUID } from 'crypto'

  export default{
    name: 'l-checkbox',
    model: {
      prop: 'checked'
    },
    props: {
      label: String,
      checked: [Array, Boolean],
      disabled: [Boolean, String],
      inline: Boolean,
      ticked: Function,
      cbId: {
        type: String,
        default: randomUUID
      }
    },
    computed: {
      model: {
        get () {
          return this.checked
        },
        set (check) {
          this.$emit('input', check)
        }
      },
      inlineClass () {
        if (this.inline) {
          return `checkbox-inline`
        }

        return false
      }
    }
  }
</script>
