<template>
    <div>
        <Table :columns="columns" :data="data" @edit="accept"></Table>
    </div>
</template>
<script>
import Table from '../../components/Table.vue';
export default {
    components: {
        Table,
    },
    data() {
        return {
            columns: ["id", "project", "agent", "amount", "accept"],
            data: []
        }
    },
    async mounted() {
        var commissions = await this.get('Commission/Unpaid')
        var staff = await this.get('Staff')

        for (var i = 0; i < commissions.length; i++) {
            var agent = this.filterList(commissions[i].agentId, "id", staff)[0]
            this.data.push({
                id: commissions[i].id,
                project: commissions[i].reference,
                agent: `${agent.firstName} ${agent.lastName}`,
                amount: `£${commissions[i].amount}`
            })
        }
    },
    methods: {
        async accept(d) {
            await this.post(`Commission/Pay?id=${d.id}`)
            await this.$toastr.s('Success')

            window.location.reload()
        }
    }
}
</script>